import React from "react";
import Heading from "../../components/Heading";
import { Button, Form, Row, Col, Input, message } from "antd";
import { checkNumbervalue, checkSpace } from "../../utils";
import nodeURLs from "../../api/nodeUrls";
import API from "../../api";

function LCRThreshold() {
  const [form] = Form.useForm();
  const { THRESHOLD_POST } = nodeURLs;

  const onFinish = async (values) => {
    const response = await API.Api(THRESHOLD_POST).postWithPrmiseHeader(values);

    if (response.status) {
      form.resetFields();
      message.success(response?.data?.message || "Data Audited Successfully");
    } else {
      message.error(response.response.data.message);
    }
  };

  return (
    <div className="p-2">
      <Heading title={"LCR Threshold"} navigateTo={"/masters"} />
      <div className="config-template mb-2">
        <p className="f-16 fw-500 title-color pt-1 pb-1 pl-1">
          Set shipment weight threshold
        </p>
        <hr className="divider" />
        <>
          <Form
            form={form}
            onFinish={onFinish}
            name="basic"
            className="global-form "
            layout="vertical"
            autoComplete="off"
          >
            <Row
              gutter={20}
              justify={"center"}
              style={{ minWidth: "65%" }}
              className="p-2 global-form-row "
            >
              <Col lg={8}>
                <Form.Item
                  label="Actual Weight(Kg)"
                  name="weightThreshold"
                  onKeyPress={(event) => {
                    if (checkNumbervalue(event) || checkSpace(event)) {
                      event.preventDefault();
                    }
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter weight",
                    },
                  ]}
                >
                  <Input placeholder="Enter weight" maxLength={4} type="text" />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  name="volumetricWeightThreshold"
                  label="Volumetric Weight(Kg)"
                  rules={[
                    {
                      required: true,
                      message: "Please enter volumetric weight",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (checkNumbervalue(event) || checkSpace(event)) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    placeholder="Enter volumetric"
                    maxLength={4}
                    type="text"
                  />
                </Form.Item>
              </Col>
              <Col lg={2}>
                <Button
                  type="primary"
                  className="save-btn"
                  form="basic"
                  htmlType="submit"
                  style={{ marginTop: "30px", padding: "0px 40px" }}
                >
                  SAVE
                </Button>
              </Col>
            </Row>
            <Row
              gutter={20}
              justify={"center"}
              style={{ minWidth: "65%" }}
              className="p-2 global-form-row"
            >
              <Col
                style={{
                  backgroundColor: "#D7E5F4",
                  border: "1px solid #C2D4EA ",
                  height: "92px",
                  borderRadius: "5px",
                }}
                lg={12}
                className="center"
              >
                <span style={{ fontSize: "14px", color: "#444444" }}>
                  <span
                    style={{
                      color: "#1C68C5",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    NOTE :
                  </span>
                  If (Volumetric Weight / Actual Weight) is less than or equal
                  to threshold, then the shipment must be bagged.
                </span>
              </Col>
            </Row>
          </Form>
        </>
      </div>
    </div>
  );
}

export default LCRThreshold;
