/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Empty, message, Spin, Row, Col, Input } from "antd";

import packetIcon from "../../../assests/images/packetIcon.svg";
import HubIcon from "../../../assests/images/HubIcon.svg";
import weightIcon from "../../../assests/images/weightLogo.svg";
import countIcon from "../../../assests/images/countLogo.svg";
import guideIcon from "../../../assests/images/guide.svg";
import packetContents from "../../../assests/images/packetContents.svg";
import shipmentTruck from "../../../assests/images/SVG/packetSurface.svg";
import planeIcon from "../../../assests/images/planeicon.svg";
import packetLock from "../../../assests/images/packet-lock.svg";
import { capitaliseWithHyphen } from "../../../utils";
import urls from "../../../api/urls";
import API from "../../../api";
import "./style.scss";
const { GET_PACKET_LIST } = urls;

const ViewPacket = () => {
  const [packetingData, setPacketingData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPacketData();
  }, []);

  const getPacketData = async () => {
    setLoading(true);
    let response = await API.Api(GET_PACKET_LIST).getWithUserNPremiseHeader();
    if (response.status) {
      setLoading(false);
      let data = response?.response?.data;
      setPacketingData(data);
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.message || "Internal server error"
      );
    }
  };

  return (
    <div className="master-main-container p-1">
      <Spin spinning={loading}>
        {packetingData?.length === 0 ? (
          <Empty />
        ) : (
          <Row gutter={16}>
            {packetingData?.map((val, idx) => {
              return (
                <Col
                  span={9}
                  className="cards active-cards"
                  key={idx}
                  style={{ backgroundColor: "#F8F7F7" }}
                >
                  <div className="space-between">
                    <div>
                      <span>
                        <img src={packetIcon} /> Packet Id
                      </span>

                      <Input disabled value={val?.packetBarcode} />
                    </div>
                    {/* <div>
                      <span className="fw-300 mr-2">
                        <img src={HubIcon} /> Hub
                      </span>

                      {val.travelBy === "air" ? (
                        <img src={planeIcon} style={{ width: "25px" }} />
                      ) : (
                        <img src={shipmentTruck} style={{ width: "25px" }} />
                      )}
                    </div> */}
                  </div>

                  <Row style={{ marginBottom: "16px" }}>
                    <Col span={7}>
                      <Row className="row-one">
                        {" "}
                        <img src={weightIcon} />
                        <span className="ml-1">Weight</span>
                      </Row>
                      <Row className="row-two">{val?.weight?.toFixed(2)}kg</Row>
                    </Col>
                    <Col span={7}>
                      <Row className="row-one">
                        {" "}
                        <img src={countIcon} />
                        <span className="ml-1">Count</span>
                      </Row>
                      <Row className="row-two">{val?.shipmentCount}</Row>
                    </Col>
                    <Col span={10}>
                      <Row className="row-one guide-name">
                        {" "}
                        <img src={guideIcon} />
                        <span className="ml-1">Packeting guide name</span>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="row-one">
                    <Col span={12}>
                      {" "}
                      <img src={packetContents} />
                      Packet contents
                    </Col>
                  </Row>
                  <Row className="content-items">
                    <Col span={24}>
                      <Row className="content-table-header">
                        <Col span={15} className="awb-col">
                          AWB No.
                        </Col>
                        <Col span={9}>Destination</Col>
                      </Row>
                      <Row className="awb-destiny-row">
                        <Col className="awb-col-items" span={24}>
                          {val?.packetShipment?.map((item, scanIndex) => {
                            return (
                              <Row
                                className="space-between"
                                key={scanIndex + Math.random()}
                                style={{
                                  borderBottom: "1px solid #ddd",
                                }}
                              >
                                <Col span={12}>
                                  {capitaliseWithHyphen(item?.awbNumber)}
                                </Col>
                                <Col span={12}>
                                  {capitaliseWithHyphen(item?.destination)}
                                </Col>
                              </Row>
                            );
                          })}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <img
                    src={packetLock}
                    style={{
                      position: "absolute",
                      bottom: "6rem",
                      left: "11rem",
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Spin>
    </div>
  );
};
export default ViewPacket;
