/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Collapse, Table, Input } from "antd";
import API from "../../../api";
import urls from "../../../api/urls";
import "./style.scss";
import { capitaliseWithHyphen } from "../../../utils";
const {
  GET_ALL_CHARGES,
  GET_ALL_RATE_SLABS,
  GET_ALL_CALCULATION_BASIS,
  CREATE_RATE_CHARGE,
  CREATE_RATE_SLAB,
} = urls;

const RateCharges = ({ quotationRateCharge }) => {
  const { Panel } = Collapse;
  const [rateCharges, setRateCharges] = useState([]);
  const [calculationBasisList, setCalculationBasisList] = useState([]);
  const [rateSlabList, setRateSlabList] = useState([]);

  useEffect(() => {
    getAllCharge();
  }, [quotationRateCharge]);

  const getAllCharge = async () => {
    setRateCharges(
      quotationRateCharge.map((item) => ({
        ...item,
        chargeType: item?.pricingType,
        pricingType: item?.pricingType,
        appliesOn: item?.appliesOn,
        rate: item?.rate,
        percentage: item?.percentage,
        slabId: item?.slabId,
        activeType: "rate",
        rateSlab: null,
        calculationBasis: item?.calculationBasis,
        head: item?.chargesHead,
      }))
    );
  };

  const columns = useMemo(
    () => [
      {
        title: "Charge Head",
        dataIndex: "head",
        render: (text, record) =>
          text ? (
            capitaliseWithHyphen(text)
          ) : (
            <Input
              style={{ width: "150px" }}
              placeholder="Enter charge head"
              value={text}
            />
          ),
      },
      {
        title: "Charge Type",
        dataIndex: "chargeType",
      },
      {
        title: "Applied On",
        dataIndex: "appliesOn",
      },
      {
        title: "Calculation Basis",
        dataIndex: "calculationBasis",
        render: (text, record) => capitaliseWithHyphen(text),
      },
      {
        title: "Pricing Type",
        dataIndex: "pricingType",
      },
      {
        title: "Rate/Item/Slab ID",
        dataIndex: "activeType",
        render: (text, record) =>
          record?.rate || record?.percentage || record?.slabId,
      },
    ],
    [calculationBasisList, rateSlabList]
  );

  const slabColumns = useMemo(
    () => [
      {
        title: "Min Value",
        dataIndex: "minValue",
      },
      {
        title: "Max Value",
        dataIndex: "maxValue",
      },
      {
        title: "Rate",
        dataIndex: "value",
      },
      {
        title: "Rate Type",
        dataIndex: "rateType",
      },
    ],
    [calculationBasisList]
  );

  return (
    <>
      <Collapse collapsible={"header"} defaultActiveKey={["1"]}>
        <Panel header="Rate Charges" key="1">
          <Table
            columns={columns}
            dataSource={rateCharges}
            rowKey="_id"
            className="rate-charge-table"
            pagination={false}
          />
        </Panel>
      </Collapse>
    </>
  );
};

export default RateCharges;
