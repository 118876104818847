/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import packetIcon from "../../../assests/images/packetIcon.svg";
import HubIcon from "../../../assests/images/HubIcon.svg";
import weightIcon from "../../../assests/images/weightLogo.svg";
import countIcon from "../../../assests/images/countLogo.svg";
import guideIcon from "../../../assests/images/guide.svg";
import checkIcon from "../../../assests/images/SVG/check.svg";
import crossIcon from "../../../assests/images/deleteRed.svg";
import packetContents from "../../../assests/images/packetContents.svg";
import shipmentTruck from "../../../assests/images/SVG/packetSurface.svg";
import planeIcon from "../../../assests/images/planeicon.svg";
import noPacketsIcon from "../../../assests/images/SVG/nopackets.svg";
import { capitaliseWithHyphen, isValidDocumentNum } from "../../../utils";
import { Button, Row, Col, Input, Spin, message, Popconfirm } from "antd";
import urls from "../../../api/urls";
import API from "../../../api";
import "./style.scss";
const { GET_CP_PACKETS, CREATE_PACKETS, GET_PACKET_LIST } = urls;

const PacketingCP = () => {
  const [packetingData, setPacketingData] = useState([]);
  const [parentHubName, setParentHubName] = useState(null);
  const [loading, setLoading] = useState(false);
  const fromPremiseId = JSON.parse(localStorage.getItem("userId"));
  useEffect(() => {
    // let moockData = {
    //   parentHubName: "BENGALURU HSR HUB",
    //   packetBarcodes: null,
    //   createPacketDtos: [
    //     {
    //       packetBarcode: null,
    //       shipmentsAdded: null,
    //       shipmentsAddedWithWeight: [
    //         {
    //           awbNo: 23001200000047,
    //           weight: 0.9,
    //         },
    //       ],
    //       scannedShipment: {
    //         packetId: null,
    //         status: null,
    //         scannedItem: [],
    //       },
    //       service: "standard",
    //       travelBy: "surface",
    //       toPremise: 212,
    //       weight: 5.4,
    //       status: null,
    //       premiseName: "MAHADEVAPURA HUB ",
    //       packetingGuideName: "Bengaluru Hsr-mahadevapura Packet",
    //     },
    //   ],
    // };
    getPacketData();
  }, []);

  const getPacketData = async () => {
    setLoading(true);
    let response = await API.Api(GET_CP_PACKETS).getWithUserNPremiseHeader();
    if (response.status) {
      setLoading(false);
      let data = response?.response?.data;
      setParentHubName(data?.parentHubName);
      setPacketingData(
        data?.createPacketDtos?.map((item) => ({
          ...item,
          scannedShipment: {
            packetId: null,
            status: null,
            scannedItem: [],
          },
        }))
      );
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.message || "Internal server error"
      );
    }
  };

  const packetIdChange = (e, idx) => {
    let data = packetingData;
    data[idx].scannedShipment.packetId = e.target.value;
    setPacketingData(data);
  };

  const createPacket = (idx) => {
    let data = packetingData;
    let packetId = data[idx].scannedShipment.packetId;
    if (isValidDocumentNum(packetId, "packet")) {
      data[idx].scannedShipment.status = "Created";
      setPacketingData([...data]);
    } else {
      message.error(`${packetId} is not a valid packet id.`);
    }
  };

  const hitScan = (val, idx, currentScanNum) => {
    let barcodePresent = val?.scannedShipment?.scannedItem?.some(
      (item) => item.awbNo == currentScanNum
    );
    let barcodeEligible = null;
    let barcodeIndex = -1;

    val?.shipmentsAddedWithWeight?.some((item, index) => {
      if (item.awbNo == currentScanNum) {
        barcodeEligible = item;
        barcodeIndex = index;
        return true;
      }
      return false;
    });

    if (barcodePresent) {
      message.error("Shipment already present in packet.");
    } else {
      if (barcodeEligible) {
        setTimeout(() => scanBarCode(barcodeEligible, barcodeIndex, idx), 100);
      } else {
        message.error(`${currentScanNum} is not eligible for this packet.`);
      }
    }
  };

  const scanBarCode = (val, childIdx, parentIdx) => {
    let data = packetingData;
    data[parentIdx].scannedShipment.scannedItem.push(val);
    data[parentIdx].shipmentsAddedWithWeight.splice(childIdx, 1);
    setPacketingData([...data]);
  };

  const deleteShipment = (idx, scanIdx, val) => {
    let data = packetingData;
    data[idx].scannedShipment.scannedItem.splice(scanIdx, 1);
    data[idx].shipmentsAddedWithWeight.push(val);
    setPacketingData([...data]);
  };

  const finalizePacket = async (val) => {
    const payload = {
      packetBarcode: val?.scannedShipment?.packetId,
      service: val?.service,
      travelBy: val?.travelBy,
      toPremiseId: val?.toPremise,
      fromPremiseId: fromPremiseId?.userInfo?.premiseId,
      packetShipments: val?.scannedShipment?.scannedItem,
    };
    let response = await API.Api(CREATE_PACKETS).postIdHeader(payload);
    if (response.status) {
      getPacketData();
    } else {
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  return (
    <div className="master-main-container p-1">
      <Spin spinning={loading}>
        {packetingData?.length === 0 ? (
          <p className={"no-packets"}>
            <>
              <div className="center">
                <div>
                  <img src={noPacketsIcon} style={{ margin: "30px auto" }} />
                  <p>No eligible shipments</p>
                </div>
              </div>
            </>
          </p>
        ) : (
          packetingData?.map((val, idx) => {
            return (
              <>
                <Row>
                  <Col span={9} className="cards">
                    <span className="fw-300 mr-2">
                      <img src={HubIcon} /> Hub
                    </span>

                    {val.travelBy === "air" ? (
                      <img src={planeIcon} style={{ width: "25px" }} />
                    ) : (
                      <img src={shipmentTruck} style={{ width: "25px" }} />
                    )}
                    <p className="ml-1">
                      {capitaliseWithHyphen(parentHubName)}
                    </p>

                    <Row style={{ marginBottom: "16px" }}>
                      <Col span={6}>
                        <Row className="row-one">
                          {" "}
                          <img src={weightIcon} />
                          <span className="ml-1">Weight</span>
                        </Row>
                        <Row className="row-two">
                          {val?.weight?.toFixed(2)}kg
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row className="row-one">
                          {" "}
                          <img src={countIcon} />
                          <span className="ml-1">Count</span>
                        </Row>
                        <Row className="row-two">
                          {val?.shipmentsAddedWithWeight?.length}
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row className="row-one guide-name">
                          {" "}
                          <img src={guideIcon} />
                          <span className="ml-1">Packeting guide name</span>
                        </Row>
                        <Row className="row-two">
                          {capitaliseWithHyphen(val?.packetingGuideName)}
                        </Row>
                      </Col>
                    </Row>
                    <Row className="row-one">
                      <Col span={12}>
                        {" "}
                        <img src={packetContents} /> Eligibe packet contents
                      </Col>
                    </Row>
                    <Row className="content-items">
                      <Col span={24}>
                        <Row className="content-table-header">
                          <Col span={15} className="awb-col">
                            AWB No.
                          </Col>
                          <Col span={9}>Destination</Col>
                        </Row>
                        <Row className="awb-destiny-row">
                          <Col className="awb-col-items" span={24}>
                            {val?.shipmentsAddedWithWeight?.map(
                              (item, index) => {
                                return (
                                  <Row
                                    className="space-between"
                                    key={index + Math.random()}
                                    style={{
                                      borderBottom: "1px solid #ddd",
                                    }}
                                  >
                                    <Col span={12}>{item?.awbNo}</Col>
                                    <Col span={12}>
                                      {capitaliseWithHyphen(val?.premiseName)}
                                    </Col>
                                  </Row>
                                );
                              }
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Popconfirm
                      title={
                        <>
                          <p> Packet ID</p>
                          <Input
                            placeholder="Enter packet id"
                            bordered
                            className="bag-num-input"
                            onChange={(e) => {
                              packetIdChange(e, idx);
                            }}
                            onKeyPress={(e) => {
                              if (/[^0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                              if (e.target.value.length === 11) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </>
                      }
                      onConfirm={() => {
                        createPacket(idx);
                      }}
                      okText="CREATE"
                      cancelText="CANCEL"
                    >
                      <div className="close">
                        <Button
                          type="primary"
                          className="save-btn"
                          disabled={packetingData?.some(
                            (item) => item.scannedShipment?.status === "Created"
                          )}
                        >
                          CREATE PACKET
                        </Button>
                      </div>
                    </Popconfirm>
                  </Col>
                  {val?.scannedShipment?.status === "Created" && (
                    <Col span={9} className="cards">
                      <div
                        className="space-between"
                        style={{ marginTop: "-1rem" }}
                      >
                        <div>
                          <span>
                            <img src={packetIcon} /> Packet Id
                          </span>

                          <Input
                            disabled
                            value={val?.scannedShipment?.packetId}
                          />
                        </div>
                        <div>
                          <span className="fw-300 mr-2">
                            <img src={HubIcon} /> Hub
                          </span>

                          {val.travelBy === "air" ? (
                            <img src={planeIcon} style={{ width: "25px" }} />
                          ) : (
                            <img
                              src={shipmentTruck}
                              style={{ width: "25px" }}
                            />
                          )}
                          <p className="ml-1">
                            {capitaliseWithHyphen(parentHubName)}
                          </p>
                        </div>
                      </div>

                      <Row style={{ marginBottom: "16px" }}>
                        <Col span={7}>
                          <Row className="row-one">
                            {" "}
                            <img src={weightIcon} />
                            <span className="ml-1">Weight</span>
                          </Row>
                          <Row className="row-two">
                            {val?.weight?.toFixed(2)}kg
                          </Row>
                        </Col>
                        <Col span={7}>
                          <Row className="row-one">
                            {" "}
                            <img src={countIcon} />
                            <span className="ml-1">Count</span>
                          </Row>
                          <Row className="row-two">
                            {val?.scannedShipment?.scannedItem?.length}
                          </Row>
                        </Col>
                        <Col span={10}>
                          <Row className="row-one guide-name">
                            {" "}
                            <img src={guideIcon} />
                            <span className="ml-1">Packeting guide name</span>
                          </Row>
                          <Row className="row-two">
                            {capitaliseWithHyphen(val?.packetingGuideName)}
                          </Row>
                        </Col>
                        <Col span={12} style={{ marginTop: "-1rem" }}>
                          <Input
                            placeholder="Enter AWB number to scan"
                            //style={{ width: "81%", margin: "10px auto" }}
                            maxLength={14}
                            onKeyPress={(e) => {
                              if (/[^0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onPressEnter={async (e) => {
                              // eslint-disable-next-line no-constant-condition
                              let currentScanNum = e.target.value;
                              if (e.key == "Enter") {
                                hitScan(val, idx, currentScanNum);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="row-one" style={{ marginTop: "-0.5rem" }}>
                        <Col span={12}>
                          {" "}
                          <img src={packetContents} />
                          Packet contents
                        </Col>
                      </Row>
                      <Row className="content-items">
                        <Col span={24}>
                          <Row className="content-table-header">
                            <Col span={15} className="awb-col">
                              AWB No.
                            </Col>
                            <Col span={9}>Destination</Col>
                          </Row>
                          <Row className="awb-destiny-row">
                            <Col className="awb-col-items" span={24}>
                              {val?.scannedShipment?.scannedItem?.map(
                                (item, scanIndex) => {
                                  return (
                                    <Row
                                      className="space-between"
                                      key={scanIndex + Math.random()}
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                      }}
                                    >
                                      <Col span={12}>
                                        {item?.awbNo}{" "}
                                        <img
                                          src={crossIcon}
                                          alt="delete"
                                          style={{ height: "15px" }}
                                          className="pointer"
                                          onClick={() =>
                                            deleteShipment(idx, scanIndex, item)
                                          }
                                        />
                                      </Col>
                                      <Col span={12}>
                                        {capitaliseWithHyphen(val?.premiseName)}
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <div className="close">
                        <Button
                          type="primary"
                          className="save-btn"
                          onClick={() => finalizePacket(val)}
                          disabled={
                            val?.scannedShipment?.scannedItem?.length === 0
                          }
                        >
                          FINALIZE PACKET
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </>
            );
          })
        )}
      </Spin>
    </div>
  );
};
export default PacketingCP;
