/* eslint-disable no-unused-vars */
/* eslint-disable indent */
// import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, Form, Button, message, Spin } from "antd";
import axios from "axios";
import "./style.scss";
import "../../commonStyles/crossButton.scss";
import crossButton from "../../assests/images/CrossButton.svg";
import DoxSummary from "./DoxModal/index";
import NonDoxModal from "./NonDoxModal/index";
import { APIs, getToken } from "../../utils/worker";
import API from "../../api";
import urls from "../../api/urls";

const ViewBookingSummaryEdit = ({
  visible,
  allAppData,
  setEditTest,
  editTest,
  setIsModalOpen,
  eyeButtonState,
  setEyeButtonState,
  inputState,
  setInputState,
  bookingType,
  handleCancel = () => {},
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const summaryDate = allAppData?.bookingTime?.split(" ", 1)[0];

  useEffect(() => {
    form.setFieldsValue({
      senderName: allAppData?.addressDetails?.senderName,
    });
  }, []);
  let token = getToken();

  const onFinishHandler = async (e) => {
    setEditTest(true);
    setLoading(true);
    setIsModalOpen(false);
    setInputState(true);
    let newdata = {
      addressDetailsRequestDto: {
        senderMobileNumber:
          +e?.senderPhoneNumber ||
          allAppData?.addressDetails?.senderMobileNumber,
        receiverMobileNumber:
          +e?.receiverPhoneNumber ||
          allAppData?.addressDetails?.receiverMobileNumber,
        senderGst: e?.senderGstNumber || allAppData?.addressDetails?.senderGst,
      },
      shipmentDetailsRequestDto: {
        ewayBillNumber:
          e?.ewayBill || allAppData?.shipmentDetails?.ewayBillNumber,
      },
    };
    try {
      let res = await axios.put(
        `${APIs.baseURL}/shipment-service/v1/shipments/details/${allAppData?.awbNumber}`,
        { ...newdata },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.data) {
        message.success("Updated successfully");
        setLoading(false);
      }
    } catch (error) {
      message.error(error.response.data.message || "Booking Failed");
      setLoading(false);
    }
  };

  const { CANCEL_BOOKING_INNO } = urls;

  // useEffect(() => {
  //   cancelBooking();
  // }, []);

  // delete
  // Booking cancelled successfully
  // 24007270001394

  const cancelBooking = async (docNum = 24007270001394) => {
    setLoading(true);

    let response = await API.Api(
      CANCEL_BOOKING_INNO + `${docNum}`
    ).deleteHeader("44344344");
    if (response?.status) {
      setLoading(false);
      message.success("Success");
      // setSetsubmitRespons(response);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error.");
    }
  };

  console.log(allAppData, "allAppData");

  return (
    <div>
      <Modal
        title="Booking Summary Edit"
        className="booking-summary-edit"
        open={visible}
        width={1000}
        footer={false}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={<img src={crossButton} />}
      >
        <Spin spinning={loading || !allAppData}>
          <Form
            form={form}
            onFinish={onFinishHandler}
            layout="vertical"
            scrollToFirstError={true}
          >
            {allAppData?.shipmentDetails?.type?.toUpperCase() === "DOX" ? (
              <DoxSummary
                allAppData={allAppData}
                summaryDate={summaryDate}
                form={form}
                editTest={editTest}
                setInputState={setInputState}
                setEditTest={setEditTest}
                eyeButtonState={eyeButtonState}
                setEyeButtonState={setEyeButtonState}
                visible={visible}
                bookingType={bookingType}
              />
            ) : allAppData?.shipmentDetails?.type?.toUpperCase() ===
              "NON-DOX" ? (
              <NonDoxModal
                allAppData={allAppData}
                summaryDate={summaryDate}
                form={form}
                editTest={editTest}
                setEditTest={setEditTest}
                setInputState={setInputState}
                visible={visible}
                bookingType={bookingType}
              />
            ) : null}
            <div className="button-footer">
              <Button
                htmlType="submit"
                className="cancel-btn"
                style={{ position: "block" }}
                // disabled={inputState}
              >
                CANCEL BOOKING
              </Button>
              <Button
                htmlType="submit"
                className="submit-edit hide-button"
                style={{ position: "block" }}
                disabled={inputState}
              >
                SUBMIT
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

// ViewBookingSummary.propTypes = {
//   second: PropTypes.third,
// };

export default ViewBookingSummaryEdit;
