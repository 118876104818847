import React from "react";
import { Row, Col } from "antd";

const LeadQuoteDetails = ({ quoteData }) => {
  // {
  //     "id": 15,

  //     "ratecardName": "testt",
  //     "billingConfigId": 17,
  //     "selectedType": "Default",
  //     "existingId": null,
  //     "existingRateCardName": null,
  //     "quotationName": "test",
  //     "declaredAmount": 5000,
  //     "cpName": "jaya",
  //     "name": "tests",
  //     "officialNumber": 1235678987,
  //     "officialEmailId": "swarnamamatha26@gmail.com",
  //     "rateCardType": "Regular Slab",
  //     "rateCardId": null
  // }
  return (
    <div style={{ backgroundColor: "white" }}>
      {/* Lead Details */}
      <div>
        <span
          className="master-title"
          style={{ padding: "0.8rem 1rem 0.3rem 1rem", fontSize: "16px" }}
        >
          Lead details
        </span>
      </div>
      <hr className="divider" />
      <div>
        <Row className="space-between ml-2">
          <Col span={3}>
            <div>
              <p>CP Name</p>
              <b>{quoteData?.cpName || "N/A"}</b>
            </div>
          </Col>
          <Col span={3}>
            <div>
              <p>Approx budget</p>
              <b>{quoteData?.declaredAmount || "N/A"}</b>
            </div>
          </Col>
          <Col span={3}>
            <div>
              <p>Contact person name</p>
              <b>{quoteData?.contactPersonName || "N/A"}</b>
            </div>
          </Col>
          <Col span={3}>
            <div>
              <p>GSTIN</p>
              <b>{quoteData?.gst || "N/A"}</b>
            </div>
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>

      {/* Quote Details */}
      <div className="mt-1">
        <span
          className="master-title"
          style={{ padding: "0.8rem 1rem 0.3rem 1rem", fontSize: "16px" }}
        >
          Quote details
        </span>
      </div>
      <hr className="divider" />

      <div>
        <Row className="space-between ml-2">
          <Col span={3}>
            <div>
              <p>Quote name</p>
              <b>{quoteData?.quotationName || "N/A"}</b>
            </div>
          </Col>
          <Col span={3}>
            <div>
              <p>Financial Year</p>
              <b>{quoteData?.ratecard?.financialYear || "N/A"}</b>
            </div>
          </Col>
          <Col span={3}>
            <div>
              <p>FOV</p>
              <b>{quoteData?.ratecard?.fov || "N/A"}</b>
            </div>
          </Col>
          <Col span={3}>
            <div>
              <p>Insurance</p>
              <b>{quoteData?.ratecard?.insurance || "N/A"}</b>
            </div>
          </Col>
          <Col span={8}></Col>
        </Row>

        <Row className="space-between ml-2 mt-1 mb-2">
          <Col span={3}>
            <div>
              <p>Handling charges</p>
              <b>{quoteData?.ratecard?.handlingCharges || "N/A"}</b>
            </div>
          </Col>
          <Col span={3}>
            <div>
              <p>Premium service charges</p>
              <b>{quoteData?.ratecard?.premiumServiceCharges || "N/A"}</b>
            </div>
          </Col>
          <Col span={3}>
            <div>
              <p>Fuel service charges</p>
              <b>{quoteData?.ratecard?.fuelServiceCharges || "N/A"}</b>
            </div>
          </Col>
          <Col span={3}>
            <div>
              <p>COD charges</p>
              <b>{quoteData?.ratecard?.codCharges || "N/A"}</b>
            </div>
          </Col>
          <Col span={8}></Col>
        </Row>

        {/* <Row className="space-between ml-2 mt-1 mb-2">
          <Col span={3}>
            <div>
              <p>Overall percentage change</p>
              <b>{quoteData?.overallChange || "0%"}</b>
            </div>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default LeadQuoteDetails;
