/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Radio,
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  Select,
  message,
  Spin,
  Alert,
  Modal,
} from "antd";
import axios from "axios";
// import { APIs } from "../../utils/worker";
import API from "../../api";
import urls from "../../api/urls";
import { APIs } from "../../utils/worker";
import BottomButton from "../CpOnboardingNew/BottomButton";
import Heading from "../CpOnboardingNew/Heading";
import {
  capitaliseWithHyphen,
  checkAlphabets,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../utils";
import { ReactComponent as Reason } from "../../assests/images/SVG/check.svg";
import KycDetailsWOGST from "../CpOnboardingNew/kycDetailsWOgst";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";

const {
  POST_GST,
  POST_WO_GST,
  UPDATE_CP_KYC,
  VERIFY_KYC_OTP,
  CP_KYC_SAVE,
  GET_KYC_DETAILS,
  GET_HUB_PINCODE,
  CREATE_BRANCH,
  VERIFY_PHONE_NUM,
  SEND_SMS_PHONE,
  VERIFY_PHONE_OTP,
} = urls;

const BranchAdmin = ({
  handleStepsChange,
  goBackStep,
  branchData,
  patchBranchDetails,
  branchId,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [isGstRegister, setIsGstRegister] = useState(true);
  const [panBusinees, setPanBusinees] = useState(false);
  const [aadharVerify, setAadharVerify] = useState(false);
  const [panVerify, setPanVerify] = useState(false);
  const [postResponse, setPostResponse] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [phnOtpVerified, setPhnOtpVerified] = useState(false);

  const gstListDetails = [
    {
      gstNo: "29AAFCH1620B1Z0",
      panNumber: "AAFCH1620B",
      companyName: "Cuatrogen Private Limited",
      businessType: null,
      adressline1:
        "16TH CROSS, KASTURI ARENA, 222, ROOPENA AGRAHARA,BOMANNAHALLI, BANGALORE",
      adressline2: null,
      pincode: 560078,
      state: "Karnataka",
      city: "Bengaluru",
      country: "India",
    },
  ];

  useEffect(() => {
    setExistingGSTData();
  }, []);

  const setExistingGSTData = () => {
    form.setFieldsValue({
      gstNo: "29AAFCH1620B1Z0",
      panNumber: "AAFCH1620B",
      companyName: "Cuatrogen Private Limited",
      businessType: null,
      adressline1:
        "16TH CROSS, KASTURI ARENA, 222, ROOPENA AGRAHARA,BOMANNAHALLI, BANGALORE",
      adressline2: null,
      pincode: 560078,
      state: "Karnataka",
      city: "Bengaluru",
      country: "India",
    });
  };

  useEffect(() => {
    if (branchData) {
      const data = branchData?.branchAdminDetails;
      form.setFieldsValue({
        name: data?.name || null,
        mailId: data?.mailId || null,
        phoneNumber: data?.phoneNumber || null,
        gstNo: data?.gstNo || null,
        panNumber: data?.panNumber || null,
        companyName: data?.companyName || null,
        businessType: data?.businessType || null,
        adharNo: data?.adharNo || null,
        signatoryPanNumber: data?.signatoryPanNumber || null,
        adressline1: data?.adressline1 || null,
        adressline2: data?.adressline2 || null,
        pincode: data?.pincode || null,
        state: data?.state || null,
        city: data?.city || null,
        country: data?.country || null,
      });
    }
  }, [branchData]);

  const typeOfBusinessOptions = [
    {
      value: "Individual",
      label: "Individual",
    },
    {
      value: "Proprietorship",
      label: "Proprietorship",
    },
    {
      value: "Private limited",
      label: "Private limited",
    },
    {
      value: "Public limited",
      label: "Public limited",
    },
    {
      value: "LLP",
      label: "LLP",
    },
    {
      value: "Partnership",
      label: "Partnership",
    },
  ];

  const showMessageError = (errorMessage) => {
    message.error({
      content: <>{errorMessage}</>,
      onClick: (event) => {
        if (event.target.className.baseVal == "") {
          message.destroy();
        }
      },
      duration: 10, // Optional, how long the message is displayed (in seconds)
    });
  };

  const radioChange = (e) => {
    setPostResponse(null);
    if (e.target.value == false && postResponse === null) {
      PostWithPan();
      // form.setFieldsValue({
      //   panNumber: null,
      //   gstNo: null,
      // })
    }
    if (e.target.value) {
      setExistingGSTData();
    }

    setIsGstRegister(e.target.value);
  };

  const PostWithPan = async () => {
    let response = await API.Api(`${POST_WO_GST}`).postWithPrmiseHeader({
      KYC: {
        isGSTRegistered: false,
      },
    });
    if (response?.status) {
      setPostResponse(response?.response?.data?.data);
    } else {
      message.error(response?.response.data?.message || "Newtork error");
    }
  };

  const onFinish = async (values) => {
    setLoading(true);

    //const branchId = localStorage.getItem("branchId");
    if (branchId) {
      patchBranchDetails({ branchAdminDetails: { ...values } });
    } else {
      let response = await API.Api(`${CREATE_BRANCH}`).postIdHeader(values);
      if (response.status) {
        setLoading(false);
        message.success(
          response?.response?.data?.message || "Branch created successfully"
        );
        localStorage.setItem("branchId", response?.response?.data?.branchId);
        handleStepsChange();
      } else {
        setLoading(false);
        showMessageError(
          response?.response?.data?.message || "Internal server error."
        );
      }
    }
  };

  const checkGST = async (event, name) => {
    form.resetFields(["panNumber", "companyName", "businessType"]);

    // if (event.target.value.length === 15) {
    //   setLoading(true);
    //   let payload = {
    //     KYC: {
    //       isGSTRegistered: true,
    //       GST: event.target.value,
    //     },
    //   };
    //   let response = await API.Api(POST_GST).postWithPrmiseHeader(payload);
    //   if (response?.status) {
    //     setLoading(false);
    //     let res = response?.response?.data?.data.customerData?.kyc;
    //     setPostResponse(response?.response?.data?.data);
    //     form.setFieldsValue({
    //       businessPAN: response?.response?.data?.data.customerData.businessPAN,
    //       nameOfCompany: res?.nameasPerGst,
    //       adressLine1: res.addressAsperGst.gstAdressLine1,
    //       adressLine2: res.addressAsperGst.gstAdressLine2,
    //       pincode: res.addressAsperGst.gstPinCode,
    //       state: res.addressAsperGst.gstState,
    //       city: res.addressAsperAadhar.city,
    //       country: res.addressAsperGst.gstCountry,
    //     });
    //   } else {
    //     setLoading(false);
    //     showMessageError(response?.response?.data?.message || "Network error");
    //   }
    // }
  };

  function handleBusinessType(params) {
    if (!isGstRegister) {
      if (params == "Proprietorship" || params == "Individual") {
        setPanBusinees(false);
      } else {
        setPanBusinees(true);
      }
    }
  }

  const validateWrapper = (event) => {
    if (checkNumbervalue(event) || checkSpace(event)) {
      event.preventDefault();
    }
  };

  const handlePincodeInput = async (values) => {
    if (values.target.value.length > 5) {
      setLoading(true);
      let response = await API.Api(
        GET_HUB_PINCODE + values.target.value
      ).getWithPremiseHeader();
      if (response?.status) {
        setLoading(false);
        form.setFieldValue("state", response?.response?.data?.stateName);
        form.setFieldValue("city", response?.response?.data?.city);
      } else {
        message.error(response?.response?.data.message || "Invalid pincode");
        setLoading(false);
        form.setFieldValue("state", null);
        form.setFieldValue("city", null);
      }
    }
  };

  function AddressDetail() {
    return (
      <>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item
            label="Address line 1"
            name="adressline1"
            rules={[
              {
                required: true,
                message: "Please enter address line 1",
              },
            ]}
          >
            <Input placeholder="Enter address line 1" />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item
            label="Address line 2"
            name="adressline2"
            rules={[
              {
                required: false,
                message: "Please enter address line 2",
              },
            ]}
          >
            <Input placeholder="Enter address line 2" />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item
            label="Pincode"
            name="pincode"
            rules={[
              {
                required: true,
                message: "Please enter pincode",
              },
              () => ({
                validator(rule, value = "") {
                  if (value.length > 0 && value.length < 6) {
                    return Promise.reject("Pincode should be 6 digits");
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            onKeyPress={validateWrapper}
          >
            <Input
              placeholder="Enter pincode"
              maxLength={6}
              onChange={handlePincodeInput}
            />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item label="State" name="state">
            <Input
              placeholder="Enter state" // disabled
            />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item label="City" name="city">
            <Input
              placeholder="Enter city" // disabled
            />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item label="Country" name="country">
            <Input
              placeholder="Enter country" // disabled
            />
          </Form.Item>
        </Col>
      </>
    );
  }

  ///Verification///
  const checkPhnNumExists = async (values) => {
    setLoading(true);

    let response = await API.Api(VERIFY_PHONE_NUM).post({
      contactNumber: values,
    });
    if (response?.status) {
      setLoading(false);
      if (response?.response?.data === false) {
        message.error("Phone number already exists");
      } else {
        // sentPhoneOtp(values);
        setShowVerify(true);
        setLoading(false);
      }
    } else {
      message.error("Internal server error.");
      setLoading(false);
    }
  };
  const sentPhoneOtp = async () => {
    setLoading(true);

    let phoneNum = form.getFieldValue("phoneNumber");

    let response = await API.Api(SEND_SMS_PHONE).post({
      mobileNo: phoneNum,
    });

    if (response?.response?.data === true) {
      setLoading(false);
      message.info("OTP has sent the given number");
    } else {
      setLoading(false);
      message.error("Failed to send the OTP");
    }
  };
  const handleCloseModal = () => {
    setVisible(false);
  };
  const verifyPhoneOtp = async (values) => {
    setLoading(true);

    let phoneNum = form.getFieldValue("phoneNumber");
    let response = await API.Api(VERIFY_PHONE_OTP).post({
      mobileNo: phoneNum,
      otp: values.otp,
    });

    if (response?.response?.data === true) {
      setLoading(false);

      message.success("Phone number verified successfully");
      setPhnOtpVerified(true);
      setVisible(false);
      setShowVerify(false);
    } else {
      setLoading(false);
      message.error("Wrong OTP");
    }
  };
  ///--///

  return (
    <Spin spinning={loading}>
      <div className="cp-detail-wrapper">
        <Heading title={"Branch Admin"} />
        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          className="global-form"
          scrollToFirstError
          // disabled={!viewOnly}
        >
          <Row gutter={24} className="global-form-row">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  onKeyPress={(event) => {
                    if (checkAlphabets(event)) {
                      event.preventDefault();
                    }
                  }}
                  rules={[
                    {
                      message: "Please enter name",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter name"
                    maxLength={40}
                    // disabled={getData ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="mailId"
                  label="Email-ID"
                  type="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter E-mail",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input placeholder="Enter E-mail" />
                </Form.Item>
              </Col>
              {/* <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                <Form.Item
                  label={<span style={{ visibility: "hidden" }}>.</span>}
                >
                  <Button
                    className="btn-style cancel-btn"
                    onClick={() => {
                      setVisible(true);
                      sentPhoneOtp();
                    }}
                    disabled={!showVerify}
                  >
                    Send OTP
                  </Button>
                </Form.Item>
              </Col> */}

              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone number"
                  hasFeedback={phnOtpVerified}
                  //validateStatus={!phnOtpVerified ? "success" : "warning"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile number",
                    },
                    {
                      pattern: /^([1-9]{1}[0-9]{9}){0,10}$/g,
                      message: "Invalid mobile number",
                    },
                    () => ({
                      validator(rule, value = "") {
                        if (value) {
                          if (!phnOtpVerified) {
                            return Promise.reject("Enter a valid otp");
                          } else {
                            return Promise.resolve();
                          }
                        }
                      },
                      validateTrigger: "onSubmit",
                    }),
                  ]}
                >
                  <Input
                    onKeyPress={(e) => {
                      if (/[^0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                      if (e.target.value.length >= 10) {
                        e.preventDefault();
                      }
                    }}
                    placeholder="Enter phone number"
                    maxLength={10}
                    onChange={(e) => {
                      if (e.target.value.length === 10) {
                        checkPhnNumExists(e.target.value);
                      } else {
                        // phnOtpVerified
                        setPhnOtpVerified(false);
                        setShowVerify(false);
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item
                  label={<span style={{ visibility: "hidden" }}>.</span>}
                >
                  <Button
                    className="btn-style cancel-btn"
                    onClick={() => {
                      setVisible(true);
                      sentPhoneOtp();
                    }}
                    disabled={!showVerify}
                  >
                    Send OTP
                  </Button>
                </Form.Item>
              </Col>
              <Col span={1}></Col>

              <Col span={11}>
                <Form.Item label="Use Existing GST" name="isGSTRegistered">
                  <Radio.Group
                    onChange={radioChange}
                    defaultValue={isGstRegister}
                    value={isGstRegister}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {isGstRegister ? (
                <>
                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="GSTIN"
                      name="gstNo"
                      rules={[
                        {
                          message: "Please enter GSTIN",
                          required: true,
                        },
                        {
                          pattern:
                            /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/g,
                          message: "Invalid GSTIN",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter GSTIN"
                        maxLength={15}
                        onChange={(e) => checkGST(e, "gstNumber")}
                        // suffix={checkStatus ? <Reason /> : null}
                        onKeyPress={(e) => {
                          if (/[^A-Za-z0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}></Col>
                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Business PAN number "
                      name="panNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter business pan number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter business pan number"
                        maxLength={12}
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Name of company"
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter company name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter name of company" />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Type of business"
                      name="businessType"
                      rules={[
                        {
                          required: true,
                          message: "Please select type of business",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Please select an option"
                        onChange={handleBusinessType}
                        options={typeOfBusinessOptions}
                      />
                    </Form.Item>
                  </Col>
                  <AddressDetail />
                  <Col md={24} lg={24} xl={24} sm={24} xs={24}>
                    <Heading title={"KYC Details (Aadhaar Details"} />
                  </Col>

                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Aadhar number"
                      name="adharNo"
                      help={
                        viewOnly && (
                          <div style={{ margin: "5px 0 15px 0" }}>
                            <InfoCircleOutlined />
                            {" Please fill all the above fields "}
                          </div>
                        )
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please enter aadhar number",
                        },
                      ]}
                      onKeyPress={validateWrapper}
                    >
                      <Input
                        placeholder="Enter aadhar number"
                        maxLength={12}
                        suffix={
                          viewOnly && (
                            <Button
                              className="save-btn mt-05 mb-05"
                              disabled={!aadharVerify}
                            >
                              SEND OTP
                            </Button>
                          )
                        }
                        // disabled
                        onChange={(e) => {
                          if (e.target.value.length > 11) {
                            setAadharVerify(true);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="OTP"
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: "Please enter OTP",
                        },
                      ]}
                      onKeyPress={validateWrapper}
                    >
                      <Input
                        placeholder="Enter OTP"
                        maxLength={6}
                        suffix={
                          viewOnly && (
                            <Button
                              className="save-btn mt-05 mb-05"
                              disabled={!panVerify}
                              onClick={handleVerifyOTP}
                            >
                              VERIFY OTP
                            </Button>
                          )
                        }
                      />
                    </Form.Item>
                  </Col> */}
                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Signatory PAN number GST"
                      name="signatoryPanNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter signatory PAN number",
                        },
                      ]}
                    >
                      <Input
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                        placeholder="Enter signatory PAN number"
                        maxLength={12}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <KycDetailsWOGST
                    form={form}
                    address={<AddressDetail />}
                    postResponse={postResponse}
                    validateWrapper={validateWrapper}
                    setPostResponse={setPostResponse}
                    typeOfBusinessOptions={typeOfBusinessOptions}
                  />

                  <Row
                    gutter={12}
                    className="mt-2"
                    justify={"end"}
                    style={{ flex: "auto" }}
                  >
                    <BottomButton goBackStep={goBackStep} />
                  </Row>
                </>
              )}
            </Row>
            <hr
              className="divider"
              style={{ marginTop: "20px", marginBottom: "30px" }}
            />
            {isGstRegister && (
              <Row
                gutter={12}
                className="mt-2"
                justify={"end"}
                style={{ flex: "auto" }}
              >
                <BottomButton goBackStep={goBackStep} />
              </Row>
            )}
          </Row>
        </Form>
      </div>
      <Modal
        title={null}
        open={visible}
        maskClosable={false}
        onCancel={handleCloseModal}
        width={400}
        loading={false}
        className="staff-onboard-modal"
        footer={
          <>
            <div className="d-flex flex-end">
              <Button
                htmlType="submit"
                onClick={modalForm.submit}
                className="save-btn ml-1"
              >
                SUBMIT
              </Button>
            </div>
          </>
        }
      >
        <div>
          <Form
            form={modalForm}
            onFinish={verifyPhoneOtp}
            layout={"vertical"}
            className="global-form"
          >
            <Row
              justify=""
              span={24}
              className="global-form-row mt-2"
              gutter={20}
              style={{ padding: "0 30px" }}
            >
              <Col span={24}>
                <Form.Item
                  name="otp"
                  label="OTP"
                  rules={[
                    {
                      message: "OTP is required",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter OTP"
                    onChange={"handlePincodeInput"}
                    maxLength={6}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      {/* <Modal
        title={null}
        open={visible}
        maskClosable={false}
        onCancel={handleCloseModal}
        width={400}
        loading={false}
        className="staff-onboard-modal"
        footer={
          <>
            <div className="d-flex flex-end">
              <Button
                htmlType="submit"
                onClick={modalForm.submit}
                className="save-btn ml-1"
              >
                SUBMIT
              </Button>
            </div>
          </>
        }
      >
        <div>
          <Form
            form={modalForm}
            onFinish={verifyEmailOtp}
            layout={"vertical"}
            className="global-form"
          >
            <Row
              justify=""
              span={24}
              className="global-form-row mt-2"
              gutter={20}
              style={{ padding: "0 30px" }}
            >
              <Col span={24}>
                <Form.Item
                  name="otp"
                  label="OTP"
                  rules={[
                    {
                      message: "OTP is required",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter OTP"
                    onChange={"handlePincodeInput"}
                    maxLength={6}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal> */}
    </Spin>
  );
};

export default BranchAdmin;
