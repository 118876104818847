/* eslint-disable react/no-unknown-property */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Form, Col, Input, Tooltip, AutoComplete, Button } from "antd";
import { checkNumbervalue } from "../../../../utils";
import useClientAndDepartment from "../../CrediteBooking/useClientAndDepartment ";

export const SenderFormFields = ({
  form,
  userInfo,
  resetForm,
  handleOnEnter,
  handleAPIKeyDown,
}) => {
  const [value, setValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedArea, setSelectedArea] = useState({});

  const {
    pinCodeServiceableStatus,
    areaList,
    getDeliveryPincode,
    resetCredit,
  } = useClientAndDepartment(userInfo, form);

  useEffect(() => {
    if (resetForm) {
      resetCredit();
    }
  }, [resetForm]);

  useEffect(() => {
    setFilteredOptions(areaList);
  }, [areaList]);

  const handleSelect = (value, option) => {
    const newobj = {
      value: option?.value,
      key: option?.key,
    };
    setSelectedArea(newobj);
    setValue(value);
  };

  const onChangeHandler = (e) => {
    setValue(e);
    const newobj = {
      value: e,
      key: null,
    };
    setSelectedArea(newobj);
    if (e !== "") {
      const filteredData = areaList.filter((item) =>
        item.label.toLowerCase().includes(e.toLowerCase())
      );
      setFilteredOptions(filteredData);
    } else {
      setFilteredOptions(areaList);
    }
    return e;
  };

  return (
    <>
      <Col lg={6} sm={6}>
        <Form.Item
          name="senderPincode"
          label="Sender Pincode"
          rules={[
            {
              required: true,
              message: "Enter sender pincode",
            },
            () => ({
              validator(rule, value = "") {
                if (value.length > 0 && value.length < 6) {
                  return Promise.reject("Pincode should be 6 digits");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          validateStatus={pinCodeServiceableStatus?.status}
          help={pinCodeServiceableStatus?.msg}
          onKeyPress={(event) => {
            if (event.code == "Enter") {
              handleOnEnter(event);
            } else if (checkNumbervalue(event)) {
              event.preventDefault();
            }
          }}
        >
          <Input
            onChange={(e) => {
              form.setFieldsValue({ senderArea: null });
              getDeliveryPincode(e);
            }}
            placeholder="Enter sender PIN code"
            maxLength={6}
            suffix={
              <span
                className={
                  pinCodeServiceableStatus?.serviceability === "Regular"
                    ? "green"
                    : pinCodeServiceableStatus?.serviceability === "Premium"
                    ? "orange"
                    : "red"
                }
              >
                <Tooltip
                  title={
                    pinCodeServiceableStatus?.city?.length > 8
                      ? pinCodeServiceableStatus?.city
                      : null
                  }
                >
                  {pinCodeServiceableStatus?.city?.length > 8
                    ? pinCodeServiceableStatus?.city?.slice(0, 8) + "..."
                    : pinCodeServiceableStatus?.city}
                  {pinCodeServiceableStatus?.city ? "-" : null}
                  {pinCodeServiceableStatus?.stateCode}
                </Tooltip>
              </span>
            }
          />
        </Form.Item>
      </Col>
      <Col lg={6} sm={6}>
        <Form.Item
          lg={6}
          sm={6}
          label="Sender area"
          name="senderArea"
          placeholder="Search area"
          rules={[
            {
              required: true,
              message: "Select area",
            },
          ]}
        >
          <AutoComplete
            showSearch
            onSelect={handleSelect}
            onKeyDown={(event) =>
              handleAPIKeyDown(
                event,
                filteredOptions,
                "senderArea",
                "label",
                "senderArea"
              )
            }
            onChange={onChangeHandler}
            placeholder="Select area"
          >
            {filteredOptions?.map((option) => (
              <AutoComplete.Option
                key={option?.value}
                value={option?.label}
                disabled={option?.disabled}
              >
                {option?.label}
              </AutoComplete.Option>
            ))}
          </AutoComplete>
        </Form.Item>
      </Col>
    </>
  );
};
