/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Radio,
  Select,
  Spin,
} from "antd";
import RateCardTable from "../../LeadGenerationCP/AllocateRateCard/RateCardTable";
import { cashBookingRateCardData } from "../../Master/RateCard/CashbookingRateCard/cashBookingRateCardData";
import { defaultRateCard } from "../../Master/RateCard/CashbookingRateCard/defaultRateCard";
import API from "../../../api";
import urls from "../../../api/urls";
// import "./style.scss";
import rate_card_back from "../../../assests/images/onbording/rate-card-back.svg";
import Heading from "../../../components/Heading";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
const { GET_CLIENT_APPROVAL_RATECARD, LEAD_CLIENT_QUOTATION_APPROVAL } = urls;

export const viewRateCard = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();

  const [standardDox, setStandardDox] = useState([]);
  const [standardNonDox, setStandardNonDox] = useState([]);
  const [fastTrackSurface, setFastTrackSurface] = useState([]);
  const [fastTrackAir, setFastTrackAir] = useState([]);
  const [fastTrackNonDox, setFastTrackNonDox] = useState([]);
  const [valuePlusDox, setValuePlusDox] = useState([]);
  const [valuePlusNonDox, setValuePlusNonDox] = useState([]);
  const [rateAdded, setRateAdded] = useState(false);
  const [rateDisable, setRateDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quoteData, setQuoteData] = useState(null);
  const [financialYearData, setFinancialYearData] = useState([]);

  const [financialYear, setFinancialYear] = useState(
    `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`
  );

  const setWeightSlabData = (data, title) => {
    switch (title) {
      case "standardDocs":
        setStandardDox(data);
        break;
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackSurface":
        setFastTrackSurface(data);
        break;
      case "fastTrackAir":
        setFastTrackAir(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusDox":
        setValuePlusDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  useEffect(() => {
    getViewRateCardById(params?.id);
  }, []);

  function handleTemplateResponse(response) {
    if (response) {
      let temp = response?.response?.data?.services;
      let standardTemp = [];
      let fastTrackTemp = [];
      let valuePlusTemp = [];

      if (temp) {
        temp.map((e) => {
          switch (e.serviceType) {
            case "STANDARD":
              {
                standardTemp.push(e.docs);
              }
              break;
            case "FAST TRACK":
              {
                fastTrackTemp.push(e.docs);
              }
              break;
            case "VALUE PLUS":
              {
                valuePlusTemp.push(e.docs);
              }
              break;
          }
        });
      }
      if (standardTemp) {
        standardTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let standardDoxTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        standardDoxTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({
                            title,
                            value: parseFloat(value).toFixed(2),
                          })),
                        });
                      });
                    }
                  }
                });
                setStandardDox(standardDoxTemp);
                break;
              }

              case "NON_DOX":
                {
                  let standardNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        standardNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          standardNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });

                  setStandardNonDox(standardNonDoxTemp);
                }
                break;
            }
          });
        });
      }
      if (fastTrackTemp) {
        fastTrackTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let fastTrackDoxSurfaceTemp = [];
                let fastTrackDoxAirTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        fastTrackDoxSurfaceTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                      break;
                    }
                    case "Air": {
                      item.rates.map((rateValue, index) => {
                        fastTrackDoxAirTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                    }
                  }
                });
                setFastTrackSurface(fastTrackDoxSurfaceTemp);
                setFastTrackAir(fastTrackDoxAirTemp);
                break;
              }

              case "NON_DOX":
                {
                  let fastTrackNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        fastTrackNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          fastTrackNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });
                  setFastTrackNonDox(fastTrackNonDoxTemp);
                }
                break;
            }
          });
        });
      }
      if (valuePlusTemp) {
        valuePlusTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let valuePlusDoxTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        valuePlusDoxTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                    }
                  }
                });
                setValuePlusDox(valuePlusDoxTemp);
                break;
              }

              case "NON_DOX":
                {
                  let valuePlusNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        valuePlusNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          valuePlusNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });
                  setValuePlusNonDox(valuePlusNonDoxTemp);
                }
                break;
            }
          });
        });
      }
    }
  }

  const getViewRateCardById = async (id) => {
    setLoading(true);
    let response = await API.Api(
      GET_CLIENT_APPROVAL_RATECARD + id
    ).getWithPremiseIdHeader();
    if (response?.status) {
      setLoading(false);
      // console.log(response, "res");
      handleTemplateResponse(response);
      let temp = response?.response?.data;
      setQuoteData(temp);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const handleApprove = async () => {
    setLoading(true);
    let response = await API.Api(
      LEAD_CLIENT_QUOTATION_APPROVAL + params?.id
    ).putIdHeader({
      quotationId: quoteData?.quoteId,
      status: "approved",
    });
    if (response?.status) {
      setLoading(false);
      let temp = response?.response?.data;
      // console.log(temp, "temp approve");
      navigate("/all-approvals");
      message.success(
        response?.response?.data?.message || "Approved succcessfully"
      );
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const handleReject = async () => {
    setLoading(true);
    let response = await API.Api(
      LEAD_CLIENT_QUOTATION_APPROVAL + quoteData?.quoteId
    ).putIdHeader({
      quotationId: quoteData?.quoteId,
      status: "rejected",
    });
    if (response?.status) {
      setLoading(false);
      let temp = response?.response?.data;
      // console.log(temp, "temp reject");
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  // console.log(quoteData, "quoteData");

  return (
    <Spin spinning={loading}>
      <div className="new-quote-container">
        <Heading title={"View rate card"} />
        <div className="new-quote-form-container">
          <div>
            <span
              className="master-title"
              style={{ padding: "0.8rem 1rem 0.3rem 1rem", fontSize: "16px" }}
            >
              Lead details
            </span>
          </div>
          <hr className="divider" />
          <div>
            <Row className="space-between ml-2">
              <Col span={3}>
                <div>
                  <p>Client name</p>
                  <b>{quoteData?.clientName || "N/A"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>Approx budget</p>
                  <b>{quoteData?.businessVolume || "N/A"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>Contact person name</p>
                  <b>{quoteData?.contactPersonName || "N/A"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>GSTIN</p>
                  <b>{quoteData?.gst || "N/A"}</b>
                </div>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
          <div className="mt-1">
            <span
              className="master-title"
              style={{ padding: "0.8rem 1rem 0.3rem 1rem", fontSize: "16px" }}
            >
              Quote details
            </span>
          </div>
          <hr className="divider" />

          <div>
            <Row className="space-between ml-2">
              <Col span={3}>
                <div>
                  <p>Quote name</p>
                  <b>{quoteData?.ratecard?.ratecardName || "N/A"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>Financial Year</p>
                  <b>{quoteData?.ratecard?.financialYear || "N/A"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>FOV</p>
                  <b>{quoteData?.ratecard?.fov || "N/A"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>Insurance</p>
                  <b>{quoteData?.ratecard?.insurance || "N/A"}</b>
                </div>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row className="space-between ml-2 mt-1 mb-2">
              <Col span={3}>
                <div>
                  <p>Handling charges</p>
                  <b>{quoteData?.ratecard?.handlingCharges || "N/A"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>Premium service charges</p>
                  <b>{quoteData?.ratecard?.premiumServiceCharges || "N/A"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>Fuel service charges</p>
                  <b>{quoteData?.ratecard?.fuelServiceCharges || "N/A"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>COD charges</p>
                  <b>{quoteData?.ratecard?.codCharges || "N/A"}</b>
                </div>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row className="space-between ml-2 mt-1 mb-2">
              <Col span={3}>
                <div>
                  <p>Overall percentage change</p>
                  <b>{"0% " || "N/A"}</b>
                </div>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: "5px" }}>
            <RateCardTable
              standardDocsData={standardDox}
              standardNonDocsData={standardNonDox}
              fastTrackSurfaceData={fastTrackSurface}
              fastTrackAirData={fastTrackAir}
              fastTrackNonDoxData={fastTrackNonDox}
              valuePlusDoxData={valuePlusDox}
              valuePlusNonDoxData={valuePlusNonDox}
              handleChangeDocs={(e, data) => setWeightSlabData(e, data)}
              handleDynamicSelect={null}
              templateType={"view"}
            />
          </div>

          {params?.status?.toLowerCase() !== "approved" &&
          params?.status?.toLowerCase() !== "rejected" ? (
            <div className="d-flex flex-end p-1">
              <Button
                className="cancel-btn ml-1 "
                onClick={() => navigate("/view-pincode-mapping/" + params?.id)}
              >
                BACK
              </Button>
              <Button className="cancel-btn ml-1" onClick={handleReject}>
                REJECT
              </Button>
              {!rateDisable && (
                <Button
                  htmlType="submit"
                  className="save-btn ml-1"
                  onClick={handleApprove}
                >
                  APPROVE
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </Spin>
  );
};

export default viewRateCard;
