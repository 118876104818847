/* eslint-disable no-unused-vars */
// eslint-disable-next-line max-len
import cashIcon from "../assests/images/cashIcon.svg";
import accountBookingIcon from "../assests/images/AccountBookingsvg.svg";
import viewBookingIcon from "../assests/images/viewBookingIcon.svg";
import master_setting from "../assests/images/master-setting.svg";
import message_alerts from "../assests/images/message-alerts.svg";
import onboarding_setting from "../assests/images/onboarding-setting.svg";
import configurationIcon from "../assests/images/SVG/configure-setting.svg";
import hub_in_scan from "../assests/images/SVG/hub-in-scan.svg";
import hub_inbound from "../assests/images/SVG/hub-inbound.svg";
import last_mile_icon from "../assests/images/lastMileLogo.svg";
import QRScanner from "../assests/images/QRScanner.svg";
import audit from "../assests/images/SVG/audit-dash.svg";
import report from "../assests/images/SVG/report-img.svg";
import constants from "../constants/constants";
import extend from "../assests/images/extentEway.svg";
import trip_scheduler from "../assests/images/trip-scheduler.svg";

import product from "../assests/images/stationaryProduct.svg";
import product_request from "../assests/images/product-request.svg";
import my_order from "../assests/images/my-order.svg";

const { ROLES } = constants;
const { CP_ADMIN, HO_ADMIN, HUB_ADMIN, ROLE_HOMARKETING } = ROLES;
const currentEnv = process.env.REACT_APP_ENV;

let dashboardMenuList = [
  {
    icon: cashIcon,
    title: "Retail Booking",
    id: "cash-booking",
    additionalClass: "",
    route: "/retail-booking",
    role: [CP_ADMIN],
  },
  {
    icon: accountBookingIcon,
    title: "Credit Booking",
    id: "account-booking",
    additionalClass: "",
    route: "/credit-booking",
    role: [CP_ADMIN],
  },
  {
    icon: viewBookingIcon,
    title: "View Booking",
    id: "view-booking",
    additionalClass: "",
    route: "/viewBooking",
    role: [CP_ADMIN],
  },
  // {
  //   icon: last_mile_icon,
  //   title: "Last Mile Ops",
  //   id: "last-mile-operations",
  //   additionalClass: "",
  //   route: "/last-mile-operations",
  //   role: [CP_ADMIN],
  // },
  {
    icon: master_setting,
    title: "Master Setting",
    id: "master-setting",
    additionalClass: "",
    route: "/masters",
    role: [HO_ADMIN, HUB_ADMIN],
  },
  {
    icon: message_alerts,
    title: "Message & Alert",
    id: "messageAlert",
    additionalClass: "",
    route: "/message-and-alerts",
    role: [HO_ADMIN],
  },
  {
    icon: onboarding_setting,
    title: "Onboard Setting",
    id: "onboarding-settings",
    additionalClass: "",
    route: "/onboarding-settings",
    role: [CP_ADMIN, HO_ADMIN, HUB_ADMIN, ROLE_HOMARKETING],
  },
  {
    icon: hub_inbound,
    title: "Hub Outbound",
    id: "middle-mile",
    additionalClass: "",
    route: "/middle-mile",
    role: [HUB_ADMIN],
  },

  {
    icon: hub_in_scan,
    title: "Hub Inbound",
    id: "hub-in-scan",
    additionalClass: "",
    route: "/hub-in-scan",
    role: [HUB_ADMIN],
  },
  {
    icon: configurationIcon,
    title: "Config Guide",
    id: "configuration-guide",
    additionalClass: "",
    route: "/configuration-guide",
    role: [HUB_ADMIN],
  },
  {
    icon: audit,
    title: "Audit",
    id: "view-audit",
    additionalClass: "",
    route: "/manifest-audit",
    role: [HO_ADMIN, HUB_ADMIN],
  },
  // {
  //   icon: QRScanner,
  //   title: "Vehicle Check-In",
  //   id: "view-audit",
  //   additionalClass: "",
  //   route: null,
  //   role: [HUB_ADMIN],
  // },
  {
    icon: report,
    title: "Reports",
    id: "report",
    additionalClass: "",
    route: "/reports",
    role: [HUB_ADMIN],
  },
  {
    icon: extend,
    title: "Extend E-way",
    id: "e-way",
    additionalClass: "",
    route: "/extend-eway",
    role: [HUB_ADMIN],
  },
  // {
  //   icon: trip_scheduler,
  //   title: "Trip Scheduler",
  //   id: "trip-scheduler",
  //   additionalClass: "",
  //   route: "/trip-scheduler",
  //   role: [HUB_ADMIN],
  // },
  {
    icon: product,
    title: "Products",
    id: "stationary-product",
    additionalClass: "",
    route: "/stationery",
    role: [HO_ADMIN],
  },

  {
    icon: product,
    title: "Request Products",
    id: "stationery",
    additionalClass: "",
    route: "/product-list",
    role: [CP_ADMIN, HUB_ADMIN],
  },
  {
    icon: my_order,
    title: "My Orders",
    id: "my-order",
    additionalClass: "",
    route: "/order-history",
    role: [CP_ADMIN, HUB_ADMIN],
  },
];

if (currentEnv !== "qa") {
  dashboardMenuList = dashboardMenuList.slice(1);
}

export { dashboardMenuList };
