/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Spin,
  message,
  Modal,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import Smcs_New_Logo from "../../assests/images/Smcs_New_Logo.png";
import Smcs_Loader from "../../assests/images/loader-new.gif";
import { WarningTwoTone } from "@ant-design/icons";
import axios from "axios";
// import Cookies from "universal-cookie";
import { capitaliseWithHyphen } from "../../utils";
import API from "../../api";
import { APIs } from "../../utils/worker";
import {
  getAccountService,
  loginUser,
} from "../../actionsMethods/cashBookingMethods";

import "antd/dist/antd.css";
import "./index.scss";
import constants from "../../constants/constants";
import CompanySelection from "./LoginComponents/CompanySelection";
import LoginForm from "./LoginComponents/LoginForm";
const NormalLoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ADMIN_ROLES = [constants.ROLES.CP_ADMIN, constants.ROLES.CP_OPERATOR];

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [spId, setSpId] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isAdminOperator, setIsAdminOperator] = useState(false);
  const { baseURLInnofulfill } = APIs;

  const currentEnv = process.env.REACT_APP_ENV;

  const getLoginResponse = useSelector(
    (state) => state.appReducer.loginUser,
    shallowEqual
  );

  const getAccountServiceResponse = useSelector(
    (state) => state?.appReducer?.getAccountServiceReducer,
    shallowEqual
  );

  const { error, fetching, result } = getLoginResponse;

  const {
    fetching: accountFetching,
    result: accountResult,
    error: accountError,
  } = getAccountServiceResponse;

  function addHours(date, hours) {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    return date;
  }
  useEffect(() => {
    const setLocalStore = async () => {
      const selectedCompany = JSON.parse(
        localStorage.getItem("selectedCompany")
      );

      if (selectedCompany) {
        // Redirect to the dashboard
        window.location.href = "/dashboard";
        return;
      }

      if (result !== null) {
        const isAdmin =
          (result.roles.some((role) => ADMIN_ROLES.includes(role)) &&
            currentEnv !== "dev") ||
          false;

        setIsAdminOperator(isAdmin);
        localStorage.setItem("userinfo", JSON.stringify(result));
        setSpId(result.spId);
        const object = { name: result.accessToken, email: result.username };
        document.cookie = "userToken=" + JSON.stringify(object);
        let sec = result?.expiresIn;
        let hour = parseInt(sec / 3600);
        const result1 = addHours(new Date(), hour);
        localStorage.setItem(
          "loggedOutTime",
          JSON.stringify(result1.getTime())
        );
        if (isAdmin) {
          let response = await API.Api(
            `${baseURLInnofulfill}/company/${result.spId}`
          ).get();
          if (response.status) {
            const comp = response?.response?.data?.data;
            setCompanies(
              comp.map((item, id) => ({
                key: item.CompanyID,
                value: item.AliasCompanyName,
                label: capitaliseWithHyphen(item.AliasCompanyName),
              }))
            );
          } else {
            setErrorMessage(error?.message);
          }
        }
      } else {
        setErrorMessage(error?.message);
      }
    };
    setLocalStore();
  }, [result, error, fetching]);

  useEffect(() => {
    if (result) {
      dispatch(getAccountService(result?.username, result?.accessToken));
    }
  }, [result]);

  useEffect(() => {
    if (accountError) {
      setLoading(false);
      message.error(accountError?.message);
    }
  }, [accountError]);

  useEffect(() => {
    if (accountResult !== null) {
      localStorage.setItem("userId", JSON.stringify(accountResult));
      localStorage.setItem(
        "userName",
        JSON.stringify(accountResult?.userInfo?.firstName)
      );
      if (!isAdminOperator) {
        handleRedirectDashboard();
      }
      // navigate("/dashboard");
    }
  }, [getAccountServiceResponse]);

  const onFinish = async (values) => {
    delete values.remember;
    // emailInput.value.trim()
    const payload = {
      ...values,
      username: values?.username?.trim()?.toLowerCase(),
    };
    dispatch(loginUser(payload));
  };

  const handleForgot = () => {
    navigate("/forgotPassword");
  };

  // const loginHit = () => {

  //   for (let i = 0; i < data.length - 1; i++) {
  //     axios
  //       .post(
  //         "https://devhubopsapis.innofulfill.com/smcs-webapp/api/users/sign-up",
  //         {
  //           username: data[i],
  //           email: data[i],
  //           password: "Admin@123",
  //         }
  //       )
  //       .then((res) => {})
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };
  const handleRedirectDashboard = () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setLoading(true);
      localStorage.setItem("selectedCompany", JSON.stringify(selectedCompany));
      let url = localStorage.getItem("viewClient");
      if (url !== undefined && url !== null) {
        window.location.replace(url);
      } else {
        window.location.replace("/dashboard");
      }
      setLoading(false);
    } else {
      message.error("Please enter all the details");
    }
  };

  return (
    <div className="login-main-container">
      <Spin
        spinning={fetching || loading || accountFetching}
        size="large"
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <Row>
          <Col
            className="img-parent"
            xl={12}
            lg={12}
            md={12}
            sm={0}
            xs={24}
            // className={"logibg-container"}
          >
            <div className="img-div"></div>
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            // style={{
            //   margin: "0 auto",
            //   position: "relative",
            //   backgroundColor: " #ffffff",
            // }}
            className="login-parent"
          >
            <div className="login-container">
              <div className="small-screen-logo">
                <img
                  src={Smcs_New_Logo}
                  alt="smcs-logo"
                  className="login-img"
                />
              </div>
              {spId === null ? (
                <LoginForm
                  errorMessage={errorMessage}
                  onFinish={onFinish}
                  handleForgot={handleForgot}
                />
              ) : (
                isAdminOperator && (
                  <CompanySelection
                    companies={companies}
                    handleRedirectDashboard={handleRedirectDashboard}
                    setSelectedCompany={setSelectedCompany}
                  />
                )
              )}
              <div
                className="footer"
                style={{
                  position: "sticky",
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  // border: "2px solid red",
                  // width: "70%",
                }}
              >
                <p>
                  Copyright © {new Date().getFullYear()} Shree Maruti Integrated
                  Logistics Limited
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
      {/* <Modal title="" open={true} footer={null}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <WarningTwoTone style={{ fontSize: "10rem" }} />
          <p style={{ color: "red", fontSize: "2rem" }}>Under Maintenance!</p>
        </div>
      </Modal> */}
    </div>
  );
};

export default NormalLoginForm;
