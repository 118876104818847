/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Form,
  message,
  Radio,
  Select,
  Collapse,
  Table,
  Tabs,
} from "antd";
import moment from "moment";
import RateCharges from "./RateCharges";
import RateMatrix from "./RateMatrix";
import API from "../../../api";
import urls from "../../../api/urls";
import CashRateCard from "./CashRateCard";
import AccountRateCard from "./AccountRateCard";
const {
  CREATE_RATE_CARD,
  GET_HUB_PINCODE,
  GET_RATE_CARD_QUOTATION,
  UPDATE_LEAD_CP_DETAILS,
} = urls;

const NewRateCard = ({
  getData,
  handleStepsChange = () => {},
  cpPinCodeData,
}) => {
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [form] = Form.useForm();
  const [clickedButton, setClickedButton] = useState(null);
  const [rateMatrixData, setRateMatrixData] = useState({});
  const [rateChargesData, setRateChargesData] = useState([]);
  const [stateCode, setStateCode] = useState(null);
  const [quotationRateCharges, setquotationRateCharge] = useState([]);
  const [quotationRateMatrixs, setQuotationRateMatrix] = useState({});
  const [leadId, setLeadId] = useState(localStorage.getItem("leadCPId"));

  const dateFormat = "DD-MM-YYYY";
  const billingCycle = [
    { label: "Weekly", value: "WEEKLY" },
    { label: "Monthly", value: "MONTHLY" },
    { label: "Quaterly", value: "QUATERLY" },
  ];

  useEffect(() => {
    if (getData?.cpLeadQuotation) {
      getQuotationDetail(getData?.cpLeadQuotation);
    }
  }, [getData]);

  const getQuotationDetail = async (quotation) => {
    const res = await API.Api(GET_RATE_CARD_QUOTATION + quotation).get();
    if (res?.status) {
      const data = res?.response?.data;
      form.setFieldsValue({
        fromDate: moment(data?.fromDate, dateFormat),
        toDate: moment(data?.toDate, dateFormat),
        billingCycle: data?.billingCycle,
        type: data?.type,
        commitedBusiness: data?.commitedBusiness,
      });
      setquotationRateCharge(data?.charges);
      setQuotationRateMatrix(data?.rate);
      setLeadId(data?.leadId);
    } else {
      message.error(res?.response?.data?.message || "Can not set rates.");
    }
  };

  const rateCardTypeOptions = [
    {
      label: "Account",
      value: "ACCOUNT",
    },
    {
      label: "Cash",
      value: "CASH",
    },
  ];
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  const getFinancialEndDate = () => {
    const currentYear = moment().year();
    const financialEndYear =
      moment().month() >= 3 ? currentYear + 1 : currentYear; // Fiscal year ending March 31st
    return moment(`${financialEndYear}-03-31`, "YYYY-MM-DD");
  };

  useEffect(() => {
    getStateCode();
  }, []);

  const getStateCode = async () => {
    let response = await API.Api(
      GET_HUB_PINCODE + (getData?.cpDetail?.pincode || cpPinCodeData)
    ).getWithPremiseHeader();
    if (response?.status) {
      setStateCode(response?.response?.data?.stateCode);
    } else {
      message.error(
        response?.response?.data?.message || "State code fetch failed."
      );
    }
  };
  const goBackStep = () => {};

  const setRateCharge = (data) => {
    setRateChargesData(data);
  };

  const setRateMatrix = (data) => {
    setRateMatrixData({
      dox: {
        air: {
          standard: convertAsRateEngine(data.standardDox),
          fastTrack: convertAsRateEngine(data.fastTrackAir),
          valuePlus: convertAsRateEngine(data.valuePlusDox),
        },
        surface: {
          standard: convertAsRateEngine(data.standardDox),
          fastTrack: convertAsRateEngine(data.fastTrackSurface),
          valuePlus: convertAsRateEngine(data.valuePlusDox),
        },
      },
      nonDox: {
        air: {
          standard: convertAsRateEngine(data.standardNonDox),
          fastTrack: convertAsRateEngine(data.fastTrackNonDox),
          valuePlus: convertAsRateEngine(data.valuePlusNonDox),
        },
        surface: {
          standard: convertAsRateEngine(data.standardNonDox),
          fastTrack: convertAsRateEngine(data.fastTrackNonDox),
          valuePlus: convertAsRateEngine(data.valuePlusNonDox),
        },
      },
    });
  };

  const convertAsRateEngine = (originalArray) => {
    return originalArray.map((item) => {
      const { title, value, minValue } = item;

      const [maxValueStr] = title
        .replace("Up to ", "")
        .replace("Gms", "")
        .split("-");
      const maxValue = parseInt(maxValueStr) || undefined;

      const transformedItem = {
        minValue: minValue,
        maxValue: maxValue,
        unit: "grams",
        local:
          parseFloat(value.find((obj) => obj.title === "LOCAL")?.value) || 0,
        withinZone:
          parseFloat(
            value.find((obj) => obj.title === "WITHIN STATE")?.value
          ) || 0,
        myZone1:
          parseFloat(value.find((obj) => obj.title === "My Zone 1")?.value) ||
          0,
        myZone2:
          parseFloat(value.find((obj) => obj.title === "My Zone 2")?.value) ||
          0,
        myZone3:
          parseFloat(value.find((obj) => obj.title === "My Zone 3")?.value) ||
          0,
        myZone4:
          parseFloat(value.find((obj) => obj.title === "My Zone 4")?.value) ||
          0,
        metro:
          parseFloat(value.find((obj) => obj.title === "METRO")?.value) || 0,
        roi:
          parseFloat(
            value.find((obj) => obj.title === "REST OF INDIA")?.value
          ) || 0,
        specialLocation:
          parseFloat(
            value.find((obj) => obj.title === "SPECIAL LOCATION")?.value
          ) || 0,
      };

      if (item.design) {
        transformedItem.volume = item.design;
      }

      return transformedItem;
    });
  };

  const saveRateCard = async (val) => {
    const checkBtn = clickedButton === "saveAndContinueButton";
    const payload = {
      ...val,
      stateCode,
      commitedBusiness: parseInt(val.commitedBusiness),
      userId: localStorage.getItem("leadCPId"),
      toDate: val?.toDate?._i,
      fromDate: moment(val?.fromDate?._i).format("DD-MM-YYYY"),
      type: "ACCOUNT",
      commission: {
        unit: "INR",
        rateType: "PERCENTAGE",
        slab: [
          {
            minValue: 0,
            value: 20,
          },
        ],
      },
      invoiceType: "CP-TO-HO",
      rate: rateMatrixData,
      charges: rateChargesData?.map((item) => ({
        chargesHead: item?.head,
        rateType: item?.chargeType,
        pricingType: item?.pricingType,
        appliesOn: item?.appliesOn,
        calculationBasis: item?.calculationBasis,
        rate: parseInt(item?.rate),
        slabId: item?.slabId,
        percentage: parseInt(item?.percentage),
      })),
    };
    let res;
    res = getData?.cpLeadQuotation
      ? await API.Api(`${CREATE_RATE_CARD}/${getData.cpLeadQuotation}`).patch(
          payload
        )
      : await API.Api(CREATE_RATE_CARD).post(payload);
    if (res?.status) {
      const response1 = await API.Api(
        UPDATE_LEAD_CP_DETAILS + "/" + localStorage.getItem("leadCPId")
      ).putCustomHeader(
        {
          cpLeadQuotation: res?.response?.data?.name,
          leadId: localStorage.getItem("leadCPId"),
        },
        { "USER-ID": userId?.userInfo?.userId }
      );
      if (response1?.status) {
        message.success("Quotation added successfully.");
        if (checkBtn) {
          handleStepsChange(1);
        }
      } else {
        message.error(response1?.response?.data?.message);
      }
    } else {
      message.error(res?.response?.data?.message || "Internal server error.");
    }
  };

  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      items={[
        {
          label: "Cash Rate",
          key: 1,
          children: (
            <CashRateCard
              saveRateCard={saveRateCard}
              dateFormat={dateFormat}
              disabledDate={disabledDate}
              getFinancialEndDate={getFinancialEndDate}
              rateCardTypeOptions={rateCardTypeOptions}
              billingCycle={billingCycle}
              setRateCharge={setRateCharge}
              quotationRateCharges={quotationRateCharges}
              setRateMatrix={setRateMatrix}
              quotationRateMatrixs={quotationRateMatrixs}
              setClickedButton={setClickedButton}
              goBackStep={goBackStep}
            />
          ),
        },
        {
          label: "Account Rate",
          key: 2,
          children: (
            <AccountRateCard
              saveRateCard={saveRateCard}
              dateFormat={dateFormat}
              disabledDate={disabledDate}
              getFinancialEndDate={getFinancialEndDate}
              rateCardTypeOptions={rateCardTypeOptions}
              billingCycle={billingCycle}
              setRateCharge={setRateCharge}
              quotationRateCharges={quotationRateCharges}
              setRateMatrix={setRateMatrix}
              quotationRateMatrixs={quotationRateMatrixs}
              setClickedButton={setClickedButton}
              goBackStep={goBackStep}
            />
          ),
        },
      ]}
    />
  );
};
export default NewRateCard;
