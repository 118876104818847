/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Col, Row, Spin, Button, message } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getPacketConfiguration,
  putPacketConfiguration,
  putPacketConfigurationClear,
} from "../../../actionsMethods/masterMethods";
import cross_button from "../../../assests/images/CrossButton.svg";
import {
  checkNumbervalue,
  checkSpecialCharacter,
  checkSpace,
} from "../../../utils";
import "./style.scss";
const PacketConfigurationModal = ({ setIsModalOpen, isModalOpen }) => {
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const getPacketConfigurationResponse = useSelector(
    (state) => state?.masterReducer?.getPacketConfigurationReducer,
    shallowEqual
  );
  const putPacketConfigurationResponse = useSelector(
    (state) => state?.masterReducer?.putPacketConfigurationReducer,
    shallowEqual
  );

  const { fetching, result } = getPacketConfigurationResponse;
  const { fetching: fetchData, result: fetchResult } =
    putPacketConfigurationResponse;

  const [payload, setPayload] = useState();
  const [inputOneAccess, setInputOneAccess] = useState(false);
  const [inputTwoAccess, setInputTwoAccess] = useState(false);

  useEffect(() => {
    dispatch(getPacketConfiguration());
  }, []);
  useEffect(() => {
    const maxPacket = result?.find((data) => data?.id === 1);
    const maxShipment = result?.find((data) => data?.id === 2);
    if (result) {
      form.setFieldsValue({
        maxPckt: maxPacket?.value,
        minShipment: maxShipment?.value,
      });
    }
  }, [result]);
  const handleSave = () => {
    dispatch(putPacketConfiguration(payload));
    setIsModalOpen(false);
    setInputOneAccess(false);
    setInputTwoAccess(false);
    message.success("Update successful");
    dispatch(putPacketConfigurationClear());
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(getPacketConfiguration());
    setInputOneAccess(false);
    setInputTwoAccess(false);
  };

  const maxPacketHandle = (e) => {
    setPayload({
      id: 1,
      configName: "MAX_WEIGHT",
      value: e.target.value,
    });
    // setInputTwoAccess(true);
  };
  const minShipmentHandle = (e) => {
    setPayload({
      id: 2,
      configName: "LEAST_SHIPMENTS_IN_PACKET",
      value: e.target.value,
    });
    setInputOneAccess(true);
  };
  return (
    <Modal
      title="Packet Configuration"
      open={isModalOpen}
      // onOk={handleSave}
      onCancel={handleCancel}
      maskClosable={false}
      closeIcon={<img src={cross_button} />}
      className="packet-config-container"
      footer={null}
    >
      <Spin spinning={fetching}>
        <Form
          layout={"vertical"}
          className="packet-config-modal"
          form={form}
          onFinish={handleSave}
          autoComplete="off"
        >
          <Row>
            <Col span={14}>
              <Form.Item
                className="max-pkt-container"
                label="Max packet weight (Kg)"
                name="maxPckt"
                onKeyPress={(event) => {
                  if (
                    checkNumbervalue(event) ||
                    checkSpecialCharacter(event) ||
                    checkSpace(event)
                  ) {
                    event.preventDefault();
                  }
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter value",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (parseInt(value.substring(1, 0)) === 0) {
                        return Promise.reject("Value should not be 0");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  className="input-field"
                  maxLength={2}
                  onChange={maxPacketHandle}
                  // disabled={inputOneAccess}
                />
              </Form.Item>
              <Form.Item
                className="max-pkt-container"
                label="Min no. of shipments in a packet"
                name="minShipment"
                onKeyPress={(event) => {
                  if (
                    checkNumbervalue(event) ||
                    checkSpecialCharacter(event) ||
                    checkSpace(event)
                  ) {
                    event.preventDefault();
                  }
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter value",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (parseInt(value.substring(1, 0)) === 0) {
                        return Promise.reject("Value should not be 0");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  className="input-field"
                  maxLength={2}
                  onChange={minShipmentHandle}
                  // disabled={inputTwoAccess}
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="divider" />
          <div className="d-flex flex-end p-1 footer-btn">
            <Button
              onClick={handleCancel}
              className="btn-style cancel-btn btn-one"
            >
              CANCEL
            </Button>
            <Button htmlType="submit" className="btn-style save-btn btn-two">
              SAVE
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PacketConfigurationModal;
