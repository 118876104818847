/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button, Form, message, Select } from "antd";
import "antd/dist/antd.css";
import API from "../../../api";
import urls from "../../../api/urls";

const CompanySelection = ({
  companies,
  handleRedirectDashboard,
  setSelectedCompany,
}) => {
  const { GET_ALL_PINCODES } = urls;
  const [loading, setLoading] = useState(false);

  // @ For Pincodes suggesstion
  async function getAllPincodesForSuggestions(params) {
    setLoading(true);
    let res = await API.Api(GET_ALL_PINCODES).get();
    if (res?.status) {
      setLoading(false);
      const response = res?.response?.data?.data;
      localStorage.setItem("allPincodes", JSON.stringify(response));
    } else {
      setLoading(false);
      message.error("Pincodes fetch error");
    }
  }

  useEffect(() => {
    getAllPincodesForSuggestions();
  }, []);

  return (
    <>
      <div className="login-container-headding">Please select your company</div>
      <Form
        autoComplete="on"
        onFinish={() => {
          handleRedirectDashboard();
        }}
      >
        <div className="login-container-email" style={{ marginTop: 20 }}>
          Company name
        </div>
        <Form.Item
          name="travelBy"
          rules={[
            {
              required: true,
              message: "Select  value",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select company"
            onSelect={(e, l) => setSelectedCompany(l)}
            options={companies}
          />
        </Form.Item>
        <Form.Item>
          <div className="login-container-btndiv">
            <Button
              className="save-btn mt-2"
              size="large"
              htmlType="submit"
              block
            >
              Continue
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default CompanySelection;
