/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Input, Spin, message, Form } from "antd";

import packet_icon from "../../../assests/images/packetIcon.svg";
import hub_icon from "../../../assests/images/HubIcon.svg";
import weight_icon from "../../../assests/images/weightLogo.svg";
import count_icon from "../../../assests/images/countLogo.svg";
import check_icon from "../../../assests/images/SVG/check.svg";
import cross_icon from "../../../assests/images/redCross.svg";
import packet_contents from "../../../assests/images/packetContents.svg";
import shipment_truck from "../../../assests/images/SVG/packetSurface.svg";
import plane_icon from "../../../assests/images/planeicon.svg";
import no_packets_icon from "../../../assests/images/SVG/nopackets.svg";
import ProceedModal from "./proceedModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import urls from "../../../api/urls";
import {
  getPacketListBySatus,
  patchChildShipment,
  patchChildShipmentClear,
} from "../../../actionsMethods/manifestMethods";
import { camelCase, capitalize } from "../../../utils";

import "./style.scss";
import Heading from "../../../components/Heading";

const ScanPacket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [manifestFor, setManifestFor] = useState();
  const [scannedIndex, SetScannedIndex] = useState();
  const [successModalvisible, setSuccessModalvisible] = useState(false);
  const [unScannedAwbs, setUnScannedAwbs] = useState([]);
  const [patchPayload, setPatchPayload] = useState();
  const [apiData, setApiData] = useState();

  const [onChangeFilter, setOnChangeFilter] = useState();
  const [parentAwb, setParentAwb] = useState(null);
  const [reloadState, setReloadState] = useState(true);

  const { fetching: loader1, result: packetList } = useSelector(
    (state) => state.manifestReducer.getPacketListBySatusReducer,
    shallowEqual
  );

  const {
    fetching: loader2,
    result: patchChildResult,
    error,
  } = useSelector(
    (state) => state.manifestReducer.patchChildShipmentReducer,
    shallowEqual
  );

  useEffect(() => {
    setManifestFor(localStorage.getItem("manifestFor"));
  }, [manifestFor]);

  useEffect(() => {
    if (loader2 == false && patchChildResult !== null) {
      message.success("Scanned successfully");
      dispatch(patchChildShipmentClear());
    } else if (error !== null) {
      message.error("Something went wrong");
    }
  }, [loader2]);

  useEffect(() => {
    if (loader2 == false && manifestFor) {
      dispatch(getPacketListBySatus("manifest", manifestFor));
      setOnChangeFilter();
    }
  }, [loader2, manifestFor]);

  useEffect(() => {
    setUnScannedAwbs([]);
  }, [apiData]);

  useEffect(() => {
    if (loader1 == false) {
      addScannedStatus();
    }
    // setApiData(allPackets2);
  }, [loader1]);

  const addScannedStatus = () => {
    let shipmentChildData = packetList?.shipmentDto;
    if (shipmentChildData !== undefined) {
      const updatedData = shipmentChildData?.map((item) => {
        const totalWeight = item?.childShipmentDetails?.reduce(
          (sum, child) => sum + child?.weight,
          0
        );
        const newItem = {
          ...item,
          totalWeight,
          childShipmentDetails: item?.childShipmentDetails?.map((child) => ({
            ...child,
            isScanned: false,
          })),
        };

        return newItem;
      });
      const allPackets = {
        packetsdto: [],
        createPacketDtos: updatedData,
      };
      setApiData(allPackets);
    }
  };

  function onFinish(values) {
    let childShipmentDetails = [];
    let data = apiData?.createPacketDtos?.filter((awb) =>
      awb.awbNumber === values.PId
        ? awb.childShipmentDetails?.map((element) => {
            if (element?.isScanned === false) {
              const arr = unScannedAwbs;
              arr.push(element.childAwbNumber);
              setUnScannedAwbs([...new Set(arr)]);
            } else if (element?.isScanned === true) {
              childShipmentDetails?.push({
                childAwbNumber: element?.childAwbNumber,
              });
              setPatchPayload({ awbNumber: values?.PId, childShipmentDetails });
            }
          })
        : ""
    );

    if (unScannedAwbs?.length !== 0) {
      setSuccessModalvisible(true);
    }

    if (unScannedAwbs?.length === 0) {
      let patchData = {
        awbNumber: values.PId,
        childShipmentDetails,
      };
      dispatch(patchChildShipment(values?.PId, patchData));
      setParentAwb(null);
      setReloadState(!reloadState);
    }
  }

  let barcode = "";
  let interval;

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [apiData]);

  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) {
        const filtered =
          apiData &&
          apiData?.createPacketDtos?.filter((element) => {
            return element?.childShipmentDetails?.find(
              (awb) => awb?.childAwbNumber === +barcode
            );
          });
        let tempFile = parentAwb ? parentAwb : filtered[0]?.awbNumber;
        setParentAwb(tempFile);
        onChangeHandler(barcode, tempFile);
      }

      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  const onChangeHandler = (e, awb) => {
    const findFilter =
      apiData &&
      apiData?.createPacketDtos?.find((items) => items?.awbNumber === awb);

    SetScannedIndex(awb);

    const findChild = findFilter?.childShipmentDetails?.find(
      (item) => Number(item?.childAwbNumber) === Number(+e)
    );

    if (findChild?.awbNumber) {
      const filtered =
        apiData &&
        apiData?.createPacketDtos?.filter((element) => {
          return element?.childShipmentDetails?.find((awb) => {
            if (awb?.childAwbNumber === +e) {
              const scannedStatus = awb;
              scannedStatus.isScanned = true;
              return scannedStatus;
            }
          });
        });
      setApiData({ ...apiData, filtered });
      setOnChangeFilter(filtered[0]);
    } else {
      message.error("Awb number not found in current packet");
    }
  };

  const callOnChangeHandler = (e, awb) => {
    if (e.length > 10) {
      onChangeHandler(e, awb);
    }
  };

  function closeSuccessModal() {
    setSuccessModalvisible(false);
  }

  function handleSuccessOk() {
    dispatch(patchChildShipment(patchPayload?.awbNumber, patchPayload));
    setParentAwb(null);
    setReloadState(!reloadState);
  }

  const handleNext = () => {
    navigate("/scan");
  };

  let totalcount =
    apiData &&
    apiData["createPacketDtos"]?.reduce((counter, obj) => {
      if (
        obj?.type == "non-dox" &&
        obj?.childShipmentDetails?.length > 0 &&
        obj?.childShipmentDetails?.some((e) => e?.bookingStatus === "COMPLETED")
      )
        counter += 1;
      return counter;
    }, 0);

  return (
    <Spin spinning={loader1}>
      <div className="child-add-packet">
        <ProceedModal
          visible={successModalvisible}
          handleCancel={closeSuccessModal}
          handleOk={handleSuccessOk}
          unScannedAwbs={unScannedAwbs}
        />
        <div>
          <div className="container">
            <div className="content-header">
              <Row>
                <Col lg={19}>
                  <Heading title={"Child packet"} />
                </Col>

                <Col lg={5} align="end">
                  Total packet count:
                  <span>{totalcount || 0}</span>
                </Col>
                <hr />
              </Row>
            </div>
            <div className="content-body">
              <Row align="center">
                <p>
                  {totalcount === undefined || totalcount === 0 ? (
                    <>
                      <Row className="no-packets">
                        <img
                          src={no_packets_icon}
                          style={{ margin: "30px auto" }}
                        />
                      </Row>
                      <Row>{"No eligible child packets to be packeted"}</Row>
                    </>
                  ) : (
                    ""
                  )}
                </p>

                {apiData &&
                  apiData["createPacketDtos"]?.map((val, idx) => {
                    if (
                      val?.type == "non-dox" &&
                      val?.childShipmentDetails?.length > 0 &&
                      val.childShipmentDetails?.some(
                        (e) => e?.bookingStatus === "COMPLETED"
                      )
                    ) {
                      return (
                        <>
                          <Form
                            onFinish={onFinish}
                            initialValues={{
                              PId: val?.awbNumber,
                            }}
                            style={{ padding: "5px" }}
                          >
                            <Col
                              lg={8}
                              className={
                                val?.awbNumber === scannedIndex
                                  ? "active-cards cards"
                                  : "cards"
                              }
                            >
                              <Row>
                                <Col span={14}>
                                  <Row className="row-one">
                                    {" "}
                                    <img src={packet_icon} /> AWB Id
                                  </Row>
                                  <Row className="row-two">
                                    <Form.Item name={"PId"}>
                                      <Input
                                        // minLength={11}q
                                        disabled={idx !== scannedIndex}
                                        ref={inputRef}
                                        maxLength={11}
                                      />
                                    </Form.Item>
                                  </Row>
                                </Col>
                                <Col span={8}>
                                  <Row className="row-one">
                                    <Col span={23}>
                                      <img src={hub_icon} /> Hub
                                    </Col>
                                    <Col span={1}>
                                      {manifestFor === "air" ? (
                                        <img
                                          src={plane_icon}
                                          style={{ width: "25px" }}
                                        />
                                      ) : (
                                        <img
                                          src={shipment_truck}
                                          style={{ width: "25px" }}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                  <Row className="row-two">
                                    {capitalize(val?.hubName)}
                                  </Row>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: "25px" }}>
                                <Col span={14}>
                                  <Row className="row-one">
                                    {" "}
                                    <img src={weight_icon} /> Weight
                                  </Row>
                                  <Row className="row-two">
                                    {val?.totalWeight?.toFixed(2)}kg
                                  </Row>
                                </Col>
                                <Col span={7}>
                                  <Row className="row-one">
                                    {" "}
                                    <img src={count_icon} /> Count
                                  </Row>
                                  <Row className="row-two">
                                    {val?.childShipmentDetails?.length}
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Input
                                  placeholder="Enter AWB number to scan"
                                  style={{ width: "81%", margin: "auto" }}
                                  disabled={
                                    onChangeFilter === undefined ||
                                    onChangeFilter?.awbNumber ===
                                      +val?.awbNumber
                                      ? false
                                      : true
                                  }
                                  maxLength={14}
                                  onChange={(e) =>
                                    callOnChangeHandler(
                                      e.target.value,
                                      val?.awbNumber
                                    )
                                  }
                                />
                              </Row>

                              <Row className="row-one">
                                <Col span={12}>
                                  {" "}
                                  <img src={packet_contents} /> Packet contents
                                </Col>
                              </Row>
                              <Row className="content-items">
                                <Col span={2}></Col>
                                <Col span={22}>
                                  <Row className="content-table-header">
                                    <Col span={15} className="awb-col">
                                      Child AWB No.
                                    </Col>
                                    <Col span={9}>Destination</Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="awb-destiny-row">
                                <Col span={2}></Col>
                                <Col className="awb-col-items" span={22}>
                                  {val?.childShipmentDetails?.map(
                                    (awbNum, index) => (
                                      <Row key={index}>
                                        <Col lg={14}>
                                          {awbNum?.bookingStatus ==
                                            "COMPLETED" && (
                                            <p>
                                              {awbNum?.childAwbNumber}
                                              {awbNum?.isScanned ? (
                                                <img src={check_icon} />
                                              ) : (
                                                <img src={cross_icon} />
                                              )}
                                            </p>
                                          )}
                                        </Col>
                                        <Col lg={10}>
                                          <p>
                                            {camelCase(
                                              awbNum?.destinationHubName
                                            )}
                                            {/* Dehli Ghandhinagar Hub */}
                                          </p>
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                </Col>
                              </Row>
                              <div className="close">
                                {val?.childShipmentDetails?.some(
                                  (awbNum) => awbNum?.isScanned === false
                                ) ? null : (
                                  <Button type="primary" htmlType="submit">
                                    READY TO CLOSE
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Form>
                        </>
                      );
                    }
                  })}
              </Row>
              <Row className="submit">
                <Button
                  type="primary"
                  // disabled={totalcount === 0 ? false : true}
                  onClick={handleNext}
                >
                  NEXT
                </Button>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ScanPacket;
