/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useState } from "react";
import { message } from "antd";
import API from "../../../api";
import urls from "../../../api/urls";
import { camelCase, capitaliseWithHyphen } from "../../../utils";
import moment from "moment";

const useClientAndDepartment = (userInfo, form, formName = null) => {
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [bookingDetails, setBookingDetails] = useState({});
  const [pinCodeServiceableStatus, setPinCodeServiceableStatus] = useState({});
  const [contentDiscriptionData, setContentDiscriptionData] = useState({});
  const [clientDetails, setClientDetails] = useState(null);
  const [setsubmitRespons, setSetsubmitRespons] = useState({});
  const [alertData, setAlertData] = useState(false);

  const [savedReturnAddressList, setSavedReturnAddressList] = useState([]);

  const [reviverNameAndAddress, setReviverNameAndAddress] = useState([]);
  const [reviverStateCity, setReviverStateCity] = useState(null);
  const [clientID, setClientID] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);

  const currentDate = moment();

  const todayDateNTimeFormat = currentDate.format("DD/MM/YYYY hh:mm:ss A");
  const transporterId = "88AABCM9407D1ZS"; // Hardcoded

  const [areaDestinationPremiseId, setAreaDestinationPremiseId] =
    useState(null);

  const [allEwayBills, setAllEwayBills] = useState([
    {
      expiryDate: "",
      ewayBillCreateDate: "",
      ewayBillNumber: "",
    },
  ]);
  const {
    GET_CLIENT_LIST,
    GET_DEPARTMENT_LIST,
    GET_SINGLE_BOOKING,
    CHECK_PIN_CODE_SERVICEABILITY,
    GET_CONTENTDISCRIPTIN_LIST,
    GET_DESTINATION_PREMISE_ID,
    POST_CREDIT_DATA,
    EWAYBILL,
    ADDRESS_PINCODE,
    RETURN_ADDRESS,
    GET_RECIVER_NAME_ADDRESS,
    BOOKING_BARCODE_VALIDATION,
    VALIDATE_DOCUMENT_NUMBER,
    GET_CLIENT_INNO,
  } = urls;

  function containsAllPairs(obj, array) {
    for (let item of array) {
      if (+item.mobileNumber === +obj.mobileNumber && item.name === obj.name) {
        return {
          receiverDetails: [
            {
              userId: item.userId,
              mobileNumber: obj.mobileNumber,
              emailAddress: obj.emailAddress,
              address: obj.address,
              name: obj.name,
              bookingType: obj.bookingType,
              addressType: item.addressType,
              createdDate: item.createdDate,
            },
          ],
        };
      }
    }

    return { receiverDetails: [{ ...obj, userId: null }] };
  }

  const getClientsQA = async () => {
    setLoading(true);
    let res = await API.Api(GET_CLIENT_LIST).getCustomHeader({
      cpId: userInfo?.premiseId,
    });
    if (res.status) {
      const response = res.response?.data;

      const a = response.map((e) => ({
        label: camelCase(e.clientName),
        value: e.clientId,
      }));
      setClientList(a);

      setLoading(false);
    } else {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const getClients = async () => {
    setLoading(true);
    const companyId =
      JSON.parse(localStorage.getItem("selectedCompany"))?.key || null;
    let res = await API.Api(GET_CLIENT_INNO).getwithParams({
      company_id: companyId,
    });
    if (res.status) {
      const response = res?.response?.data?.data || [];
      const clients = response.map((e, i) => ({
        key: i,
        label: camelCase(e.ClientName),
        value: e.ClientName,
        department: e.Departments,
      }));
      setClientList(clients);

      setLoading(false);
    } else {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const getRevicerNameAndAddress = async (type) => {
    setLoading(true);
    let res = await API.Api(GET_RECIVER_NAME_ADDRESS + type).getCustomHeader({
      "PREMISE-ID": userInfo?.premiseId,
    });
    if (res.status) {
      const response = res.response?.data;
      setReviverNameAndAddress(response);

      setLoading(false);
    } else {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const getContentDiscriptionData = async () => {
    setLoading(true);
    let res = await API.Api(GET_CONTENTDISCRIPTIN_LIST).getCustomHeader({
      cpId: userInfo?.premiseId,
    });
    if (res.status) {
      const response = res.response?.data;
      setContentDiscriptionData(response);
      setLoading(false);
    } else {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const getDepartMent = async (a, clientId) => {
    form.setFieldsValue({
      department: null,
      documentNumber: null,
      receiverAddress: null,
      mobileNumber: null,
      receiverEmail: null,
    });

    setLoading(true);
    let res = await API.Api(GET_DEPARTMENT_LIST + clientId).getCustomHeader({
      cpId: userInfo?.premiseId,
    });
    if (res?.status) {
      const clientOrganizationDetails = res?.response?.data;

      setDepartment(
        clientOrganizationDetails?.clientOrganizationDetails?.departmentName?.map(
          (item) => {
            return { value: item, label: item };
          }
        )
      );

      const deptCheck =
        clientOrganizationDetails?.clientOrganizationDetails?.departmentName;

      if (deptCheck)
        form.setFieldsValue({
          department: deptCheck[0],
        });

      setLoading(false);

      const clientOnboard = res?.response?.data?.clientOnboard;

      setClientDetails({
        clientId: clientOnboard?.clientId,
        clientName: clientOnboard?.clientName,
      });

      const {
        clientName: senderName,
        addressline1: senderAddressLine1,
        addressline2: senderAddressLine2 = "",
        pincode: senderPincode,
        state: senderState,
        primaryNum: senderMobileNumber,
        emailId: senderEmail,
        city: senderCity,
        gstin: senderGst,
        city: areaName,
      } = clientOnboard;

      const addressDetails = {
        senderName,
        senderAddressLine1,
        senderAddressLine2,
        senderPincode,
        senderState,
        senderMobileNumber,
        senderEmail,
        senderCity,
        senderGst,
        areaName,
      };

      setBookingDetails((prevState) => ({
        ...prevState,
        addressDetails,
        clientId: clientOrganizationDetails?.clientId,
      }));
      getReturnAddressList(clientId);
    } else {
      // form.setFieldsValue({
      //   clientName: null,
      // });
      form.setFieldsValue({
        department: null,
      });
      setDepartment([]);
      // setClientDetails(null);
      message.error(res?.response?.data?.message || "Something went wrong");
      setLoading(false);
    }
  };

  const setClientDepartment = async (selectedClient, selectedDepartment) => {
    console.log(selectedClient, "selectedClient");

    form.setFieldsValue({
      department: null,
      documentNumber: null,
      receiverAddress: null,
      mobileNumber: null,
      receiverEmail: null,
    });
    // RESET THE STATE
    const addressDetail = {
      senderName: null,
      senderAddressLine1: null,
      senderAddressLine2: null,
      senderPincode: null,
      senderState: null,
      senderMobileNumber: null,
      senderEmail: null,
      senderCity: null,
      senderGst: null,
      areaName: null,
    };
    setBookingDetails((prevState) => ({
      ...prevState,
      addressDetails: addressDetail,
      clientId: null,
    }));

    if (selectedClient) {
      setCurrentClient(selectedClient);
    } else {
      selectedClient = { ...currentClient };
    }
    const currentDepartment = selectedDepartment
      ? [selectedDepartment.item]
      : selectedClient?.department || [];

    console.log(currentDepartment, "currentDepartment");

    setDepartment(
      selectedClient?.department?.map((item, i) => {
        const name =
          item?.Department && item?.Department !== "NA"
            ? `${item?.Department}-${item?.ContactPerson}`
            : `${item?.ContactPerson}`;
        return { key: i, value: name, label: name, item: item };
      })
    );

    if (currentDepartment.length == 1) {
      const item = currentDepartment[0];
      setLoading(true);

      let clientDetails = await API.Api(
        `${GET_CLIENT_INNO}${item?.ClientID}`
      ).getWithHeader();
      const name =
        item?.Department && item?.Department !== "NA"
          ? `${item?.Department}-${item?.ContactPerson}`
          : `${item?.ContactPerson}`;
      form.setFieldsValue({
        department: name,
      });
      if (clientDetails?.status) {
        setLoading(false);

        setClientDetails({
          clientId: item?.ClientID,
          clientName: selectedClient?.value,
        });

        const details = clientDetails?.response?.data?.data || {};
        const {
          ClientName: senderName,
          Address1: senderAddressLine1,
          Address2: senderAddressLine2 = "",
          Pincode: senderPincode,
          StateName: senderState,
          Mobile: senderMobileNumber,
          Email: senderEmail,
          City: senderCity,
          Gstin: senderGst = null,
          City: areaName,
        } = details;

        const addressDetails = {
          senderName,
          senderAddressLine1,
          senderAddressLine2,
          senderPincode,
          senderState,
          senderMobileNumber,
          senderEmail,
          senderCity,
          senderGst,
          areaName,
        };

        setBookingDetails((prevState) => ({
          ...prevState,
          addressDetails,
          clientId: item?.ClientID,
        }));
        getReturnAddressList(item?.ClientID);
        setClientID(item?.ClientID);
      }
    }
  };

  const getDeliveryPincode = async (e, fieldName) => {
    const value = e?.target ? e.target.value : e;
    form.setFieldsValue({
      areaId: null,
      areaName: null,
      city: null,
      state: null,
      pincode: null,
      returnAreaName: null,
      // receiverAreaId: null,
    });

    setPinCodeServiceableStatus({
      serviceability: null,
      // status: "success",
      msg: null,
      stateCode: null,
      city: null,
    });
    setAreaList([]);
    if (value > 5) {
      setLoading(true);

      let response2 = await API.Api(ADDRESS_PINCODE + value).getCustomHeader();
      if (response2?.status) {
        const res = response2.response.data;

        const serviceability = res?.serviceability.serviceability;
        const city = res?.city;
        const state = res?.stateName;
        const pincode = res?.pincode;
        const status =
          serviceability === "Non-Serviceable" ? "error" : "success";
        const msg =
          serviceability === "Non-Serviceable" ? "Non-Serviceable" : null;

        setPinCodeServiceableStatus({
          serviceability,
          status,
          msg,
          stateCode: res?.stateCode,
          city,
          state,
          pincode,
        });
        if (fieldName == "deliveryPincode") {
          setReviverStateCity({
            city: res?.city,
            state: res?.stateName,
          });
        }
        if (formName === "returnAddressform") {
          const areas =
            serviceability !== "Non-Serviceable"
              ? (res?.areadto || []).map((e) => ({
                  label:
                    e?.areatype == "non-serviceable"
                      ? capitaliseWithHyphen(
                          e.areaName + " --Non-delivery-zone"
                        )
                      : capitaliseWithHyphen(e.areaName),
                  value: capitaliseWithHyphen(e.areaName),
                  disabled: e?.areatype == "non-serviceable" ? true : false,
                }))
              : [];

          setAreaList(areas);
        } else {
          const areas =
            serviceability !== "Non-Serviceable"
              ? (res?.areadto || []).map((e) => ({
                  label:
                    e?.areatype == "non-serviceable"
                      ? capitaliseWithHyphen(
                          e.areaName + " --Non-delivery-zone"
                        )
                      : capitaliseWithHyphen(e.areaName),

                  value: e.areaId,
                  disabled: e?.areatype == "non-serviceable" ? true : false,
                }))
              : [];
          setAreaList(areas);
        }

        form.setFieldsValue({
          city: capitaliseWithHyphen(res?.city),
          state: capitaliseWithHyphen(res?.stateName),
          pincode: value,
        });
        setLoading(false);
      } else {
        setPinCodeServiceableStatus({
          serviceability: null,
          status: "error",
          msg: response2?.response?.data?.message,
          stateCode: null,
          city: null,
        });
        setLoading(false);
      }
    }
  };

  const checkAWBDocUsed = (awb, bookType, shipmentType) => {
    form.setFields([
      {
        name: "documentNumber",
        errors: null,
      },
    ]);
  };

  // const checkAWBDocUsed = async (awb, bookType, shipmentType) => {
  //   let response = await API.Api(
  //     BOOKING_BARCODE_VALIDATION + awb
  //   ).getwithParams(null, {
  //     "booking-type": bookType?.toUpperCase(),
  //     "SHIPMENT-TYPE": shipmentType?.toUpperCase(),
  //   });
  //   if (response?.status) {
  //     if (response.response.data == false) {
  //       message.error("Invalid doc number!");
  //       form.setFields([
  //         {
  //           name: "documentNumber",
  //           errors: ["Invalid doc number!"],
  //         },
  //       ]);
  //     } else {
  //       form.setFields([
  //         {
  //           name: "documentNumber",
  //           errors: null,
  //         },
  //       ]);
  //     }
  //     // return response?.response?.data;
  //   } else {
  //     message.error(
  //       response?.response?.data?.message || "Internal server error."
  //     );
  //     return response;
  //   }
  // };

  const getAreaDestinationPremiseId = async (val, child) => {
    let response = await API.Api(GET_DESTINATION_PREMISE_ID + child?.key).get();
    if (response?.status) {
      setAreaDestinationPremiseId({
        areaDestinationPremiseId: response?.response?.data,
        areaId: child?.key,
      });
      form.setFieldsValue({
        areaName: val,
      });
    } else {
      form.setFieldsValue({
        receiverAreaId: null,
        areaId: null,
        areaName: null,
      });
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  const submitWithPostDetails = async (payload, values) => {
    setLoading(true);
    const company_id =
      JSON.parse(localStorage.getItem("selectedCompany"))?.key || null;
    let response = await API.Api(POST_CREDIT_DATA).postIdCustomHeader(payload, {
      company_id,
    });
    if (response?.status) {
      setLoading(false);
      // setSetsubmitRespons(response);
      setAlertData({
        visible: true,
        message: "Booking successfully completed",
      });
      setPinCodeServiceableStatus({});
    } else {
      message.error(
        response?.response?.data?.message ||
          response?.response?.data ||
          "Network error."
      );
    }
    setLoading(false);
  };

  const eWayBillHandle = async (value, key) => {
    if (value?.length === 12) {
      let response = await API.Api(
        EWAYBILL + "/" + value
      ).getWithPremiseIdHeader();
      if (response?.status) {
        let data = JSON.parse(response?.response?.data?.data);
        const validilityDate = moment(data?.validUpto, "DD/MM/YYYY");
        const todayDateNTime = moment(todayDateNTimeFormat, "DD/MM/YYYY");

        let errorFields = [];

        if (data?.errorCodes) {
          errorFields.push({
            name: ["ewayBill" + key],
            errors: ["Please give correct E-way bill number"],
          });
        }
        if (!data?.errorCodes) {
          if (todayDateNTime.isAfter(validilityDate)) {
            errorFields.push({
              name: ["ewayBill" + key],
              errors: ["E-way bill expired"],
            });
          } else if (data?.transporterId !== transporterId) {
            errorFields.push({
              name: ["ewayBill" + key],
              errors: ["Transporter id is not matching"],
            });
          }
        }

        if (errorFields.length > 0) {
          form.setFields(errorFields);
        } else {
          updateVerifiedEwayBillAtIndex(key, data, value);
          return Promise.resolve(
            message.success("E-way bill request succeeds")
          );
        }
      }
    }
  };

  const documentValidate = async (documentNumber, bookingType, service) => {
    const serviceLower = service?.toLowerCase();

    try {
      const spId = JSON.parse(localStorage.getItem("userinfo"))?.spId || null;
      let response = await API.Api(VALIDATE_DOCUMENT_NUMBER).getwithParams({
        bookingType: bookingType,
        // companyId: companyId,
        clientId: clientID,
        subBookingType: serviceLower?.includes("fast")
          ? "FastTrack"
          : serviceLower == "childbadgecourier"
          ? "ChildBadgeCourier"
          : serviceLower?.includes("standard")
          ? "Standard"
          : serviceLower?.includes("value")
          ? "Value Plus"
          : null,

        spId: spId,
        documentNumber: documentNumber,
      });
      return response;
    } catch (error) {
      console.error("Error during validation:", error);
    }
  };

  const updateVerifiedEwayBillAtIndex = (
    indexToUpdate,
    newData,
    ewayBillNumber
  ) => {
    let temp = [...allEwayBills];

    setAllEwayBills(
      temp?.map((field, index) => {
        if (index === indexToUpdate) {
          const updatedItem = {
            ...field,
            expiryDate: moment(
              newData.validUpto,
              "DD/MM/YYYY h:mm:ss A"
            ).format("YYYY-MM-DD HH:mm:ss"),
            ewayBillCreateDate: moment(
              newData.validUpto,
              "DD/MM/YYYY h:mm:ss A"
            ).format("YYYY-MM-DD HH:mm:ss"),
            ewayBillNumber,
          };
          return updatedItem;
        }
        return field;
      })
    );
  };

  const getReturnAddressList = async (cliendID) => {
    let res = await API.Api(RETURN_ADDRESS + cliendID).getWithHeader();
    if (res?.status) {
      let data = res?.response?.data;
      let temp = data?.map((item, i) => ({
        ...item,
        isAddressEnabled: i === 0,
      }));
      setSavedReturnAddressList(temp);

      // setReturnAddress(temp);
    } else {
      message.error(
        res?.response?.data?.message || "Can't fetch return address."
      );
    }
  };

  const updateReturnAddressList = (value) => {
    setSavedReturnAddressList(value);
  };

  const resetCredit = () => {
    setPinCodeServiceableStatus({});
    setAreaList([]);
  };

  const handleOnEnter = (event) => {
    {
      event.preventDefault();

      const tabbableElements = Array.from(
        document.querySelectorAll("input, button, select, textarea, a[href]")
      )?.filter((el) => !el?.disabled && el?.offsetParent !== null);

      const currentIndex = tabbableElements?.indexOf(document?.activeElement);

      const nextIndex = (currentIndex + 1) % tabbableElements?.length;
      tabbableElements[nextIndex]?.focus();
      tabbableElements[nextIndex]?.select();
    }
  };

  // @ keep this for furture use

  // const defaultTravelByOptions = [
  //   {
  //     value: "Surface",
  //     label: "Surface",
  //   },
  //   {
  //     value: "Air",
  //     label: "Air",
  //   },
  // ];

  // const localTravelByOptions = [
  //   {
  //     value: "Surface",
  //     label: "Surface",
  //   },
  // ];
  // const { CHECK_LOCAL } = urls;

  // const setTravelOptions = (isLocal) => {
  //   if (isLocal) {
  //     form.setFieldValue("travelBy", "Surface");
  //   }

  //   setTravelByOptions(isLocal ? localTravelByOptions : defaultTravelByOptions);
  // };

  // const userId = JSON.parse(localStorage.getItem("userId"));
  // const getLocal = async (e) => {
  //   const value = e.target.value;

  //   if (value?.length <= 5) {
  //     setTravelOptions(false);
  //     return;
  //   }

  //   try {
  //     const response = await API.Api(
  //       CHECK_LOCAL + `${userId?.premises?.pincode}/to/${value}`
  //     ).getWithUserNPremiseHeader();
  //     if (response?.status) {
  //       const data = response?.response?.data;
  //       setTravelOptions(data === "LOCAL");
  //       // form.setFieldValue("travelBy", "Surface");
  //     } else {
  //       setTravelOptions(false);
  //     }
  //   } catch (error) {
  //     setTravelOptions(false);
  //   }
  // };

  let servicesOptions = [
    {
      value: "standard",
      label: "Standard",
    },
    {
      value: "fast track",
      label: "Fast Track",
    },
    {
      value: "value plus",
      label: "Value Plus",
    },
  ];

  const travelByOptions = [
    {
      value: "Surface",
      label: "Surface",
    },
    {
      value: "Air",
      label: "Air",
    },
  ];

  const insuredOptions = [
    {
      value: "no",
      label: "No",
    },
    {
      value: "yes",
      label: "Yes",
    },
  ];

  const [editShipmentDetails, setEditShipmentDetails] = useState(null);

  const getSingleShipmentDetails = async (cliendID = 212121) => {
    let res = await API.Api(GET_SINGLE_BOOKING + cliendID).getWithHeader();
    if (res?.status) {
      setEditShipmentDetails(res.response.data);
      // setReturnAddress(temp);
    } else {
      setEditShipmentDetails(null);
      message.error(res?.response?.data?.message || "Network error");
    }
  };

  return {
    travelByOptions,
    insuredOptions,
    servicesOptions,
    loading,
    clientList,
    department,
    getClientsQA,
    getClients,
    getDepartMent,
    setClientDepartment,
    setPinCodeServiceableStatus,
    handleOnEnter,
    containsAllPairs,
    getDeliveryPincode,
    getSingleShipmentDetails,

    editShipmentDetails,
    pinCodeServiceableStatus,
    areaList,
    bookingDetails,
    getContentDiscriptionData,
    contentDiscriptionData,
    getAreaDestinationPremiseId,
    areaDestinationPremiseId,
    clientDetails,
    submitWithPostDetails,
    setsubmitRespons,
    setAlertData,
    eWayBillHandle,
    allEwayBills,
    setAllEwayBills,
    alertData,
    savedReturnAddressList,
    setSavedReturnAddressList,
    updateReturnAddressList,
    getRevicerNameAndAddress,
    reviverNameAndAddress,
    reviverStateCity,
    resetCredit,
    checkAWBDocUsed,
    // getReturnAddressList,
    documentValidate,
    clientID,
    setClientID,
  };
};

export default useClientAndDepartment;
