/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import API from "../../../api";
import urls from "../../../api/urls";
import {
  clearCreatedManifest,
  createManifest,
  getPacketListBySatus,
  getPacketListClear,
  //getViewAllBookings,
} from "../../../actionsMethods/manifestMethods";
import {
  getStartAndEndDate,
  dateFormat_dmy,
  capitaliseWithHyphen,
  scrollToTop,
} from "../../../utils";
import {
  Col,
  Row,
  Select,
  Menu,
  Dropdown,
  Spin,
  Input,
  Button,
  message,
  DatePicker,
} from "antd";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DraggerModal from "../modals/draggerModal";
import ViewBookingSummaryEdit from "../../ViewBookingSummaryEdit";
import ViewBookingSummary from "../../ViewBookingSummary";
import {
  getBarcodeId,
  getSingleBooking,
} from "../../../actionsMethods/cashBookingMethods";
import { column } from "./column";
import TableContainer from "../../../components/TableContainer";
import search_icon from "../../../assests/images/search-icon.svg";
import truck from "../../../assests/images/SVG/packetSurface.svg";
import plane from "../../../assests/images/planeicon.svg";
import "./AllBookingTable.scss";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import filter_icon from "../../../assests/images/master/sorting-filter.svg";

const {
  UPLOAD_MANIFEST_FILE,
  GET_RETAIL_DOCUMENT_DETAILS,
  GET_ALL_BOOKING_STATUS,
} = urls;
const { RangePicker } = DatePicker;

const BookingTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let premiseId = JSON.parse(localStorage.getItem("userId"));
  const [data, setData] = useState([]);
  const [oldAwbNum, setOldAwbNum] = useState(null);
  const [uploadModalvisible, setUploadModalvisible] = useState(false);
  const [editTest, setEditTest] = useState(false);
  const [manifestFor, setManifestFor] = useState();
  const [dataSource, setDataSource] = useState(data);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const [dropdownValue, setdropdownValue] = useState(true);
  const [eyeButtonState, setEyeButtonState] = useState(false);
  const [inputState, setInputState] = useState(true);
  const [bookingType, setBookingType] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [typeBooking, setTypeBooking] = useState("Both");
  const [mode, setMode] = useState("");
  const debounceTimeout = useRef(null);

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, "days"),
    dateFormat_dmy,
    endDate: moment(new Date(), dateFormat_dmy),
  });
  const [searchValue, setSearchValue] = useState(null);
  const [bookingStatusList, setBookingStatusList] = useState([]);
  const [bookingStatus, setBookingStatus] = useState("All");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const typeBookingList = [
    {
      value: "Cash",
      label: "Retail",
    },
    {
      value: "Account",
      label: "Credit",
    },
    {
      value: "Both",
      label: "Both",
    },
  ];
  const { result } = useSelector(
    (state) => state.appReducer.getBarcodeIdReducer,
    shallowEqual
  );

  const viewBookingSummaryResponse = useSelector(
    (state) => state.appReducer.getSingleBookingReducer,
    shallowEqual
  );

  const viewBookingTableResponse = useSelector(
    (state) => state.manifestReducer.getViewAllBookingsReducer,
    shallowEqual
  );

  const createManifestResponse = useSelector(
    (state) => state.manifestReducer.createManifestReducer,
    shallowEqual
  );

  const { result: manifestResult, error: manifestError } =
    createManifestResponse;
  const { result: viewTableResult, fetching } = viewBookingTableResponse;

  const { fetching: loader1, result: packetAndShipment } = useSelector(
    (state) => state.manifestReducer.getPacketListBySatusReducer,
    shallowEqual
  );

  useEffect(() => {
    if (loader1 == false) {
      if (
        packetAndShipment?.packetsdto?.length > 0 ||
        packetAndShipment?.shipmentDto?.length > 0
      ) {
        setdropdownValue(false);
      } else {
        setdropdownValue(true);
      }
    }
  }, [packetAndShipment]);

  useEffect(() => {
    if (manifestError) {
      message.error(manifestError.message);
    } else if (manifestResult) {
      if (manifestFor == "Surface") {
        // navigate("/child-scan");
        navigate("/scan");

        setdropdownValue(true);
      } else {
        // navigate("/viewBooking-packet");
        // navigate("/child-scan");
        navigate("/scan");

        setdropdownValue(true);
      }

      localStorage.setItem("manifestFor", manifestFor.toLowerCase());
      localStorage.setItem("manifestedId", JSON.stringify(manifestResult));
    }
    return () => {
      dispatch(clearCreatedManifest());
    };
  }, [manifestResult, manifestError]);

  useEffect(() => {
    if (viewTableResult) {
      let data = viewTableResult?.viewBookings?.map((obj) =>
        obj.bookingStatus === "COMPLETED"
          ? { ...obj, bookingStatus: "BOOKED" }
          : obj
      );
      let data2 = { ...viewTableResult, viewBookings: data };
      setData(data2);
    }
  }, [viewTableResult]);

  useEffect(() => {
    getAllBookings(
      pageNum,
      pageSize,
      null,
      null,
      typeBooking,
      searchValue,
      bookingStatus
    );
    getAllBookingStatus();
    dispatch(getPacketListClear());
  }, []);

  const getAllBookingStatus = async () => {
    let response = await API.Api(GET_ALL_BOOKING_STATUS).getWithAllHeaders();
    if (response?.status) {
      let data = response?.response?.data?.map((e) => ({
        label: capitaliseWithHyphen(e === "COMPLETED" ? "BOOKED" : e),
        value: e === "COMPLETED" ? "COMPLETED" : e,
      }));
      setBookingStatusList([
        {
          label: "All",
          value: "All",
        },
        ...data?.sort((a, b) => a.value.localeCompare(b.value)),
      ]);
    }
  };

  const getAllBookings = async (
    pageNo,
    pageSize,
    startDate,
    endDate,
    type,
    search,
    status
  ) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setLoading(true);

    let searchData = search ? `&search=${search}` : "";
    let response = await API.Api(
      GET_RETAIL_DOCUMENT_DETAILS +
        `?pageNo=${pageNo}&pageSize=${pageSize}` +
        getStartAndEndDate(
          startDate ? startDate : null,
          endDate ? endDate : null,
          dateFormat_dmy
        ) +
        "&bookingType=" +
        type +
        searchData +
        "&status=" +
        status
    ).getWithAllHeaders();
    if (response?.status) {
      setLoading(false);
      let res = response?.response?.data;
      let temp = res?.viewBookings?.map((obj) =>
        obj.bookingStatus === "COMPLETED"
          ? { ...obj, bookingStatus: "BOOKED" }
          : obj
      );

      let response1Data = { ...res, viewBookings: temp };
      let result = sanitizeData(response1Data);

      setData(result);
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  function sanitizeData(obj) {
    for (let key in obj) {
      if (
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === "" ||
        Number.isNaN(obj[key]) ||
        obj[key] === "NaN" ||
        Number.isNaN(obj[key])
      ) {
        obj[key] = 0;
      }
    }
    return obj;
  }

  const handleUpload = useCallback((val) => {
    setUploadModalvisible(true);
    setOldAwbNum(val.awbNumber);
  });

  function handleCancel() {
    setIsModalOpen(false);
    setIsSummaryModalOpen(false);
    setEditTest(true);
    setInputState(true);
  }

  function MyComponent(dateToFormat) {
    const toUpperCaseFilter = (d) => {
      return d.slice(0, 10);
    };
    return (
      <Moment filter={toUpperCaseFilter} format="DD/MM/YYYY">
        {dateToFormat}
      </Moment>
    );
  }

  function handleEditView(params) {
    setBookingType(params?.bookingType);
    setIsModalOpen(true);
    dispatch(getSingleBooking(params?.awbNumber));
    setBookingType(params?.bookingType);
    setEditTest(true);
  }

  const handleEyeButton = (b) => {
    setBookingType(b?.bookingType);
    setIsSummaryModalOpen(true);
    dispatch(getSingleBooking(b?.awbNumber));
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const statusFun = (val) => {
    const statusColors = {
      BOOKED: "stat-green",
      INITIATED: "blue",
      "In progress": "blue",
      Delivered: "stat-green",
      PACKETED: "darkBlue",
      SCANNED: "purple",
      "In transit": "yellow",
      MANIFESTED: "yellow",
      OUTSCANNED_TO_CP: "yellow",
    };

    const color = statusColors[val?.toUpperCase()] || "yellow";

    return <p className={color}>{val?.toUpperCase()}</p>;
  };

  function handleEdit(id) {
    navigate("/credit-booking-edit/" + id);
  }

  /******************* Input search ******************************/
  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      getAllBookings(
        pageNum,
        pageSize,
        moment().subtract(30, "days"),
        moment(new Date(), dateFormat_dmy),
        "both",
        e.target.value.length ? e.target.value : null,
        "All"
      );
      const currValue = e.target.value;
      setInputSearchValue(currValue);
    }, 900);
  };

  /******************* Type ******************************/

  function closeUploadModal(data) {
    setUploadModalvisible(false);
  }

  async function handleOK(data) {
    const temp = data?.map((val) => ({
      fileName: val?.response?.data?.filePath,
      fileId: val?.response?.data?.id,
    }));

    let response = await API.Api(UPLOAD_MANIFEST_FILE).postCustomHeader(temp, {
      "Awb-Number": oldAwbNum,
    });
    if (response) {
      setUploadModalvisible(false);
    }
  }

  function handlePageChange(pagenum, pagesizee) {
    setPageNum(pagenum - 1);
    setPageSize(pagesizee);
    setSearchValue(null);
    getAllBookings(
      pagenum - 1,
      pagesizee,
      dateRange?.startDate,
      dateRange?.endDate,
      typeBooking,
      null,
      bookingStatus
    );
    window.scrollTo(0, 0);
  }

  const handleGenerateManifest = () => {
    let manifestpayload = {
      type: "pickup",
      status: "INITIATED",
      // pickupDriverId: "null",
      travelBy: manifestFor?.toUpperCase(),
    };
    dispatch(createManifest(manifestpayload));
  };

  const handleChange = (item) => {
    const newItem = item;

    setMode(newItem);

    const actionMap = {
      Surface: () => {
        setManifestFor("Surface");
        dispatch(getPacketListBySatus("manifest", "surface"));
      },
      Both: () => {
        setManifestFor("BOTH");
        setdropdownValue(true);
      },
      Air: () => {
        setManifestFor("Air");
        dispatch(getPacketListBySatus("manifest", "air"));
      },
    };

    const action = actionMap[newItem];
    if (action) {
      action();
    }
  };

  /******************* DATE AND TYPE FILTER ******************************/

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleDateChange = (e, date) => {
    // e.preventDefault();
    setSearchValue(null);

    setDateRange({ startDate: date[0], endDate: date[1] });
    getAllBookings(
      pageNum,
      pageSize,
      date[0],
      date[1],
      typeBooking,
      null,
      bookingStatus
    );
  };

  /******************* DATE AND TYPE FILTER END ******************************/

  let d2 = data?.atrShipmentWeight + data?.surfaceShipmentWeight;

  console.log(dateRange, "dateRange");

  const menu = (
    <>
      <Menu
        style={{ cursor: "default", backgroundColor: "#fff" }}
        items={[
          {
            label: (
              <>
                <span>Date range</span>
                <Col>
                  <RangePicker
                    value={
                      dateRange.startDate && dateRange.endDate
                        ? [
                            moment(dateRange.startDate, dateFormat_dmy),
                            moment(dateRange.endDate, dateFormat_dmy),
                          ]
                        : null
                    }
                    format={dateFormat_dmy}
                    onClick={(e) => e.stopPropagation()}
                    disabledDate={(current) => disabledDate(current)}
                    onChange={handleDateChange}
                    // format={dateFormat_dmy}
                  />
                </Col>
              </>
            ),
            key: 199,
            style: { fontSize: "13px" },
          },
          {
            label: (
              <>
                <span>Service type</span>
                <Col>
                  <Select
                    defaultValue="both"
                    placeholder="Select type"
                    className="filter-select"
                    style={{ width: "100%", marginRight: "12px" }}
                    value={typeBooking}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(val) => {
                      setTypeBooking(val);
                      getAllBookings(
                        pageNum,
                        pageSize,
                        dateRange?.startDate,
                        dateRange?.endDate,
                        val,
                        null,
                        bookingStatus
                      );
                    }}
                    options={typeBookingList}
                  />
                </Col>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    type="link"
                    onClick={() => {
                      setTypeBooking("Both");
                      setMode(null);
                      setManifestFor(null);
                      setDateRange({
                        startDate: moment().subtract(1, "days"),
                        endDate: moment(new Date(), dateFormat_dmy),
                      });
                      getAllBookings(
                        0,
                        pageSize,
                        moment().subtract(1, "days"),
                        moment(new Date(), dateFormat_dmy),
                        "Both",
                        null,
                        bookingStatus
                      );
                    }}
                    style={{ color: "#007bff" }}
                  >
                    Reset
                  </Button>
                </div>
              </>
            ),
            key: 199,
            style: { fontSize: "13px" },
          },
        ]}
      />
    </>
  );

  const handleDropdownVisibility = (visible) => {
    setDropdownVisible(visible);
  };

  console.log(pageSize, "pageSize");

  return (
    <Spin
      spinning={fetching || loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <ViewBookingSummaryEdit
        visible={isModalOpen}
        handleCancel={handleCancel}
        allAppData={viewBookingSummaryResponse?.result}
        editTest={editTest}
        setEditTest={setEditTest}
        setIsModalOpen={setIsModalOpen}
        setInputState={setInputState}
        inputState={inputState}
        eyeButtonState={eyeButtonState}
        setEyeButtonState={setEyeButtonState}
        bookingType={bookingType}
      />
      <ViewBookingSummary
        visible={isSummaryModalOpen}
        handleCancel={handleCancel}
        allAppData={viewBookingSummaryResponse?.result}
        bookingType={bookingType}
      />

      <DraggerModal
        oldAwbNum={oldAwbNum}
        visible={uploadModalvisible}
        handleCancel={closeUploadModal}
        handleOk={handleOK}
      />

      <div className="table-container">
        <Row
          className="row-one align-center"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <Col>
            <p className="label">Shipment count and weight</p>
          </Col> */}

          <Col span={10}>
            <div className="filter-container space-between">
              <Col className="standard">
                <div>
                  <img src={truck} />
                  <span>
                    {+data?.surfaceShipmentNo}/
                    {+data?.surfaceShipmentWeight?.toFixed(2)} {" kg"}
                  </span>
                </div>
              </Col>
              <Col className="fast-track">
                <div>
                  <img src={plane} />
                  <span>
                    {+data?.airShipmentNo}/
                    {+data?.atrShipmentWeight?.toFixed(2)}
                    {" kg"}
                  </span>
                </div>
              </Col>
              <Col className="stand-fast">
                <div>
                  <img src={truck} /> + <img src={plane} />
                  <span className="span-2">
                    {/* Consolidated weight :{" "} */}
                    {+data?.airShipmentNo + +data?.surfaceShipmentNo}/
                    {d2?.toFixed(2)}
                    {" kg"}
                  </span>
                </div>
              </Col>
            </div>
          </Col>
          <Col span={5} style={{ marginLeft: "auto" }}>
            <Input
              value={searchValue}
              placeholder="Search by doc No, mode"
              bordered
              onChange={(e) => handleChangeSearch(e)}
              style={{
                borderRadius: "5px",
                height: "30px",
                fontSize: "12px",
                marginRight: "10px",
              }}
              suffix={<img src={search_icon} />}
            />
          </Col>
          <div className="pointer">
            <Dropdown
              // trigger={["click"]}
              visible={dropdownVisible}
              onOpenChange={handleDropdownVisibility}
              overlay={menu}
              overlayClassName="nav-dropdown-overlay filter-order"
              // open={true}
            >
              <div
                className="pointer"
                onClick={(e) => e.preventDefault()}
                style={{ margin: "0 10px" }}
              >
                <img src={filter_icon} height={20} />
                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                  Filters
                </span>
              </div>
            </Dropdown>
          </div>
          <Col span={2}>
            <Select
              placeholder="Select mode"
              // defaultValue={"Both"}
              // value={mode}
              className="filter-select"
              style={{ width: "100%", margin: "0 -6px" }}
              onChange={handleChange}
              onClick={(e) => e.stopPropagation()}
              options={[
                {
                  value: "Surface",
                  label: "Surface",
                },
                {
                  value: "Air",
                  label: "Air",
                },
                {
                  value: "Both",
                  label: "Both",
                },
              ]}
            />
          </Col>

          <Col className="generate-manifest">
            <Button
              type="primary"
              onClick={handleGenerateManifest}
              className="addPacketBtn"
              disabled={!manifestFor}
            >
              GENERATE MANIFEST
            </Button>
          </Col>
        </Row>

        <TableContainer
          columns={column(
            MyComponent,
            statusFun,
            handleEdit,
            handleEditView,
            handleUpload,
            handleEyeButton,
            pageSize,
            pageNum
          )}
          // showTotal={(total, range) =>
          //   `${range[0]}-${range[1]} of ${total} items`
          // }
          pagination={{
            pageSize: pageSize,
            total:
              inputSearchValue.length == 0
                ? data?.totalCount
                : dataSource.length,
            onChange: handlePageChange,
            itemRender: itemRender,
          }}
          // total={data?.totalCount}

          defaultCurrent={1}
          // dataSource={
          //   inputSearchValue.length == 0 ? data?.viewBookings : dataSource
          // }
          dataSource={data?.viewBookings}
          headerClassName="header"
          className="view-table table-head-sticky"
        />
      </div>
    </Spin>
  );
};

export default React.memo(BookingTable);
