/* eslint-disable indent */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Row,
  Col,
  Spin,
  Select,
  Input,
  Button,
  Card,
  Empty,
  message,
  notification,
  Tooltip,
  AutoComplete,
} from "antd";
import "./style.scss";
import {
  capitalize,
  checkNumbervalue,
  isValidAwbForCredit,
  isValidDocumentNum,
} from "../../../utils";
import { HeaderWithBottomLine } from "../../Master/CpOnbordingSteps/HeaderWithBottomLine";
import useClientAndDepartment from "./useClientAndDepartment ";
import DoxAndNonDox from "./DoxAndNonDox";
import UploadDocument from "./UploadDocument";
import AutoCompleteAddress from "./AutoCompleteAddress";

import Heading from "../../../components/Heading";
import "../style.scss";

import Smcs_Loader from "../../../assests/images/loader-new.gif";
import EwayBillDrawer from "./EwayBillDrawer/EwayBillDrawer";
import ChildDrawer from "./childDrawer";
import { ReactComponent as AttachFile } from "../../../assests/images/FileEarmarkPlus.svg";
import ReturnAddressDrawer from "./ReturnAddressDrawer";
import RecentBooking from "./RecentBooking";
import AutoSelectAndType from "./AutoSelectAndType";
import AddressCard from "./AddressCard";

const CrediteBooking = () => {
  const [form] = Form.useForm();

  const { userInfo } = JSON.parse(window?.localStorage?.getItem("userId"));

  const [ewayBill, setEwayBill] = useState(false);
  const [isNonDox, setIsNonDox] = useState(false);
  const [freeze, setFreeze] = useState(false);
  const [childShipments, setChildShipments] = useState([]);
  const [filesData, setFileData] = useState([]);
  const [chilldShipmentLength, setChilldShipmentLength] = useState(0);
  const [shipmentContentOptions, setShipmentContentOptions] = useState([]);

  const [documentLength, setDocumentLength] = useState(14);
  const [documentNumber, setDocumentNumber] = useState("");
  const [isValidDocument, setIsValidDocument] = useState(null);

  const [allEwayBills, setAllEwayBills] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [ewayDrawer, setEwayDrawer] = useState(false);
  const [childDrawer, setChildDrawer] = useState(false);
  const [radioActive, setRadioActive] = useState("default");
  const [returnAddressDrawer, setReturnAddressDrawer] = useState(false);
  const [recentAPICall, setRecentAPICall] = useState(false);
  const [number, setNumber] = useState(3);
  const [dropDownChanges, setdropDownChanges] = useState(false);
  const [serviceType, setServiceType] = useState("standard");
  const [activePinOption, setActivePinOption] = useState(null);

  const [allPincodes, setAllPincodes] = useState(
    JSON.parse(localStorage.getItem("allPincodes"))
  );

  const [options, setOptions] = useState([]);

  const [ewayForm] = Form.useForm();
  const [childDrawerform] = Form.useForm();

  const debounceTimeout = useRef(null);

  const doxandNonDoxChange = {
    content: null,
    description: null,
    weight: "1",
    length: null,
    width: null,
    height: null,
    totalVolWeight: null,
    child_Selected: false,
    value: null,
  };

  const currentEnv = process.env.REACT_APP_ENV;

  const {
    travelByOptions,
    servicesOptions,
    insuredOptions,
    loading,
    clientList,
    department,
    clientDetails,
    areaList,
    pinCodeServiceableStatus,
    bookingDetails,
    contentDiscriptionData,
    areaDestinationPremiseId,
    alertData,
    savedReturnAddressList,
    reviverNameAndAddress,

    setAlertData,
    resetCredit,

    getDepartMent,
    getClientsQA,
    getClients,
    setClientDepartment,
    setPinCodeServiceableStatus,
    getDeliveryPincode,
    getContentDiscriptionData,
    getAreaDestinationPremiseId,
    submitWithPostDetails,
    getRevicerNameAndAddress,
    updateReturnAddressList,
    checkAWBDocUsed,
    documentValidate,
    handleOnEnter,
    clientID,
    setClientID,
    containsAllPairs,
  } = useClientAndDepartment(userInfo, form);
  const [filteredOptions, setFilteredOptions] = useState(clientList);

  const senderAddress = bookingDetails?.addressDetails;
  useEffect(() => {
    if (currentEnv == "qa") {
      getClientsQA();
    } else {
      getClients();
    }
    getContentDiscriptionData();
    getRevicerNameAndAddress("Account");
    getFocusFirstInput("cashFirst");
  }, []);

  const getFocusFirstInput = (id) => {
    const timer = setTimeout(() => {
      document.getElementById(id)?.focus();
      document.getElementById(id)?.select();
    }, 100);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (Object.entries(contentDiscriptionData).length !== 0) {
      form.setFieldsValue({
        shipmentType: "Dox",
        service: "standard",
        travelBy: "Surface",
      });
      handleShipmentTypeChange("_", { key: 1, value: "Dox" });
    }
  }, [contentDiscriptionData]);

  const handleShipmentTypeChange = (_, { key, value }) => {
    // setdropDownChanges(!dropDownChanges);
    form.setFieldValue("content", null);
    const isDox = value == "Non-Dox";
    setIsNonDox(isDox);
    if (isDox) {
      form.setFieldsValue(doxandNonDoxChange);
      setChilldShipmentLength(0);
    } else {
      form.setFieldsValue({
        value: "0",
        weight: 50,
      });
    }
    let output = [];

    contentDiscriptionData?.shipmentContent?.forEach((content) => {
      let filteredDescriptions =
        contentDiscriptionData?.shipmentDescription?.filter((description) => {
          return (
            description?.shipmentContentId === content?.shipmentContentId &&
            content?.shipmentTypeId === +key
          );
        });

      filteredDescriptions?.forEach((description) => {
        let combinedName = `${content?.shipmentContentName} - ${description?.shipmentDescriptionName}`;
        output.push({
          shipmentContentId: content?.shipmentContentId,
          shipmentContentName: combinedName,
          shipmentTypeId: content?.shipmentTypeId,
        });
      });
    });
    setShipmentContentOptions(output);
  };

  const uploadDocumentCallback = (files) => {
    setFileData(files);
  };

  const onSubmit = (value) => {
    if (childShipments?.length > 0) {
      Object.keys(value).forEach((key) => {
        if (key.includes("Child_")) {
          delete value[key];
        }
      });
    }

    if (value?.value > 50000 && allEwayBills?.length == 0) {
      message.error("Add E-way bill");
      return;
    }

    const obj = {
      mobileNumber: +value?.mobileNumber,
      emailAddress: value?.receiverEmail || null,
      address: value?.receiverAddress,
      name: value?.receiverName,
      // userId: value?.userId,
      bookingType: "Account",
    };

    const payload = {
      documentNumber: value.documentNumber || null,
      pickupPincode: value.pickupPincode || null,
      deliveryPincode: value.deliveryPincode || null,
      areaId: +areaDestinationPremiseId.areaId || value?.areaId || null,
      shipmentType: value.shipmentType || null,
      // billRemark: value.billRemark,
      content: value?.content || null,
      description: value?.billRemark || "",
      weight: value.weight || null,
      length: value.length || null,
      width: value.width || null,
      height: value.height || null,
      volumatricWeight: value.volumatricWeight || null,
      value: value.value || null,
      senderGstNumber: bookingDetails?.addressDetails?.senderGst || null,
      service: value.service || null,
      travelBy: value.travelBy || null,

      destinationCpId:
        areaDestinationPremiseId?.areaDestinationPremiseId || null,
      destinationHubId: value.destinationHubId || null,
      // cod: value.cod,
      // fov: value.fov,

      insured: value.insured === "yes" ? true : false || false,
      clientId: clientDetails?.clientId,
      clientPincode: bookingDetails?.addressDetails?.senderPincode,
      clientName: clientDetails?.clientName || null,
      childShipments,
      ewayBills: allEwayBills?.length
        ? allEwayBills
            // ?.filter((val) => val.error == false)
            .map(({ id, error, errorMsg, ...rest }) => rest)
        : null,

      addressDetails: {
        ...(bookingDetails?.addressDetails || null),
        customerId: 0,
        receiverName: value?.receiverName || null,
        receiverAddressLine1: value?.receiverAddress || null,
        receiverAddressLine2: value?.addressLine2 || null,
        receiverCity: pinCodeServiceableStatus?.city || null,
        receiverPincode: pinCodeServiceableStatus?.pincode || null,
        receiverState: pinCodeServiceableStatus?.state || null,
        receiverAreaId:
          +areaDestinationPremiseId.areaId || value?.areaId || null,
        receiverAreaName: value?.areaId || null,
        receiverMobileNumber: +value?.mobileNumber || "0",
        receiverEmail: value?.receiverEmail || null,
      },
      returnAddressDetail:
        radioActive !== "default"
          ? savedReturnAddressList.map((e, i) => ({
              ...e,
              isAddressEnabled: i === radioActive,
            }))
          : savedReturnAddressList.map((e) => ({
              ...e,
              isAddressEnabled: false,
            })),

      files: filesData?.length
        ? filesData?.map((val) => ({
            fileName: val?.filePath,
            fileId: val?.id,
          }))
        : [],
      userDetailsDto: containsAllPairs(
        obj,
        reviverNameAndAddress?.receiverDetails
      ),
    };

    document.getElementById("submit_credit").blur();
    submitWithPostDetails(payload, value);
  };

  const company_id =
    JSON.parse(localStorage.getItem("selectedCompany"))?.key || null;

  console.log(company_id, "company_id");

  useEffect(() => {
    if (alertData?.visible) {
      resetCallBack();
    }
  }, [alertData]);

  const resetCallBack = () => {
    setFileData([]);
    resetCredit();

    setAllEwayBills([]);
    setFileList([]);
    setChildShipments([]);
    setEwayBill(false);
    setRecentAPICall(!recentAPICall);

    ewayForm.resetFields();
    childDrawerform.resetFields();
    // setIsValidDocument(false);

    const keysToInclude = [
      "clientName",
      "shipmentType",
      "travelBy",
      "service",
      "documentNumber",
      "department",
    ];
    const values = form.getFieldValue();

    form.resetFields(
      Object.keys(values).filter((key) => !keysToInclude.includes(key))
    );
    let documentNumber = +values?.documentNumber;

    form.setFieldsValue({
      documentNumber: ++documentNumber,
      value: "0",
    });

    // if (currentEnv == "prod") {
    //   console.log("called");

    //   handleDocumentInputChange(null, +values?.documentNumber + 1);
    //   // form.validateFields(["documentNumber"]);
    // }

    if (values?.shipmentType == "Non-Dox") {
      form.setFieldsValue({
        weight: 1,
      });
    } else {
      form.setFieldsValue({
        weight: 50,
      });
    }

    getFocusFirstInput("documentNumber");
    getRevicerNameAndAddress("Account");
    setAlertData({
      visible: false,
      message: null,
    });

    window.scrollTo(0, 0);
  };

  const iconStyles = {
    color: "#2e77d0",
    fontSize: "1.17em",
  };

  useEffect(() => {
    setFilteredOptions(clientList);
  }, [clientList]);

  const handleFilter = (inputValue) => {
    const filtered = clientList.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const filterOptionforService = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleService = (e) => {
    // form.setFieldsValue({ documentNumber: null });
    setDocumentLength(e.toLowerCase() === "standard" ? 14 : 11);
    if (e.toLowerCase() === "value plus") {
      setServiceType(e);
    } else {
      setServiceType("standard");
    }

    if (documentNumber && currentEnv == "prod") {
      handleDocumentInputChange(null, documentNumber);
    }
  };

  const cardDesing = {
    width: "auto",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    marginBottom: "1rem",
    padding: "0",
    background: "#F7F5F5",
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: <h3 className="alert-">Booking successfully completed</h3>,
      duration: 3,
      // onClose: resetCallBack,
      description: (
        <>
          <h5 className="order-id">
            Document no.&nbsp; <b>{form.getFieldValue("documentNumber")}</b>
          </h5>

          {childShipments?.length > 0 ? (
            <h5>
              Child document no.&nbsp;{" "}
              <b>
                {childShipments
                  ?.map((e, i) => e?.childDocumentNumber)
                  ?.join(", ")}
              </b>
            </h5>
          ) : (
            ""
          )}
        </>
      ),
    });
  };

  useEffect(() => {
    const resetActiveState = () => {
      document.querySelectorAll(".ant-select-item-option").forEach((option) => {
        option.classList.remove("ant-select-item-option-active");
      });
    };

    const handleTabPress = (event) => {
      if (event.key === "Tab" || event.key === "Enter") {
        resetActiveState();
      }
    };

    document.addEventListener("keydown", handleTabPress);

    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, [dropDownChanges]);

  const handleKeyDown = (event, dataOptions, formItemName, value = "value") => {
    // eslint-disable-next-line no-case-declarations
    let activeOption = document.querySelector(".ant-select-item-option-active");

    switch (event.key) {
      case "Enter":
        setdropDownChanges(!dropDownChanges);

        // event.preventDefault();
        if (formItemName == "deliveryPincode") {
          if (activeOption) {
            const value2 = activeOption.getAttribute("title");
            form.setFieldsValue({ [formItemName]: value2?.slice(0, 6) });
            getDeliveryPincode(activePinOption);
          }
        }
        handleOnEnter(event);

        break;

      case "Tab":
        setdropDownChanges(!dropDownChanges);

        if (activeOption) {
          const value = activeOption.getAttribute("title");

          if (formItemName == "deliveryPincode") {
            const optionExists = dataOptions.some(
              (option) => option?.value == +value?.slice(0, 6)
            );
            if (optionExists) {
              form.setFieldsValue({ [formItemName]: value?.slice(0, 6) });
              getDeliveryPincode(value?.slice(0, 6));
            }
            return;
          }
          const optionExists = dataOptions.some(
            (option) => option?.value?.toLowerCase() == value?.toLowerCase()
          );

          if (optionExists) {
            form.setFieldsValue({ [formItemName]: value });
            if (formItemName === "shipmentType") {
              form.setFieldValue("content", null);
              const fil = contentDiscriptionData?.shipmentType?.find(
                (option) =>
                  option.shipmentTypeName.toLocaleLowerCase() ===
                  value?.toLocaleLowerCase()
              );
              const newVal = {
                key: fil.shipmentTypeId,
                value: fil.shipmentTypeName,
              };
              handleShipmentTypeChange("_", newVal);
            }

            if (formItemName == "service") {
              handleService(value);
            }
          }
        }

        break;

      default:
        break;
    }
  };

  const handleAPIKeyDown = (
    event,
    dataOptions,
    formItemName,
    formKeyName = "value",
    dropDownName
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        setdropDownChanges(!dropDownChanges);
        handleOnEnter(event);

        break;

      case "Tab":
        setdropDownChanges(!dropDownChanges);
        // Get the active option
        // eslint-disable-next-line no-case-declarations
        let activeOption = document.querySelector(
          ".ant-select-item-option-active"
        );

        if (activeOption) {
          const value = activeOption.getAttribute("title");

          const optionExists = dataOptions.find(
            (option) =>
              option[formKeyName].toLowerCase() === value?.toLowerCase()
          );

          if (optionExists) {
            if (dropDownName === "area") {
              const valueId = activeOption.getAttribute("id");

              const filterArea = dataOptions.find(
                (option) => option?.value == +valueId
              );

              form.setFieldsValue({ [formItemName]: filterArea?.label });

              const child = {
                key: optionExists?.value,
              };
              getAreaDestinationPremiseId(optionExists, child);
              return;
            }
            if (dropDownName === "clients") {
              form.setFieldsValue({ [formItemName]: value });
              setRadioActive("default");

              if (currentEnv == "qa") {
                getDepartMent("", optionExists?.value);
              } else {
                setClientDepartment(optionExists);
              }
              setClientID(optionExists?.value);
            }

            return;
          }
        }
        break;
      default:
        break;
    }
  };

  let contentDiscriptionOptions = contentDiscriptionData?.shipmentType?.map(
    (type) => ({
      value: type?.shipmentTypeName,
      label: type?.shipmentTypeName,
      key: type?.shipmentTypeId,
      id: type?.shipmentTypeId,
    })
  );

  useEffect(() => {
    const handleResize = () => {
      setNumber(18);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDocumentInputChange = async (e, b) => {
    const value = e?.target?.value || b;
    setDocumentNumber(value);

    if (!clientID) {
      return;
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      if (e?.target) {
        if (value?.length === 11) {
          form.setFieldValue("service", "fast track");
        } else if (value?.length === 14) {
          if (serviceType?.toLowerCase() == "value plus") {
            form.setFieldValue("service", serviceType);
          } else {
            form.setFieldValue("service", "standard");
          }
        }
      }

      if (value?.length == 11 || value?.length == 14) {
        try {
          const response = await documentValidate(
            value,
            "Account",
            form.getFieldValue("service")
          );

          if (response?.response?.data?.data) {
            setIsValidDocument(true);
          } else {
            setIsValidDocument(false);
          }
        } catch (error) {
          setIsValidDocument(false);
        }
      } else {
        setIsValidDocument(null);
      }
    }, 700);
  };
  // console.log(isValidDocument, "ISVALID");

  useEffect(() => {
    if (isValidDocument !== null) {
      form.validateFields(["documentNumber"]);
    }
  }, [isValidDocument]);

  const applyFocusEffect = (e) => {
    e.target.style.transform = "scale(1.05)";
    e.target.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
  };

  const removeFocusEffect = (e) => {
    e.target.style.transform = "scale(1)";
    e.target.style.boxShadow = "none";
  };

  const handleSearch = (value) => {
    if (value?.length > 0) {
      const filtered = allPincodes
        ?.filter((item) => {
          const pincodeMatch = String(item?.Pincode)?.startsWith(String(value));
          return pincodeMatch;
        })
        .map((item) => ({
          value: item?.Pincode,
          label: `${item?.Pincode} - ${item?.CityName}, ${item?.StateName}`,
          // label: `${item.Pincode}`,
        }));
      if (filtered?.length > 0) {
        setActivePinOption(filtered[0]?.value);
        setOptions(filtered);
      }
    } else {
      setOptions([]);
    }
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="credit-container">
        {alertData?.visible && openNotificationWithIcon("success")}
        <EwayBillDrawer
          allEwayBills={allEwayBills}
          setOpen={(e) => setEwayDrawer(e)}
          open={ewayDrawer}
          setAllEwayBills={setAllEwayBills}
          ewayForm={ewayForm}
        />

        <ChildDrawer
          childDrawerform={childDrawerform}
          childShipments={childShipments}
          setChildShipments={setChildShipments}
          setOpen={(e) => setChildDrawer(e)}
          open={childDrawer}
          documentLength={documentLength}
          isValidAwbForCredit={isValidAwbForCredit}
        />
        <ReturnAddressDrawer
          setOpen={(e) => setReturnAddressDrawer(e)}
          open={returnAddressDrawer}
          alertData={alertData}
          clientID={clientID}
          senderAddress={senderAddress}
          savedReturnAddressList={savedReturnAddressList}
          updateReturnAddressList={updateReturnAddressList}
          radioActive={radioActive}
          upDateRadioActive={(val) => setRadioActive(val)}
        />
        <div style={{ background: "#F5F8FD", padding: "0.7rem" }}>
          <Heading title={"Credit Booking"} />
          <Row
            // gutter={[16, 16]}
            className="account-tab-container"
            style={{ background: "#FFFFFF" }}
          >
            <Col
              xl={16}
              lg={18}
              md={18}
              style={{ padding: "10px 30px 0 30px" }}
            >
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError={{
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                }}
                onFinish={onSubmit}
                className="global-form"
                initialValues={{ returnAddressDetail: [""] }}
              >
                <HeaderWithBottomLine
                  text="Sender Details"
                  icon={false}
                ></HeaderWithBottomLine>
                <Row className="global-form-row" gutter={16}>
                  <Col lg={8} md={8}>
                    <Form.Item
                      name="clientName"
                      label="Client"
                      rules={[
                        {
                          required: true,
                          message: "Select a client",
                        },
                      ]}
                    >
                      <Select
                        id="cashFirst"
                        placeholder="Select a client"
                        disabled={freeze}
                        showSearch
                        onSearch={handleFilter}
                        onSelect={(value, obj) => {
                          setRadioActive("default");
                          if (currentEnv == "qa") {
                            getDepartMent("", value), setClientID(value);
                          } else {
                            setClientDepartment(obj);
                          }
                        }}
                        onKeyDown={(event) =>
                          handleAPIKeyDown(
                            event,
                            filteredOptions,
                            "clientName",
                            "label",
                            "clients"
                          )
                        }
                        defaultActiveFirstOption
                        filterOption={false}
                        options={filteredOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col lg={4} sm={4}>
                    <Form.Item
                      name="department"
                      label="Department"
                      rules={[
                        {
                          required: true,
                          message: "Select a department",
                          validateTrigger: "onSubmit",
                        },
                      ]}
                    >
                      <Select
                        showAction={["focus", "click"]}
                        placeholder="Select a department"
                        disabled={freeze}
                        options={department}
                        onSelect={(value, obj) => {
                          if (currentEnv == "qa") {
                            getDepartMent("", clientID);
                          } else {
                            setClientDepartment(null, obj);
                          }
                        }}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event,
                            department,
                            "department",
                            "label",
                            "department"
                          )
                        }
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col lg={4} sm={6} md={4}>
                    <Form.Item
                      // style={{ width: "65%" }}
                      name="shipmentType"
                      label="Type"
                      rules={[
                        {
                          required: true,
                          message: "Enter value",
                        },
                      ]}
                    >
                      <Select
                        showAction={["focus", "click"]}
                        showSearch={true}
                        placeholder="Select DOX type"
                        className="shipmentType"
                        onChange={handleShipmentTypeChange}
                        disabled={freeze}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event,
                            contentDiscriptionOptions,
                            "shipmentType",
                            "value"
                          )
                        }
                        options={contentDiscriptionOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col lg={4} sm={6}>
                    <Form.Item
                      label="Service"
                      name="service"
                      rules={[
                        {
                          required: true,
                          message: "Enter  value",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value && !value?.length) {
                              return Promise.reject();
                            }

                            if (value < 1) {
                              return Promise.reject(
                                "Length should be more than 1Cm"
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Select
                        showSearch
                        showAction={["focus", "click"]}
                        filterOption={filterOptionforService}
                        className="pincodeType-placecholder"
                        placeholder="Select service"
                        onChange={handleService}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event,
                            servicesOptions,
                            "service",
                            "value"
                          )
                        }
                        options={servicesOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={4} sm={4}>
                    <Form.Item
                      // style={{ width: "77%" }}
                      name="travelBy"
                      rules={[
                        {
                          required: true,
                          message: "Select  value",
                        },
                      ]}
                      label="Travel by"
                    >
                      <Select
                        showSearch
                        showAction={["focus", "click"]}
                        className="pincodeType-placecholder"
                        placeholder="Select  Travel by"
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event,
                            travelByOptions,
                            "travelBy",
                            "value"
                          )
                        }
                        options={travelByOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={24} sm={24}>
                    <HeaderWithBottomLine
                      text="Receiver Details"
                      icon={false}
                    />
                  </Col>
                  <Col lg={6} sm={6}>
                    {currentEnv == "qa" ? (
                      <Form.Item
                        label="Document Number"
                        name="documentNumber"
                        type="input"
                        rules={[
                          {
                            required: true,
                            message: null,
                          },
                        ]}
                        onKeyPress={(event) => {
                          if (checkNumbervalue(event)) {
                            event.preventDefault();
                          }
                        }}
                      >
                        <Input
                          placeholder="Enter document number"
                          id="documentNumber"
                          maxLength={14}
                          // disabled={clientID == null}
                          // onChange={handleDocumentInputChange}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Document Number"
                        name="documentNumber"
                        type="input"
                        rules={[
                          {
                            required: true,
                            message: null,
                          },
                          {
                            validator: (_, value) => {
                              if (isValidDocument === null) {
                                return Promise.reject(
                                  "Enter a valid document number."
                                );
                              }
                              if (isValidDocument) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  "Enter a valid document number."
                                );
                              }
                            },
                          },
                        ]}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            handleOnEnter(event);
                          } else if (checkNumbervalue(event)) {
                            event.preventDefault();
                          }
                        }}
                      >
                        <Input
                          placeholder="Enter document number"
                          id="documentNumber"
                          maxLength={14}
                          // disabled={clientID == null}
                          onChange={handleDocumentInputChange}
                        />
                      </Form.Item>
                    )}
                  </Col>

                  <Col lg={9} sm={9} className="deliveryPinInput">
                    <Form.Item
                      name="deliveryPincode"
                      label="Delivery PIN code"
                      // className="pincode"
                      validateStatus={pinCodeServiceableStatus?.status}
                      help={pinCodeServiceableStatus?.msg}
                      rules={[
                        {
                          required: true,
                          message: "Enter a delivery pincode",
                        },
                        () => ({
                          validator(rule, value = "") {
                            if (value?.length > 0 && value?.length < 6) {
                              return Promise.reject(
                                "Pincode should be 6 digits"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      onKeyPress={(event) => {
                        if (checkNumbervalue(event)) {
                          event.preventDefault();
                        }
                      }}
                    >
                      <AutoComplete
                        // options={options}
                        onSearch={handleSearch}
                        options={options?.map((option) => ({
                          ...option,
                          className:
                            option?.value === activePinOption
                              ? "ant-select-item-option-active"
                              : "",
                        }))}
                        // onDropdownVisibleChange={handleDropdownOpen}
                        onSelect={(value, obj) => {
                          getDeliveryPincode(value);
                        }}
                        onFocus={() => setdropDownChanges(!dropDownChanges)}
                        maxLength={6}
                        onKeyDown={(event) => {
                          setActivePinOption(null);
                          handleKeyDown(
                            event,
                            options,
                            "deliveryPincode",
                            "value"
                          );
                        }}
                      >
                        <Input
                          // style={{ border: "none" }}
                          onChange={(e) => {
                            // Prevent any further input if length is 6
                            if (e.target.value?.length == 6) {
                              getDeliveryPincode(e.target.value);
                            } else {
                              setPinCodeServiceableStatus({
                                serviceability: null,
                                // status: "success",
                                msg: null,
                                stateCode: null,
                                city: null,
                              });
                            }
                          }}
                          placeholder="Enter delivery pincode"
                          suffix={
                            <span
                              className={
                                pinCodeServiceableStatus?.serviceability ===
                                "Regular"
                                  ? "green"
                                  : pinCodeServiceableStatus?.serviceability ===
                                    "Premium"
                                  ? "orange"
                                  : "red"
                              }
                            >
                              <Tooltip
                                title={
                                  pinCodeServiceableStatus?.city?.length >
                                  number
                                    ? pinCodeServiceableStatus?.city
                                    : null
                                }
                              >
                                {pinCodeServiceableStatus?.city?.length > number
                                  ? pinCodeServiceableStatus?.city?.slice(
                                      0,
                                      number
                                    ) + "..."
                                  : pinCodeServiceableStatus?.city}
                                {pinCodeServiceableStatus?.city ? "-" : null}
                                {pinCodeServiceableStatus?.stateCode}
                              </Tooltip>
                            </span>
                          }
                        />
                      </AutoComplete>
                    </Form.Item>
                  </Col>
                  <Col lg={9} sm={9}>
                    <Form.Item
                      lg={6}
                      sm={6}
                      label="Area"
                      type="select"
                      name="areaId"
                      placeholder="Search area"
                      rules={[
                        {
                          required: true,
                          message: "Select area",
                        },
                      ]}
                    >
                      <Select
                        onSelect={(val, child) =>
                          getAreaDestinationPremiseId(val, child)
                        }
                        defaultActiveFirstOption // Toggle activeFirstOption based on state
                        placeholder="Search area"
                        showAction={["focus", "click"]}
                        showSearch
                        onKeyDown={(event) => {
                          handleAPIKeyDown(
                            event,
                            areaList,
                            "areaId",
                            "label",
                            "area",
                            "area"
                          );
                        }}
                      >
                        {areaList?.map((item) => (
                          <Select.Option
                            key={item.value}
                            id={item?.value}
                            spid={item.spId}
                            value={item?.label}
                            disabled={item?.disabled}
                          >
                            {item?.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* ///////sdcscsdcds */}

                  <Col lg={6} sm={6}>
                    <AutoSelectAndType
                      form={form}
                      fieldName="receiverName"
                      reviverNameAndAddress={
                        reviverNameAndAddress?.receiverDetails
                      }
                    />
                  </Col>
                  <Col lg={8} sm={8}>
                    <AutoCompleteAddress
                      form={form}
                      returnAddress={false}
                      alertData={alertData}
                      // addressDetailsCallback={null}
                      fieldName="receiverAddress"
                      label="Receiver Address"
                      rules={[
                        {
                          required: true,
                          message: "Enter receiver address",
                        },
                      ]}
                    />
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label="Mobile number"
                      name="mobileNumber"
                      rules={[
                        {
                          required: true,
                          message: null,
                        },
                        () => ({
                          validator(rule, value = "") {
                            if (!value && !value?.length) {
                              return Promise.reject("Enter mobile number");
                            }
                            if (value.length > 0 && value.length < 10) {
                              return Promise.reject(
                                "Enter 10 digits phone number"
                              );
                            } else if (parseInt(value.substring(1, 0)) === 0) {
                              return Promise.reject(
                                "Mobile number should not be 0"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Enter phone number"
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            handleOnEnter(event);
                          } else if (/[^0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Email "
                      name="receiverEmail"
                      rules={[
                        {
                          type: "email",
                          message: "Give the correct email",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter email"
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            handleOnEnter(event);
                          }
                        }}
                        onBlur={(e) =>
                          form.setFieldValue(
                            "returnEmail",
                            e.target.value.toLocaleLowerCase()
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div>
                  <HeaderWithBottomLine
                    text={"Service & Shipment Details"}
                    icon={false}
                  />
                </div>

                <Row className="global-form-row" gutter={16}>
                  <DoxAndNonDox
                    form={form}
                    isNonDox={isNonDox}
                    ewayBill={ewayBill}
                    freeze={freeze}
                    ewayBillValueCheck={(value) => setEwayBill(value)}
                    chilldShipmentLength={chilldShipmentLength}
                    shipmentContentOptions={shipmentContentOptions}
                    handleKeyDown={handleKeyDown}
                  />

                  <Col lg={3} sm={6} md={3}>
                    <Form.Item name="insured" label="Insured">
                      <Select
                        showSearch
                        //  showAction={["focus", "click"]}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase())
                        }
                        showAction={["focus", "click"]}
                        className="pincodeType-placecholder"
                        placeholder="Select insured"
                        optionFilterProp="children"
                        defaultValue={"No"}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event,
                            insuredOptions,
                            "insured",
                            "value"
                          )
                        }
                        options={insuredOptions}
                      ></Select>
                    </Form.Item>
                  </Col>

                  <Col style={{ paddingTop: "26px" }} className="flex-column">
                    <Button
                      type="secondary"
                      className={
                        allEwayBills.length > 0
                          ? "eway-Child-Active"
                          : "eway-Child-InActive"
                      }
                      // size={"large"}
                      style={{
                        height: "34px",
                        width: "max-content",
                        padding: "0 6px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onFocus={applyFocusEffect}
                      onBlur={removeFocusEffect}
                      onClick={() => setEwayDrawer(true)}
                      onKeyPress={(e) =>
                        e.key === "Enter" && setEwayDrawer(true)
                      }
                    >
                      <span className="pr-05">E-way bills</span>
                      <AttachFile width={13} height={13} />
                    </Button>
                    <div>
                      {ewayBill && allEwayBills.length === 0 ? (
                        <span style={{ color: "#FF5376" }}>
                          {" "}
                          Add e-way bill
                        </span>
                      ) : allEwayBills.length > 0 ? (
                        <span style={{ color: "#50CD89" }}>
                          {allEwayBills.length} E-way Bill added
                        </span>
                      ) : null}
                    </div>
                  </Col>

                  {isNonDox ? (
                    <>
                      <Col
                        style={{ paddingTop: "26px" }}
                        className="flex-column"
                      >
                        <Button
                          type="secondary"
                          className={
                            childShipments?.length > 0
                              ? "eway-Child-Active"
                              : "eway-Child-InActive"
                          }
                          // size={"large"}
                          style={{
                            height: "34px",
                            padding: "0 5px",
                            width: "max-content",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onFocus={applyFocusEffect}
                          onBlur={removeFocusEffect}
                          onClick={() => setChildDrawer(true)}
                          onKeyPress={(e) =>
                            e.key === "Enter" && setChildDrawer(true)
                          }
                        >
                          <span className="pr-05"> Child Badge</span>
                          <AttachFile width={14} height={14} />
                        </Button>
                        <div>
                          {childShipments?.length > 0 ? (
                            <span style={{ color: "#50CD89" }}>
                              {childShipments?.length} child added
                            </span>
                          ) : null}
                        </div>
                      </Col>
                      <Col style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                        <b style={{ fontSize: "12px" }}>
                          Note: Please provide accurate Length, Width and Height
                          values to avoid reconciliation charges.
                        </b>
                      </Col>
                    </>
                  ) : null}
                </Row>
                <div className="vertical-space-between">
                  <div>
                    <UploadDocument
                      uploadDocumentCallback={uploadDocumentCallback}
                      setFileList={setFileList}
                      fileList={fileList}
                      filesData={filesData}
                      form={form}
                      billremark={true}
                    >
                      <div className="d-flex flex-end">
                        <Button
                          htmlType="submit"
                          id="submit_credit"
                          className="btn-style cancel-btn ml-1"
                          // onKeyDown={(e) => e.key === "Tab" && scrollToTop()}
                          onFocus={applyFocusEffect}
                          onBlur={removeFocusEffect}
                        >
                          CONFIRM BOOKING
                        </Button>
                      </div>
                    </UploadDocument>
                  </div>
                </div>
                <hr className="divider" />
              </Form>
            </Col>
            <Col
              xl={8}
              lg={6}
              md={6}
              style={{ padding: "10px" }}
              className="pt-1 card-container"
            >
              {senderAddress?.senderName &&
              radioActive === "default" &&
              clientID ? (
                <AddressCard
                  iconStyles={iconStyles}
                  setReturnAddressDrawer={setReturnAddressDrawer}
                  cardDesing={cardDesing}
                  capitalize={capitalize}
                  senderAddress={senderAddress}
                />
              ) : radioActive !== "default" && clientID ? (
                <AddressCard
                  iconStyles={iconStyles}
                  setReturnAddressDrawer={setReturnAddressDrawer}
                  cardDesing={cardDesing}
                  capitalize={capitalize}
                  senderAddress={savedReturnAddressList[radioActive]}
                />
              ) : (
                <Card size="small" title="Sender Address" style={cardDesing}>
                  <Empty description={false} />
                </Card>
              )}
              <div className="">
                <Card
                  size="small"
                  className="dashboard-container-card-div "
                  title={
                    <div>
                      <span className="announcement-text">
                        {" "}
                        Booking Summary{" "}
                      </span>
                    </div>
                  }
                  style={{
                    ...cardDesing,
                    height: "250px",
                    marginBottom: "10px",
                  }}
                >
                  <Empty description={false} />
                </Card>
              </div>
            </Col>
            <Col lg={16} sm={16} md={16}>
              <div style={{ padding: "0px 40px" }}>
                <RecentBooking recentAPICall={recentAPICall} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
};

export default CrediteBooking;
