/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Select,
  DatePicker,
  Spin,
  Table,
  Modal,
} from "antd";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getClientPincodeMapping } from "../../../../../actionsMethods/onboardingMethods";
import postcard from "../../../../../assests/images/master/postcard.svg";
import zone_location_icon from "../../../../../assests/images/master/zone-location-icon.svg";
import zone_location_circle from "../../../../../assests/images/master/zone-location-circle.svg";
import city_icon from "../../../../../assests/images/master/city.svg";
import scooter from "../../../../../assests/images/master/scooter.svg";
import with_in_state from "../../../../../assests/images/master/with_in_state.svg";
import print_icon from "../../../../../assests/images/master/print.svg";
import download_icon from "../../../../../assests/images/master/download.svg";
import rest_of_india from "../../../../../assests/images/master/restofindia-icon.svg";
import search_icon from "../../../../../assests/images/search-icon.svg";
import API from "../../../../../api";
import urls from "../../../../../api/urls";
import { capitaliseWithHyphen } from "../../../../../utils";

const { PATCH_LEAD_CLIENT_PINMAP } = urls;

export const PincodeMapping = ({
  handleStepsChange = () => {},
  goBackStep = () => {},
  getData,
}) => {
  const userId = JSON.parse(localStorage.getItem("userId"));
  const clientLeadId = JSON.parse(localStorage.getItem("clientLeadId"));

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [parentDataSource, setParentDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceParent, setDataSourceParent] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [pincodeModalVisible, setPincodeModalVisible] = useState(false);
  const [modalLabel, setModalLabel] = useState("");
  const [previousName, setPreviousName] = useState("");
  const [inputSearchValue, setInputSearchVal] = useState("");
  const [swappingOption, setSwappingOption] = useState([
    { label: "My Zone 1", value: "myZoneOneMap" },
    { label: "My Zone 2", value: "myZoneTwoMap" },
    { label: "My Zone 3", value: "myZoneThreeMap" },
    { label: "My Zone 4", value: "myZoneFourMap" },
  ]);
  const [swappingOption_, setSwappingOption_] = useState([
    { label: "My Zone 1", value: "myZoneOneMap" },
    { label: "My Zone 2", value: "myZoneTwoMap" },
    { label: "My Zone 3", value: "myZoneThreeMap" },
    { label: "My Zone 4", value: "myZoneFourMap" },
  ]);
  const dispatch = useDispatch();
  const columns = [
    {
      title: (
        <div className="d-flex align-center">
          <img src={scooter} />
          <span>Local</span>
        </div>
      ),
      dataIndex: "local",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span
                style={{ color: "#000" }}
                // className="pointer"
                // onClick={() =>
                //   openModal(text, _record, index, "localMap", "localValue")
                // }
              >
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.localValue)}
              >
                {_record.localValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={with_in_state} />
          <span>Within State</span>
        </div>
      ),
      dataIndex: "withInState",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span
                style={{ color: "#000" }}
                // className="pointer"
                // onClick={() =>
                //   openModal(
                //     text,
                //     _record,
                //     index,
                //     "withInStateMap",
                //     "withInStateValue"
                //   )
                // }
              >
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.withInStateValue)}
              >
                {_record.withInStateValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 1</span>
        </div>
      ),
      dataIndex: "myZoneOne",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span
                style={{ color: "#2E77D0" }}
                className="pointer"
                onClick={() =>
                  openModal(
                    text,
                    _record,
                    index,
                    "myZoneOneMap",
                    "myZoneOneValue"
                  )
                }
              >
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.myZoneOneValue)}
              >
                {_record.myZoneOneValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 2</span>
        </div>
      ),
      dataIndex: "myZoneTwo",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span
                style={{ color: "#2E77D0" }}
                className="pointer"
                onClick={() =>
                  openModal(
                    text,
                    _record,
                    index,
                    "myZoneTwoMap",
                    "myZoneTwoValue"
                  )
                }
              >
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.myZoneTwoValue)}
              >
                {_record.myZoneTwoValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 3</span>
        </div>
      ),
      dataIndex: "myZoneThree",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span
                style={{ color: "#2E77D0" }}
                className="pointer"
                onClick={() =>
                  openModal(
                    text,
                    _record,
                    index,
                    "myZoneThreeMap",
                    "myZoneThreeValue"
                  )
                }
              >
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.myZoneThreeValue)}
              >
                {_record.myZoneThreeValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 4</span>
        </div>
      ),
      dataIndex: "myZoneFour",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span
                style={{ color: "#2E77D0" }}
                className="pointer"
                onClick={() =>
                  openModal(
                    text,
                    _record,
                    index,
                    "myZoneFourMap",
                    "myZoneFourValue"
                  )
                }
              >
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.myZoneFourValue)}
              >
                {_record.myZoneFourValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={city_icon} />
          <span>Metro</span>
        </div>
      ),
      dataIndex: "metro",
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span
                style={{ color: "#2E77D0" }}
                className="pointer"
                onClick={() =>
                  openModal(text, _record, index, "metroMap", "metroValue")
                }
              >
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.metroValue)}
              >
                {_record.metroValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
      width: 130,
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>Special locations</span>
        </div>
      ),
      dataIndex: "specialPlaces",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span
                style={{ color: "#2E77D0" }}
                className="pointer"
                onClick={() =>
                  openModal(
                    text,
                    _record,
                    index,
                    "specialPlacesMap",
                    "specialPlacesValue"
                  )
                }
              >
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.specialPlacesValue)}
              >
                {_record.specialPlacesValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={rest_of_india} />
          <span>Rest of India</span>
        </div>
      ),
      dataIndex: "restOfIndia",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span
                style={{ color: "#2E77D0" }}
                className="pointer"
                onClick={() =>
                  openModal(
                    text,
                    _record,
                    index,
                    "restOfIndiaMap",
                    "restOfIndiaValue"
                  )
                }
              >
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.restOfIndiaValue)}
              >
                {_record.restOfIndiaValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
  ];

  const swapData = (values) => {
    let x = parentDataSource;
    x[values.bussiness] = {
      [modalLabel]: x[previousName][modalLabel],
      ...x[values.bussiness],
    };
    delete x[previousName][modalLabel];
    setParentDataSource(x);
    getUpdatedTable(x);
    setModalVisible(false);
    form.resetFields();
  };

  const openPincodeModal = (value) => {
    setModalData(value);
    setPincodeModalVisible(true);
  };

  const getPincodeMappingResponse = useSelector(
    (state) => state.onboardingReducer.getClientPincodeMappingReducer,
    shallowEqual
  );

  const { fetching, result } = getPincodeMappingResponse;
  useEffect(() => {
    if (result) {
      setParentDataSource(result);
      getUpdatedTable(result);
    }
  }, [result]);

  useEffect(() => {
    dispatch(getClientPincodeMapping(userId?.premises?.pincode));
  }, []);

  const getUpdatedTable = (result) => {
    let {
      localMap,
      metroMap,
      restOfIndiaMap,
      withInStateMap,
      specialPlacesMap,
      myZoneOneMap,
      myZoneTwoMap,
      myZoneThreeMap,
      myZoneFourMap,
    } = result;
    let local = Object.keys(localMap || []);
    let localValue = Object.values(localMap || []);
    let metro = Object.keys(metroMap || []);
    let metroValue = Object.values(metroMap || []);
    let restOfIndia = Object.keys(restOfIndiaMap || []);
    let restOfIndiaValue = Object.values(restOfIndiaMap || []);
    let withInState = Object.keys(withInStateMap || []);
    let withInStateValue = Object.values(withInStateMap || []);
    let specialPlaces = Object.keys(specialPlacesMap || []);
    let specialPlacesValue = Object.values(specialPlacesMap || []);
    let myZoneOne = Object.keys(myZoneOneMap || []);
    let myZoneOneValue = Object.values(myZoneOneMap || []);
    let myZoneTwo = Object.keys(myZoneTwoMap || []);
    let myZoneTwoValue = Object.values(myZoneTwoMap || []);
    let myZoneThree = Object.keys(myZoneThreeMap || []);
    let myZoneThreeValue = Object.values(myZoneThreeMap || []);
    let myZoneFour = Object.keys(myZoneFourMap || []);
    let myZoneFourValue = Object.values(myZoneFourMap || []);

    let lengthTest = [
      metro?.length,
      restOfIndia?.length,
      withInState?.length,
      specialPlaces?.length,
      myZoneOne?.length,
      myZoneTwo?.length,
      myZoneThree?.length,
      myZoneFour?.length,
    ].sort((a, b) => {
      return a - b;
    });
    let dataSourceTemp = [];
    for (let i = 0; i < lengthTest[lengthTest.length - 1]; i++) {
      dataSourceTemp.push({
        local: local[i] !== undefined ? local[i] : null,
        localValue: localValue[i] !== undefined ? localValue[i] : null,
        metro: metro[i] !== undefined ? metro[i] : null,
        metroValue: metroValue[i] !== undefined ? metroValue[i] : null,
        restOfIndia: restOfIndia[i] !== undefined ? restOfIndia[i] : null,
        restOfIndiaValue:
          restOfIndiaValue[i] !== undefined ? restOfIndiaValue[i] : null,
        withInState: withInState[i] !== undefined ? withInState[i] : null,
        withInStateValue:
          withInStateValue[i] !== undefined ? withInStateValue[i] : null,
        specialPlaces: specialPlaces[i] !== undefined ? specialPlaces[i] : null,
        specialPlacesValue:
          specialPlacesValue[i] !== undefined ? specialPlacesValue[i] : null,
        myZoneOne: myZoneOne[i] !== undefined ? myZoneOne[i] : null,
        myZoneOneValue:
          myZoneOneValue[i] !== undefined ? myZoneOneValue[i] : null,
        myZoneTwo: myZoneTwo[i] !== undefined ? myZoneTwo[i] : null,
        myZoneTwoValue:
          myZoneTwoValue[i] !== undefined ? myZoneTwoValue[i] : null,
        myZoneThree: myZoneThree[i] !== undefined ? myZoneThree[i] : null,
        myZoneFour: myZoneFour[i] !== undefined ? myZoneFour[i] : null,
        myZoneThreeValue:
          myZoneThreeValue[i] !== undefined ? myZoneThreeValue[i] : null,
        myZoneFourValue:
          myZoneFourValue[i] !== undefined ? myZoneFourValue[i] : null,
      });
    }
    setDataSource(dataSourceTemp);
    setDataSourceParent(dataSourceTemp);
  };

  const openModal = (text, record, index, name, value) => {
    let filteredData = swappingOption_.filter((e) => e.value !== name);
    setSwappingOption(filteredData);
    setModalLabel(text);
    setModalVisible(true);
    setPreviousName(name);
  };
  const handleSubmit = async () => {
    let payload = {
      pincode: userId.premises.pincode,
      localMap:
        Object.keys(parentDataSource.localMap || {}).length > 0
          ? parentDataSource.localMap
          : null,
      withInStateMap:
        Object.keys(parentDataSource.withInStateMap || {}).length > 0
          ? parentDataSource.withInStateMap
          : null,
      myZoneOneMap:
        Object.keys(parentDataSource.myZoneOneMap || {}).length > 0
          ? parentDataSource.myZoneOneMap
          : null,
      myZoneTwoMap:
        Object.keys(parentDataSource.myZoneTwoMap || {}).length > 0
          ? parentDataSource.myZoneTwoMap
          : null,
      myZoneThreeMap:
        Object.keys(parentDataSource.myZoneThreeMap || {}).length > 0
          ? parentDataSource.myZoneThreeMap
          : null,
      myZoneFourMap:
        Object.keys(parentDataSource.myZoneFourMap || {}).length > 0
          ? parentDataSource.myZoneFourMap
          : null,
      metroMap:
        Object.keys(parentDataSource.metroMap || {}).length > 0
          ? parentDataSource.metroMap
          : null,
      specialPlacesMap:
        Object.keys(parentDataSource.specialPlacesMap || {}).length > 0
          ? parentDataSource.specialPlacesMap
          : null,
      restOfIndiaMap:
        Object.keys(parentDataSource.restOfIndiaMap || {}).length > 0
          ? parentDataSource.restOfIndiaMap
          : null,
    };
    let clientId = localStorage.getItem("clientLeadId");
    let response = await API.Api(
      PATCH_LEAD_CLIENT_PINMAP + `${clientId}`
    ).patchCustomHeader(payload, { cpId: userId?.userInfo?.premiseId });
    if (response.status) {
      message.success(response.response.data.message);
      handleStepsChange();
    } else {
      message.error(response.error.response.data.message);
    }
  };
  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchVal(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(dataSourceParent, currValue)
        : filterArray(dataSourceParent, currValue);
    setDataSource(filteredData);
  };

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  return (
    <div className="p-2">
      <Row>
        <Col span={24} className="mb-1">
          <div className="space-between" style={{ alignItems: "center" }}>
            <div className="">
              <span className="master-title">Pincode Mapping:</span>
              <span style={{ fontSize: "16px", fontWeight: "500" }}>
                {getData?.organizationDetails.pincode || "N/A"}
              </span>
              <span>
                &nbsp;
                {getData?.organizationDetails?.city + " / " || "N/A"}
              </span>
              <span>
                {getData?.organizationDetails?.state
                  ? getData?.organizationDetails?.state + " / "
                  : ""}
              </span>
              <span>{getData?.organizationDetails?.zone}</span>
            </div>

            <div className="text-right p-1">
              <Input
                placeholder="Search by keyword"
                bordered
                onChange={onSearch}
                style={{ borderRadius: "5px", height: "40px", width: "400px" }}
                suffix={<img src={search_icon} />}
              />
            </div>
          </div>
          <hr className="divider" />
        </Col>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{
            itemRender: itemRender,
            pageSizeOptions: [5, 10, 20, 50, 100],
          }}
          scroll={{
            x: 1700,
          }}
        />
      </Row>
      <hr className="divider" />
      <div className="flex-end p-1">
        <Button className="cancel-btn ml-1 w-15" onClick={goBackStep}>
          BACK
        </Button>
        <Button
          htmlType="submit"
          className="save-btn w-15 ml-1"
          onClick={handleSubmit}
        >
          CONTINUE
        </Button>
      </div>
      <Modal
        className="pincode-mapping-modal"
        title={"Change PIN Code"}
        open={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        // maskClosable={false}
      >
        <Form
          className="global-form pl-4 pr-4 p-2"
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinish={swapData}
        >
          <Row className="global-form-row" gutter={37}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="bussiness"
                label={modalLabel}
                rules={[
                  {
                    message: "Please select any one of below",
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Select" options={swappingOption} />
              </Form.Item>
              <Button htmlType="submit" className="btn-style save-btn mt-1">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        className="pincode-mapping-modal"
        title={"Pincodes"}
        open={pincodeModalVisible}
        onCancel={() => setPincodeModalVisible(false)}
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        <Row className="pincode-mapping-modal-container" gutter={37}>
          {modalData?.map((data, idx) => (
            <Col key={idx} className="pincode-data">
              {data}
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
};

export default PincodeMapping;
