/* eslint-disable indent */
import React, { useState } from "react";
import Profile from "../Profile/index";
import CpOnboardingNew from "../CpOnboardingNew";
import BranchList from "../CompanyBranch/BranchList";
import { Tabs } from "antd";
import "./style.scss";
import constants from "../../constants/constants";
import Heading from "../../components/Heading";

const index = () => {
  const [key, setKey] = useState("1");

  const isKyc = true;

  const onChange = (key) => {
    setKey(key);
  };

  const { ROLES } = constants;
  const { CP_ADMIN } = ROLES;

  const userData = JSON.parse(localStorage.getItem("userinfo"));
  const userRole = userData?.roles?.[0];

  return (
    <div className="profile-wrapper">
      <p
        className="pl-2 pr-2 pt-1 profile-heading"
        style={{ marginBottom: "-15px" }}
      >
        <Heading title={"Back"} />
      </p>
      <Tabs
        defaultActiveKey={"3"}
        activeKey={key}
        items={[
          {
            label: "Personal Profile",
            key: "1",
            children: <Profile />,
          },
          ...(userRole === CP_ADMIN && isKyc
            ? [
                {
                  label: "Company Profile",
                  key: "2",
                  children: <CpOnboardingNew />,
                },
              ]
            : []),
          ...(userRole === CP_ADMIN
            ? [
                {
                  label: "Branch List",
                  key: "3",
                  children: <BranchList />,
                },
              ]
            : []),
        ]}
        onChange={onChange}
      />
    </div>
  );
};

export default index;
