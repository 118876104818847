import React from "react";
import { Row, Col, Input, Button, DatePicker, Form, Select } from "antd";
import moment from "moment";
import RateMatrix from "./RateMatrix";
import RateCharges from "./RateCharges";

const AccountRateCard = ({
  saveRateCard,
  dateFormat,
  disabledDate,
  getFinancialEndDate,
  //rateCardTypeOptions,
  billingCycle,
  setRateCharge,
  quotationRateCharges,
  setRateMatrix,
  quotationRateMatrixs,
  setClickedButton,
  goBackStep,
}) => {
  const [form] = Form.useForm();
  return (
    <Form
      className="global-form"
      layout="vertical"
      form={form}
      scrollToFirstError={{
        behavior: "smooth",
        block: "center",
        inline: "center",
      }}
      onFinish={saveRateCard}
    >
      <Row className="global-form-row p-1" gutter={20}>
        <Col span={2} />
        <Col className="form-inputs" span={10}>
          <Form.Item
            name="fromDate"
            label="Start Date"
            initialValue={moment(new Date(), dateFormat)}
            rules={[
              {
                required: true,
                message: "Please select start date",
              },
            ]}
          >
            <DatePicker disabledDate={disabledDate} format={dateFormat} />
          </Form.Item>
        </Col>
        <Col className="form-inputs" span={10}>
          <Form.Item
            name="toDate"
            label="End Date"
            initialValue={getFinancialEndDate()}
            rules={[
              {
                required: true,
                message: "Please select end date",
              },
            ]}
          >
            <DatePicker
              format={dateFormat}
              disabledDate={(current) =>
                current && current > getFinancialEndDate()
              }
            />
          </Form.Item>
        </Col>
        <Col span={2} />

        {/* <Col span={10}>
          <Form.Item
            name="type"
            label="Rate card type"
            rules={[
              {
                required: true,
                message: "Please select rate card type",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select rate card type"
              options={rateCardTypeOptions}
            />
          </Form.Item>
        </Col> */}
        <Col span={2} />
        <Col className="form-inputs" span={10}>
          <Form.Item
            name="billingCycle"
            label="Billing Cycle"
            rules={[
              {
                required: true,
                message: "Please Billing Cycle",
              },
            ]}
          >
            <Select placeholder="Select Billing Cycle" options={billingCycle} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="commitedBusiness"
            label="Commited Business"
            rules={[
              {
                required: true,
                message: "Please enter commited business",
              },
            ]}
          >
            <Input placeholder="Enter commited business" />
          </Form.Item>
        </Col>
      </Row>
      <div>
        <RateCharges
          rateCharge={setRateCharge}
          quotationRateCharge={quotationRateCharges}
        />
        <RateMatrix
          rateMatrixData={setRateMatrix}
          quotationRateMatrix={quotationRateMatrixs}
        />
      </div>
      <div className="flex-end m-1">
        <Button className="cancel-btn ml-1 w-15" onClick={goBackStep}>
          CANCEL
        </Button>
        <Button className="cancel-btn ml-1  w-15" htmlType="submit">
          SAVE
        </Button>
        <Button
          htmlType="submit"
          className="save-btn  ml-1"
          id="saveNContinue"
          onClick={() => setClickedButton("saveAndContinueButton")}
        >
          SAVE & CONTINUE
        </Button>
      </div>
    </Form>
  );
};
export default AccountRateCard;
