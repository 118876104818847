/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Form,
  message,
  Select,
  Radio,
  Modal,
  Space,
} from "antd";
import moment from "moment";
import RateCharges from "./RateCharges";
import RateMatrix from "./RateMatrix";
import API from "../../../api";
import urls from "../../../api/urls";
import { useParams } from "react-router";
import Heading from "../../Heading";
import { useNavigate } from "react-router-dom";

const {
  CREATE_RATE_CARD,
  GET_RATE_CARD_QUOTATION,
  UPDATE_LEAD_CP_DETAILS,
  LEAD_APPROVAL,
  GET_RATE_CARD_APPROVAL,
  APPROVE_RATE_CARD,
} = urls;

const NewRateCard = ({ getData, handleStepsChange = () => {} }) => {
  let params = useParams();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [form] = Form.useForm();
  const [clickedButton, setClickedButton] = useState(null);
  const [rateMatrixData, setRateMatrixData] = useState({});
  const [rateChargesData, setRateChargesData] = useState([]);
  const [stateCode, setStateCode] = useState(null);
  const [quotationRateCharges, setquotationRateCharge] = useState([]);
  const [quotationRateMatrixs, setQuotationRateMatrix] = useState({});
  const [leadId, setLeadId] = useState(localStorage.getItem("leadCPId"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState("option1");
  const [inputText, setInputText] = useState(null);
  const [loading, setLoading] = useState(false);
  const rejectList = [
    [
      {
        label: "Conflict with existing CP Network",
        value: "Conflict with existing CP Network",
      },
      {
        label: "Lack of commitment enthusiasm",
        value: "Lack of commitment enthusiasm",
      },
      {
        label: "Failed to pass due diligence check",
        value: "Failed to pass due diligence check",
      },
      {
        label: "Lack of industry experience",
        value: "Lack of industry experience",
      },
      {
        label: "Insufficient financial resources",
        value: "Insufficient financial resources",
      },
      { label: "others", value: "others" },
    ],
  ];
  const dateFormat = "DD-MM-YYYY";
  const billingCycle = [
    { label: "Weekly", value: "WEEKLY" },
    { label: "Monthly", value: "MONTHLY" },
    { label: "Quaterly", value: "QUATERLY" },
  ];

  const quotationId = params?.cpLeadQuotation;

  useEffect(() => {
    //if (quotationId) {
    getQuotationDetail(quotationId);
    //}
  }, []);

  const getQuotationDetail = async (quotation) => {
    const res = await API.Api(GET_RATE_CARD_QUOTATION + quotation).get();
    if (res?.status) {
      const data = res?.response?.data;
      form.setFieldsValue({
        fromDate: moment(data?.fromDate, dateFormat),
        toDate: moment(data?.toDate, dateFormat),
        billingCycle: data?.billingCycle,
        type: data?.type,
        commitedBusiness: data?.commitedBusiness,
      });
      setquotationRateCharge(data?.charges);
      setQuotationRateMatrix(data?.rate);
      setLeadId(data?.leadId);
    } else {
      message.error(res?.response?.data?.message || "Can not set rates.");
    }
  };

  const rateCardTypeOptions = [
    {
      label: "Account",
      value: "ACCOUNT",
    },
    {
      label: "Cash",
      value: "CASH",
    },
  ];

  const setRateCharge = (data) => {
    setRateChargesData(data);
  };

  const setRateMatrix = (data) => {
    setRateMatrixData({
      dox: {
        air: {
          standard: convertAsRateEngine(data.standardDox),
          fastTrack: convertAsRateEngine(data.fastTrackAir),
          valuePlus: convertAsRateEngine(data.valuePlusDox),
        },
        surface: {
          standard: convertAsRateEngine(data.standardDox),
          fastTrack: convertAsRateEngine(data.fastTrackSurface),
          valuePlus: convertAsRateEngine(data.valuePlusDox),
        },
      },
      nonDox: {
        air: {
          standard: convertAsRateEngine(data.standardNonDox),
          fastTrack: convertAsRateEngine(data.fastTrackNonDox),
          valuePlus: convertAsRateEngine(data.valuePlusNonDox),
        },
        surface: {
          standard: convertAsRateEngine(data.standardNonDox),
          fastTrack: convertAsRateEngine(data.fastTrackNonDox),
          valuePlus: convertAsRateEngine(data.valuePlusNonDox),
        },
      },
    });
  };

  const convertAsRateEngine = (originalArray) => {
    return originalArray.map((item) => {
      const { title, value, minValue } = item;

      const [maxValueStr] = title
        .replace("Up to ", "")
        .replace("Gms", "")
        .split("-");
      const maxValue = parseInt(maxValueStr) || undefined;

      const transformedItem = {
        minValue: minValue,
        maxValue: maxValue,
        unit: "grams",
        local:
          parseFloat(value.find((obj) => obj.title === "LOCAL")?.value) || 0,
        withinZone:
          parseFloat(
            value.find((obj) => obj.title === "WITHIN STATE")?.value
          ) || 0,
        myZone1:
          parseFloat(value.find((obj) => obj.title === "My Zone 1")?.value) ||
          0,
        myZone2:
          parseFloat(value.find((obj) => obj.title === "My Zone 2")?.value) ||
          0,
        myZone3:
          parseFloat(value.find((obj) => obj.title === "My Zone 3")?.value) ||
          0,
        myZone4:
          parseFloat(value.find((obj) => obj.title === "My Zone 4")?.value) ||
          0,
        metro:
          parseFloat(value.find((obj) => obj.title === "METRO")?.value) || 0,
        roi:
          parseFloat(
            value.find((obj) => obj.title === "REST OF INDIA")?.value
          ) || 0,
        specialLocation:
          parseFloat(
            value.find((obj) => obj.title === "SPECIAL LOCATION")?.value
          ) || 0,
      };

      if (item.design) {
        transformedItem.volume = item.design;
      }

      return transformedItem;
    });
  };

  const saveRateCard = async (val) => {
    const checkBtn = clickedButton === "saveAndContinueButton";
    const payload = {
      ...val,
      stateCode,
      commitedBusiness: parseInt(val.commitedBusiness),
      userId: localStorage.getItem("leadCPId"),
      toDate: val?.toDate?._i,
      fromDate: moment(val?.fromDate?._i).format("DD-MM-YYYY"),
      commission: {
        unit: "INR",
        rateType: "PERCENTAGE",
        slab: [
          {
            minValue: 0,
            value: 20,
          },
        ],
      },
      invoiceType: "CP-TO-HO",
      rate: rateMatrixData,
      charges: rateChargesData?.map((item) => ({
        chargesHead: item?.head,
        rateType: item?.chargeType,
        pricingType: item?.pricingType,
        appliesOn: item?.appliesOn,
        rate: parseInt(item?.rate),
        slabId: item?.slabId,
        percentage: parseInt(item?.percentage),
      })),
    };
    let res;
    res = getData?.cpLeadQuotation
      ? await API.Api(`${CREATE_RATE_CARD}/${getData.quatationId}`).patch(
          payload
        )
      : await API.Api(CREATE_RATE_CARD).post(payload);
    if (res?.status) {
      const response1 = await API.Api(
        UPDATE_LEAD_CP_DETAILS + "/" + localStorage.getItem("leadCPId")
      ).putCustomHeader(
        {
          cpLeadQuotation: res?.response?.data?.name,
          leadId: localStorage.getItem("leadCPId"),
        },
        { "USER-ID": userId?.userInfo?.userId }
      );
      if (response1?.status) {
        message.success("Quotation added successfully.");
        if (checkBtn) {
          handleStepsChange(1);
        }
      } else {
        message.error(response1?.response?.data?.message);
      }
    } else {
      message.error(res?.response?.data?.message || "Internal server error.");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setLoading(true);
    let payload = {
      leadId: params?.leadId,
      approvalResponses: "rejected",
      remark: value === "option4" ? inputText : value,
    };
    let res = await API.Api(`${LEAD_APPROVAL}`).putCustomIdHeader(payload);

    if (res?.status) {
      setLoading(false);
      navigate("/all-approvals");
      setIsModalOpen(false);
    } else {
      setLoading(false);
      message.error(res?.response?.data?.message || "Internal server error");
      setIsModalOpen(false);
    }
  };

  const onRejectChange = (e) => {
    setValue(e.target.value);

    setInputText(null);
  };

  const approveLead = async () => {
    setLoading(true);
    const payload = {
      leadId: params?.leadId,
      approvalResponses: "approved",
    };
    let response = await API.Api(GET_RATE_CARD_APPROVAL).putCustomIdHeader(
      payload
    );
    console.log(response);
    if (response?.status) {
      setLoading(false);
      let response1 = await API.Api(APPROVE_RATE_CARD).post({
        existingUserId: params?.leadId,
        newUserId: `CP_${response?.response?.data?.cpId}`,
        rateCardName: params?.cpLeadQuotation,
      });
      if (response1?.status) {
        message.success(
          response1?.response?.data?.message || "CP onboarded successfully."
        );
        navigate("/all-approvals");
      } else {
        message.error(response1?.response?.data?.message);
      }
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  return (
    <div className="master-main-container p-1">
      <Heading
        title={`${params?.cpLeadQuotation} Approval Confirmation`}
        id={1}
        // navigateTo={!state?.view ? "/all-approvals" : null}
      />
      <Form
        className="global-form"
        layout="vertical"
        form={form}
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
      >
        <Row className="global-form-row p-1" gutter={20}>
          <Col span={2} />
          <Col className="form-inputs" span={10}>
            <Form.Item
              name="fromDate"
              label="Start Date"
              initialValue={moment(new Date(), dateFormat)}
              rules={[
                {
                  required: true,
                  message: "Please select start date",
                },
              ]}
            >
              <DatePicker format={dateFormat} disabled />
            </Form.Item>
          </Col>
          <Col className="form-inputs" span={10}>
            <Form.Item
              name="toDate"
              label="End Date"
              rules={[
                {
                  required: true,
                  message: "Please select end date",
                },
              ]}
            >
              <DatePicker format={dateFormat} disabled />
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={2} />
          <Col span={10}>
            <Form.Item
              name="type"
              label="Rate card type"
              rules={[
                {
                  required: true,
                  message: "Please select rate card type",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select rate card type"
                options={rateCardTypeOptions}
                disabled
              />
            </Form.Item>
          </Col>

          <Col className="form-inputs" span={10}>
            <Form.Item
              name="billingCycle"
              label="Billing Cycle"
              rules={[
                {
                  required: true,
                  message: "Please Billing Cycle",
                },
              ]}
            >
              <Select
                placeholder="Select Billing Cycle"
                options={billingCycle}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={2} />
          <Col span={10}>
            <Form.Item
              name="commitedBusiness"
              label="Commited Business"
              rules={[
                {
                  required: true,
                  message: "Please enter commited business",
                },
              ]}
            >
              <Input placeholder="Enter commited business" disabled />
            </Form.Item>
          </Col>
        </Row>
        <div>
          <RateCharges
            rateCharge={setRateCharge}
            quotationRateCharge={quotationRateCharges}
          />
          <RateMatrix
            rateMatrixData={setRateMatrix}
            quotationRateMatrix={quotationRateMatrixs}
          />
        </div>
        <div className="flex-end m-1">
          <Button
            className="btn-style cancel-btn w-10"
            // onClick={cancelTemlate}
            onClick={() => showModal()}
          >
            REJECT
          </Button>
          <Button
            htmlType="submit"
            className="save-btn  ml-1"
            id="saveNContinue"
            onClick={() => approveLead()}
          >
            APPROVE
          </Button>
        </div>
      </Form>
      <Modal
        title="Reason for  rejection"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Radio.Group onChange={onRejectChange} value={value}>
          <Space direction="vertical">
            {rejectList?.map((item, i) => (
              <Radio key={i} value={item.value}>
                {item.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        {value === "others" && (
          <div style={{ marginTop: "20px" }}>
            <div>Remarks</div>
            <TextArea
              className="input-field-style"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              style={{
                borderRadius: "10px",
                minWidth: "250px",
              }}
              placeholder="Please enter remarks"
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};
export default NewRateCard;
