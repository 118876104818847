import React from "react";
import { Row, Col, Card } from "antd";

import { ReactComponent as Location } from "../../../assests/images/credit-loc.svg";
import { ReactComponent as Mail } from "../../../assests/images/credit-mail.svg";
import { ReactComponent as Phone } from "../../../assests/images/credit-phone.svg";

function AddressCard({
  iconStyles,
  setReturnAddressDrawer,
  cardDesing,
  capitalize,
  senderAddress,
}) {
  const {
    senderName,
    senderAddressLine1,
    returnName,
    returnAddressLine1,
    senderMobileNumber,
    returnMobileNumber,
    senderEmail,
    returnEmail,
  } = senderAddress;

  // console.log(senderAddress, "senderAddress in child");

  return (
    <div className="mt-1">
      <Card
        size="small"
        title={
          <div className="space-between">
            <span>Return Address</span>
            <span
              style={iconStyles}
              className="pointer"
              onClick={() => setReturnAddressDrawer(true)}
            >
              Change
            </span>
          </div>
        }
        style={cardDesing}
      >
        <p style={iconStyles}>{capitalize(senderName || returnName)}</p>
        <Row>
          <Col lg={2} className="flex-start">
            <Location height={30} />
          </Col>
          <Col lg={22}>
            <p>{capitalize(senderAddressLine1 || returnAddressLine1)}</p>
          </Col>
        </Row>
        {(senderMobileNumber || returnMobileNumber) && (
          <Row>
            <Col lg={2} className="flex-start ">
              <Phone height={20} />
            </Col>
            <Col lg={22}>
              <p>{senderMobileNumber || returnMobileNumber}</p>
            </Col>
          </Row>
        )}
        {(senderEmail || returnEmail) && (
          <Row>
            <Col lg={2} className="flex-start">
              <Mail height={20} />
            </Col>
            <Col lg={22}>
              <p>{senderEmail || returnEmail}</p>
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
}

export default AddressCard;
