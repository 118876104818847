/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React from "react";
import { Col, Form, Select, DatePicker, Row } from "antd";
import moment from "moment";

const AllocateDetails = ({
  getDefaultRateCardData,
  // categoryBasedOptions,
  searchValue,
  allocationFromDate,
  allocationToDate,
  disabled,
  templateType,
  changeFromDate,
  // changeToDate,
  setExpiryDate,
  // form,
  existingRateCardType,

  // selectCategoryBasedValue,
}) => {
  const dateFormat = "DD-MM-YYYY";
  const { Option } = Select;

  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  const disabledDateNext = (current) => {
    return current <= moment(allocationFromDate, "DD-MM-YYYY").endOf("day");
  };

  const changeAllocationFrom = (date, dateString, name) => {
    changeFromDate(date, dateString, name);
  };

  return (
    <Row gutter={40}>
      {/* <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
      >
        <Form.Item
          name="rateCardType"
          label="Select rate card"
          rules={
            templateType !== "view" && [
              {
                required: true,
                message: "Please select category",
              },
            ]
          }
        >
          <Select
            placeholder="Select category"
            options={[
              {
                value: "default",
                label: "Default",
              },
              {
                value: "existing",
                label: "Existing",
              },
            ]}
            onChange={(e) => getDefaultRateCardData(e, e.toString())}
            disabled={disabled}
          />
        </Form.Item>
      </Col> */}
      {/* {existingRateCardType.length !== 0 && (
        <Col
          className="form-inputs"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          offset={1}
        >
          <Form.Item
            name="rateCatdList"
            label="Rate card list"
            rules={
              templateType !== "view" && [
                {
                  required: true,
                  message: "Please select value",
                },
              ]
            }
          >
            <Select
              placeholder="Select value"
              onSearch={(e) => searchValue(e)}
              onChange={(e) => getDefaultRateCardData(e, "ratecardId")}
            >
              {existingRateCardType?.map((option) => (
                <Option key={option?.ratecardId} value={option?.ratecardId}>
                  {option?.ratecardName}
                </Option>
              ))}{" "}
            </Select>
          </Form.Item>
        </Col>
      )} */}
      <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
      >
        <Form.Item
          name="allocatedFromDate"
          label="Allocation From Date"
          // initialValue={moment(allocationFromDate, dateFormat)}
          rules={
            templateType !== "view" && [
              {
                required: true,
                message: "Please select allocation from date",
              },
            ]
          }
        >
          <DatePicker
            disabled={disabled}
            disabledDate={disabledDate}
            format={dateFormat}
            onChange={(date, dateString) =>
              changeAllocationFrom(date, dateString, "allocatedFromDate")
            }
          />
        </Form.Item>
      </Col>
      <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
      >
        <Form.Item
          name="validToDate"
          label="Allocation To Date"
          // initialValue={moment(allocationToDate, dateFormat)}
          rules={
            templateType !== "view" && [
              {
                required: true,
                message: "Please select allocation to date",
              },
            ]
          }
        >
          <DatePicker
            disabled={disabled}
            // disabledDate={disabledDateNext}
            disabledDate={disabledDate}
            format={dateFormat}
            onChange={(date, dateString) =>
              changeAllocationFrom(date, dateString, "validToDate")
            }
          />
        </Form.Item>
      </Col>
      <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
      >
        <Form.Item
          name="expiryThreshold"
          label="Expiry alert threshold (in days)"
          rules={
            templateType !== "view" && [
              {
                required: true,
                message: "Please select expiry alert threshold",
              },
            ]
          }
        >
          <Select
            placeholder="Select expiry alert threshold"
            options={[
              {
                value: "15",
                label: "15",
              },
              {
                value: "30",
                label: "30",
              },
              {
                value: "45",
                label: "45",
              },
              {
                value: "60",
                label: "60",
              },
            ]}
            onChange={setExpiryDate}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AllocateDetails;
