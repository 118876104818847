/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import moment from "moment";
const nullishValueCheck = (item) => {
  return item.value !== null && item.value !== "0.00"
    ? parseFloat(item.value).toFixed(2)
    : parseFloat(1.0).toFixed(2);
};

export const getRatesDox = (data) => {
  let rateData = [];
  data.map((e) => {
    let temp = {};
    e.value.map((item) => (temp[item.title] = nullishValueCheck(item)));
    rateData.push({
      rateMode: e.title.split(" ").includes("up")
        ? e.title.split("to ")[1]
        : `Additional Weight Slab ${e.title.split("Add ")[1]}`,
      rateTypes: temp,
    });
  });
  return rateData;
};

export const getRatesNonDoxSurface = (data) => {
  const temp1 = data[0].value.reduce((acc, item) => {
    acc[item.title] = nullishValueCheck(item);
    return acc;
  }, {});

  const temp2 = data[2].value.reduce((acc, item) => {
    acc[item.title] = nullishValueCheck(item);
    return acc;
  }, {});

  return [
    {
      rateMode: "Weight Slab 1000 Gms",
      rateTypes: temp1,
    },
    {
      rateMode: `Additional Weight Slab ${data[2].title.split("Add ")[1]}`,
      rateTypes: temp2,
    },
  ];
};

export const getRatesNonDoxAir = (data) => {
  const temp1 = data[1].value.reduce((acc, item) => {
    acc[item.title] = nullishValueCheck(item);
    return acc;
  }, {});

  const temp2 = data[2].value.reduce((acc, item) => {
    acc[item.title] = nullishValueCheck(item);
    return acc;
  }, {});

  return [
    {
      rateMode: "Weight Slab 1000 Gms",
      rateTypes: temp1,
    },
    {
      rateMode: `Additional Weight Slab ${data[2].title.split("Add ")[1]}`,
      rateTypes: temp2,
    },
  ];
};

export const fovFormInitialValues = {
  fov: "0.00",
  insurance: "0.00",
  handlingCharges: "0.00",
  fuelServiceCharges: "0.00",
  codCharges: "0.00",
  premiumServiceCharges: "0.00",
};

export const fovChargeRule = [
  {
    required: true,
    pattern: /^[0-9].*/g,
    message: "Initial value should be numeric.",
  },
  {
    pattern: /^\d{1,9}(\.\d{0,2})?$/,
    message: "Format should be 000.00",
  },
];

export const standardTempFun = (standardTemp) => {
  let standardDoxTemp = [];
  let standardNonDoxTemp = [];

  standardTemp.map((item) => {
    item.map((e) => {
      switch (e.docType) {
        case "DOX": {
          e.transports.map((item) => {
            switch (item.mode) {
              case "Surface": {
                item.rates.map((rateValue, index) => {
                  standardDoxTemp.push({
                    title:
                      index === item.rates.length - 1
                        ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                        : `up to ${rateValue.rateMode}`,
                    design: index === item.rates.length - 1 ? true : null,
                    value: Object.entries(item.rates[index]?.rateTypes).map(
                      ([title, value]) => ({
                        title,
                        value: parseFloat(value).toFixed(2),
                      })
                    ),
                  });
                });
              }
            }
          });
          break;
        }

        case "NON_DOX":
          {
            e.transports.map((item) => {
              switch (item.mode) {
                case "Surface": {
                  standardNonDoxTemp.push({
                    title: "By Surface (Per Kg)",
                    design: null,
                    value: Object.entries(item.rates[0]?.rateTypes).map(
                      ([title, value]) => ({ title, value })
                    ),
                  });
                  break;
                }
                case "Air": {
                  item.rates.map((rateItem, index) => {
                    standardNonDoxTemp.push({
                      title:
                        index === item.rates.length - 1
                          ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                          : "By Air (Per Kg)",
                      design: index === item.rates.length - 1 ? true : null,
                      value: Object.entries(item.rates[index]?.rateTypes).map(
                        ([title, value]) => ({ title, value })
                      ),
                    });
                  });
                  break;
                }
              }
            });
          }
          break;
      }
    });
  });

  return { standardDoxTemp, standardNonDoxTemp };
};

export const fastTrackTempFun = (fastTrackTemp) => {
  let fastTrackDoxSurfaceTemp = [];
  let fastTrackDoxAirTemp = [];
  let fastTrackNonDoxTemp = [];
  fastTrackTemp.map((item) => {
    item.map((e) => {
      switch (e.docType) {
        case "DOX": {
          e.transports.map((item) => {
            switch (item.mode) {
              case "Surface": {
                item.rates.map((rateValue, index) => {
                  fastTrackDoxSurfaceTemp.push({
                    title:
                      index === item.rates.length - 1
                        ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                        : `up to ${rateValue.rateMode}`,
                    design: index === item.rates.length - 1 ? true : null,
                    value: Object.entries(item.rates[index]?.rateTypes).map(
                      ([title, value]) => ({ title, value })
                    ),
                  });
                });
                break;
              }
              case "Air": {
                item.rates.map((rateValue, index) => {
                  fastTrackDoxAirTemp.push({
                    title:
                      index === item.rates.length - 1
                        ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                        : `up to ${rateValue.rateMode}`,
                    design: index === item.rates.length - 1 ? true : null,
                    value: Object.entries(item.rates[index]?.rateTypes).map(
                      ([title, value]) => ({ title, value })
                    ),
                  });
                });
              }
            }
          });

          break;
        }

        case "NON_DOX":
          {
            e.transports.map((item) => {
              switch (item.mode) {
                case "Surface": {
                  fastTrackNonDoxTemp.push({
                    title: "By Surface (Per Kg)",
                    design: null,
                    value: Object.entries(item.rates[0]?.rateTypes).map(
                      ([title, value]) => ({ title, value })
                    ),
                  });
                  break;
                }
                case "Air": {
                  item.rates.map((rateItem, index) => {
                    fastTrackNonDoxTemp.push({
                      title:
                        index === item.rates.length - 1
                          ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                          : "By Air (Per Kg)",
                      design: index === item.rates.length - 1 ? true : null,
                      value: Object.entries(item.rates[index]?.rateTypes).map(
                        ([title, value]) => ({ title, value })
                      ),
                    });
                  });
                  break;
                }
              }
            });
          }
          break;
      }
    });
  });
  return { fastTrackDoxSurfaceTemp, fastTrackDoxAirTemp, fastTrackNonDoxTemp };
};

export const valuePlusTempFun = (valuePlusTemp) => {
  let valuePlusDoxTemp = [];
  let valuePlusNonDoxTemp = [];

  valuePlusTemp.map((item) => {
    item.map((e) => {
      switch (e.docType) {
        case "DOX": {
          e.transports.map((item) => {
            switch (item.mode) {
              case "Surface": {
                item.rates.map((rateValue, index) => {
                  valuePlusDoxTemp.push({
                    title:
                      index === item.rates.length - 1
                        ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                        : `up to ${rateValue.rateMode}`,
                    design: index === item.rates.length - 1 ? true : null,
                    value: Object.entries(item.rates[index]?.rateTypes).map(
                      ([title, value]) => ({ title, value })
                    ),
                  });
                });
              }
            }
          });

          break;
        }

        case "NON_DOX":
          {
            e.transports.map((item) => {
              switch (item.mode) {
                case "Surface": {
                  valuePlusNonDoxTemp.push({
                    title: "By Surface (Per Kg)",
                    design: null,
                    value: Object.entries(item.rates[0]?.rateTypes).map(
                      ([title, value]) => ({ title, value })
                    ),
                  });
                  break;
                }
                case "Air": {
                  item.rates.map((rateItem, index) => {
                    valuePlusNonDoxTemp.push({
                      title:
                        index === item.rates.length - 1
                          ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                          : "By Air (Per Kg)",
                      design: index === item.rates.length - 1 ? true : null,
                      value: Object.entries(item.rates[index]?.rateTypes).map(
                        ([title, value]) => ({ title, value })
                      ),
                    });
                  });
                  break;
                }
              }
            });
          }
          break;
      }
    });
  });
  return { valuePlusDoxTemp, valuePlusNonDoxTemp };
};

export const getFieldValues = (data) => {
  return {
    fov: parseFloat(data.fov || 0).toFixed(2),
    insurance: parseFloat(data.insurance || 0).toFixed(2),
    handlingCharges: parseFloat(data.handlingCharges || 0).toFixed(2),
    premiumServiceCharges: parseFloat(data.premiumServiceCharges || 0).toFixed(
      2
    ),
    fuelServiceCharges: parseFloat(data.fuelServiceCharges || 0).toFixed(2),
    codCharges: parseFloat(data.codCharges || 0).toFixed(2),
    financialYear: data.financialYear,
    category: data.category && data.category,
    value: data.values ? data.values.split(",") : [],
    fromDate: moment(data.allocatedFromDate || new Date()),
  };
};
