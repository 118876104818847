import React from "react";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { Spin } from "antd";
const AggreementDetails = () => {
  return (
    <Spin
      spinning={false}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="cp-lead-gen-container p-2">
        <p className="text-center">Coming Soon</p>
      </div>
    </Spin>
  );
};
export default AggreementDetails;
