/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import urls from "../../api/urls";
import API from "../../api";
import { message, Table, Input } from "antd";
import Smcs_Loader from "../../assests/images/loader-new.gif";
import { serialNum, capitalize, convertDate } from "../../utils";
import Heading from "../../components/Heading";
import search_icon from "../../assests/images/search-icon.svg";
import { useNavigate } from "react-router-dom";

// import { ReactComponent as Pfd_red_icon } from "../../assests/images/SVG/pdf-red-icon.svg";

// import "../CPLeadApproval/style.scss";

function BranchList() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  //   const [dataSource, setdataSource] = useState(tableData);
  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(0);
  const navigate = useNavigate();
  const { ALL_BRANCH_LIST } = urls;

  useEffect(() => {
    getStaffTable();
  }, []);

  const getStaffTable = async () => {
    setLoading(true);
    let response = await API.Api(ALL_BRANCH_LIST).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);
      //   const res = response?.response?.data?.leadGenerationResponses;
      //   setPageSize(response?.response?.data?.count);

      const res = response?.response?.data;
      setPageSize(20);

      setTableData(res);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
    // setTableData(mockData);
  };

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
  };

  const reRoute = (pathName, id) => {
    navigate(pathName, {
      state: {
        branchId: id,
      },
    });
  };

  const reRouteDetail = (pathName, rateCardName) => {
    navigate(pathName, {
      state: {
        view: false,
        rateCardName,
      },
    });
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
      render: (text, record) => {
        return record?.status?.toLowerCase() === "approved" ? (
          <a
            onClick={() =>
              reRouteDetail(`/branch-details/${record?.branchId}`, true)
            }
          >
            {text}
          </a>
        ) : (
          <a onClick={() => reRoute("/company-branch", record?.branchId)}>
            {text}
          </a>
        );
      },

      //   render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "CP Name",
      dataIndex: "cpName",
      key: "cpName",

      //   render: (text, val) => {
      //     return (
      //       <>
      //         {text} {" file "} <Pfd_red_icon />
      //       </>
      //     );
      //   },
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDate",
      //   width: 100,
      render: (_, record) => (
        <div>{convertDate(record?.createdDate) || "---"}</div>
      ),
    },
    // {
    //   title: "Phone number",
    //   dataIndex: "officialNumber",
    //   key: "officialNumber",
    //   //   width: 100,
    // },
    // {
    //   title: "Lead Generated by",
    //   dataIndex: "leadGeneratorName",
    //   key: "leadGeneratorName",
    //   //   width: 100,
    // },
    {
      title: "Rate Card",
      dataIndex: "ratecardName",
      key: "ratecardName",
      render: (text, record) => {
        return text ? (
          <a
            onClick={() =>
              reRouteDetail(
                `/branch-approval-confirmation/${record?.ratecardId}`,
                record?.ratecardName
              )
            }
          >
            {text}
          </a>
        ) : (
          "N/A"
        );
      },

      width: 100,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      className: "actions-col",
      render: (_, record) => (
        <div className={`status center ${record?.status?.toLowerCase()}`}>
          {capitalize(record?.status)}
        </div>
      ),
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  return (
    <div className="master-main-container cp-approval-list ml-1">
      <div className="align-center">
        <div className="pl-1-5 fw-600"></div>
        <div className="text-right p-1">
          <Input
            placeholder="Search by Lead ID, Phone number,"
            bordered
            // onChange={onSearch}
            style={{ borderRadius: "5px", height: "40px", width: "400px" }}
            suffix={<img src={search_icon} />}
          />
        </div>
      </div>
      <Table
        className="table-head-sticky"
        loading={{
          indicator: (
            <img
              src={Smcs_Loader}
              style={{ height: "100px", width: "100px" }}
            />
          ),
          spinning: loading,
        }}
        columns={columns}
        // loading={loading}
        defaultCurrent={1}
        onChange={handlePageChange}
        // dataSource={inputSearchValue?.length == 0 ? tableData : dataSource}
        dataSource={tableData}
        pagination={{
          // pageSize: pageSize,
          // total: inputSearchValue.length == 0 ? tableData.length : 10,
          itemRender: itemRender,
        }}
        rowClassName={(record) => !record?.status && "disabled-row"}
      />
    </div>
  );
}

export default BranchList;
