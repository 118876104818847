/* eslint-disable no-unused-vars */
import { Steps, Row, Col, Spin, Button, message } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import BranchAdmin from "./BranchAdmin";
import BranchDetails from "./BranchDetails";
import DeliveryDetails from "./DeliveryDetails";
import Smcs_Loader from "../../assests/images/loader-new.gif";
import Heading from "../../components/Heading";
import "../CpOnboardingNew/style.scss";
import API from "../../api";
import urls from "../../api/urls";
const { GET_KYC_DETAILS, GET_BRANCH_DETAILS, PATCH_BRANCH_DETAILS } = urls;

export const CompanyBranch = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [branchData, setBranchData] = useState(null);

  const [sideStepHide, setSideStepHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const [branchId, setBranchId] = useState(null);

  const onChange = (value) => {
    setCurrent(value);
  };

  const handleStepsChange = () => {
    if (current === 3) {
      setCurrent(3);
    } else {
      setCurrent(current + 1);
    }
  };

  const goBackStep = () => {
    window.scrollTo(0, 0);
    if (current == 0) {
      setCurrent(0);
    } else {
      setCurrent(current - 1);
    }
  };

  const getBranchDetails = async (branchId) => {
    let response = await API.Api(
      `${GET_BRANCH_DETAILS}${branchId}`
    ).getWithAllHeaders();
    if (response?.status) {
      setBranchData(response.response.data);
    } else {
      message.error(response?.response?.data?.message);
    }
  };

  useEffect(() => {
    const branchId =
      localStorage.getItem("branchId") || history?.state?.usr?.branchId;
    console.log(branchId, "branchId");
    setBranchId(branchId);
    if (branchId) {
      getBranchDetails(branchId);
    }
    window.scrollTo(0, 0);
  }, [current]);

  const patchBranchDetails = async (values, checkBtn) => {
    setLoading(true);
    //const branchId = localStorage.getItem("branchId");
    let response = await API.Api(
      PATCH_BRANCH_DETAILS + branchId
    ).putCustomIdHeader({ branchId: +branchId, ...values });
    if (response?.status) {
      setLoading(false);
      message.success(
        response?.response?.data?.message || "Branch updated successfully."
      );
      setCurrent(current + 1);
      //   if (checkBtn) {
      //     setCurrent(current + 1);
      //   }
    } else {
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
      console.log(response?.response?.data?.message);
      setLoading(false);
    }
  };

  const items = [
    {
      title: "Branch Admin",
      description: "Please provide your details",
      content: (
        <BranchAdmin
          handleStepsChange={handleStepsChange}
          goBackStep={goBackStep}
          branchData={branchData}
          patchBranchDetails={patchBranchDetails}
          branchId={branchId}
        />
      ),
    },
    {
      title: "Branch Details",
      description: "Please provide branch details",
      content: (
        <BranchDetails
          handleStepsChange={handleStepsChange}
          goBackStep={goBackStep}
          branchData={branchData}
          patchBranchDetails={patchBranchDetails}
          branchId={branchId}
        />
      ),
    },
    {
      title: "Delivery Details",
      description: "Please provide delivery details",
      content: (
        <DeliveryDetails
          handleStepsChange={handleStepsChange}
          goBackStep={goBackStep}
          branchData={branchData}
          patchBranchDetails={patchBranchDetails}
          branchId={branchId}
        />
      ),
    },
  ];
  return (
    <div className="p-2">
      <Heading title={"Request for new branch"} />
      <div className="master-main-container cp-details-main-container">
        <Spin
          spinning={loading}
          indicator={
            <img
              src={Smcs_Loader}
              style={{ height: "100px", width: "100px" }}
            />
          }
        >
          <Row className={sideStepHide ? "" : "p-1"}>
            {!sideStepHide && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={6}
                className="global-steps-container"
              >
                <Steps
                  current={current}
                  direction="vertical"
                  onChange={onChange}
                  items={items}
                />
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={24} xl={sideStepHide ? 24 : 18}>
              <div>{items[current]?.content}</div>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default CompanyBranch;
