/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Tag,
  Table,
  Input,
  Row,
  Col,
  Spin,
  Dropdown,
  Menu,
  message,
  Modal,
  Select,
  DatePicker,
  Button,
} from "antd";
import search_icon from "../../../assests/images/search-icon.svg";
import "./style.scss";
import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;

import Heading from "../../../components/Heading";
import API from "../../../api";
import { APIs } from "../../../utils/worker";
import { capitalize } from "../../../utils";
import { DownOutlined } from "@ant-design/icons";
import urls from "../../../api/urls";
import { ReactComponent as Download_pin_icon } from "../../../assests/images/download.svg";
import printerIcon from "../../../assests/images/printIcon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

const { GET_PRODUCT_BY_ORDER_ID } = urls;
const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return <a>Previous</a>;
  }
  if (type === "next") {
    return <a>Next</a>;
  }
  return (
    <div>
      <p>{originalElement} </p>
    </div>
  );
};
function OrderRequest() {
  const [loading, setLoading] = useState(false);
  const [tableDate, setTableDate] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [loadingModal, setLoadingModal] = useState(false);
  const [filterData, setFilterData] = useState({
    status: null,
    fromAndToDate: null,
    search: null,
  });
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [total, setTotal] = useState(0);
  const [originalTotal, setOriginalTotal] = useState();

  const [filterdTable, setFilterdTable] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    onSearchAndDownload("search");
  }, [filterData]);

  useEffect(() => {
    getTableData();
  }, [statusUpdated]);

  useEffect(() => {
    getTableData();
    if (categoryArr.length === 0) {
      categoryList();
    }
  }, []);

  let dateFormat = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  let convertTableData = (resData) => {
    let temp = [];
    for (let i = 0; i < resData?.length; i++) {
      let tempObj = {
        orderId: resData[i]?.orderId,
        total: resData[i]?.price?.itemTotal,
        deliveryDetails: resData[i]?.deliveryDetails?.receiver,
        paymentMode: resData[i]?.paymentDetails?.mode,
        paymentRefNumber: resData[i]?.paymentDetails?.referenceNumber,
        price: resData[i]?.price?.orderTotal,
        status: resData[i]?.status,
        quantity: resData[i]?.price?.itemTotal,
        createdDate: resData[i]?.createdDate,
      };
      temp.push(tempObj);
    }
    return temp;
  };

  const onSearchAndDownload = async (key) => {
    let params = {
      status: filterData?.status,
      fromDate:
        filterData?.fromAndToDate !== null
          ? dateFormat(filterData?.fromAndToDate[0]?._d)
          : null,
      toDate:
        filterData?.fromAndToDate !== null
          ? dateFormat(filterData?.fromAndToDate[1]?._d)
          : null,
      search: filterData?.search,
    };
    // let dynamic = key === "search" ? /"v1/order/order-download?" : "/order/v1/search/order?"
    if (key === "search") {
      let response = await API.Api(
        `${APIs.baseURL}/order/v1/search/order?`
      ).getwithParams({ ...params });
      if (response.status) {
        let resData = response?.response?.data;
        let tableData = convertTableData(resData);
        setTotal(resData[0]?.count);

        setFilterdTable(tableData);
      }
    } else {
      window.open(
        `${APIs.baseURL}/order/v1/order-download?&status=${params?.status}&fromDate=${params?.fromDate}&toDate=${params?.toDate}&search=${params?.search}`
      );
    }
  };

  const getTableData = async (p1 = 10, p2 = 0) => {
    setLoading(true);
    let response = await API.Api(
      `${APIs.baseURL}/order/v1/all-orders?pageSize=${p1}&pageNumber=${p2}`
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      setLoading(false);
      let resData = response?.response?.data;
      let tableData = convertTableData(resData);
      setTotal(resData[0]?.count);
      setOriginalTotal(resData[0].count);

      setTableDate(tableData);
    } else {
      if (!response.status) {
        setLoading(false);
      }
    }
  };

  const redirectTo = async (record) => {
    navigate("/order-detail/" + record);
    // setOpenModal(true);
    // setLoadingModal(true);
    // let response = await API.Api(GET_PRODUCT_BY_ORDER_ID + record).get();
    // if (response.status) {
    //   setOrderDetails(response?.response?.data);

    //   setLoadingModal(false);
    // } else {
    //   message.error("Network error.");
    //   setLoadingModal(false);
    // }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      fixed: "left",
      render: (text, record) => (
        <a onClick={() => redirectTo(record?.orderId)}>{text}</a>
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 200,
      render: (val) => {
        const date = new Date(val);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        const formattedDate = date.toLocaleDateString("en-US", options);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 80,
    },
    {
      title: "Customer Name",
      key: "deliveryDetails",
      dataIndex: "deliveryDetails",
      width: 150,
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      width: 100,
    },
    {
      title: "Reference Number",
      dataIndex: "paymentRefNumber",
      key: "paymentRefNumber",
    },
    {
      title: "Price",
      dataIndex: "orderTotal",
      key: "orderTotal",
      width: 100,
      render: (_, record) => (
        <text
          style={{
            fontWeight: "600",
            fontSize: "13px",

            letterSpacing: "0.03em",

            color: "#272C39",
          }}
        >
          ₹{record?.price}
        </text>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      fixed: "right",
      render: (status) => (
        <div
          className={
            status === "APPROVED"
              ? "order-green center"
              : status === "CANCELLED"
              ? "order-pink center"
              : status === "PARTLY-APPROVED"
              ? "order-blue center"
              : status === "DISPATCHED"
              ? "order-green center"
              : status === "PAYMENT_RECEIVED"
              ? "order-blue center"
              : status === "RECEIVED"
              ? "order-green center"
              : "order-yellow center"
          }
          key={status}
        >
          {capitalize(status)}
        </div>
      ),
    },

    // {
    //   title: "Action",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (status, record) => (
    //     <a
    //       disabled={
    //         status.toLowerCase() == "cancelled" ||
    //         status.toLowerCase() == "delivered"
    //           ? true
    //           : false
    //       }
    //       onClick={() => setOrderId(record.orderId)}
    //     >
    //       <Dropdown
    //         disabled={
    //           status.toLowerCase() == "cancelled" ||
    //           status.toLowerCase() == "delivered"
    //             ? true
    //             : false
    //         }
    //         overlay={
    //           <>
    //             <Menu onClick={handleMenuClick}>
    //               <Menu.Item
    //                 key={"PENDING"}
    //                 disabled={status !== "PENDING" ? false : true}
    //               >
    //                 Pending
    //               </Menu.Item>
    //               <Menu.Item
    //                 key={"IN-PROGRESS"}
    //                 disabled={status == "PENDING" ? false : true}
    //               >
    //                 In-progress
    //               </Menu.Item>
    //               <Menu.Item
    //                 key={"DISPATCHED"}
    //                 disabled={status == "IN-PROGRESS" ? false : true}
    //               >
    //                 Dispatched
    //               </Menu.Item>
    //               <Menu.Item
    //                 key={"DELIVERED"}
    //                 disabled={status == "DISPATCHED" ? false : true}
    //               >
    //                 Delivered
    //               </Menu.Item>
    //               <Menu.Item
    //                 key={"CANCELLED"}
    //                 disabled={status == "DISPATCHED" ? true : false}
    //               >
    //                 Cancelled
    //               </Menu.Item>
    //             </Menu>
    //           </>
    //         }
    //         trigger={["click"]}
    //       >
    //         <Tag
    //           style={{
    //             width: "100px",
    //             display: "flex",
    //             justifyContent: "space-between",
    //             alignItems: "center",
    //           }}
    //         >
    //           <div>{capitalize(status)} </div>
    //           <DownOutlined className="arrow" />
    //         </Tag>
    //       </Dropdown>
    //     </a>
    //   ),
    // },
    // {
    //   title: "Download In-voice",
    //   dataIndex: "paymentRefNumber",
    //   key: "paymentRefNumber",
    //   render: (text, record) => (
    //     <img
    //       src={printerIcon}
    //       onClick={() => actionHandle(text, record)}
    //       className="pointer"
    //     />
    //   ),
    // },
  ];

  const actionHandle = (e, record) => {
    window.open(`${APIs.baseURL}/order/v1/print/orderby/${record?.orderId}`);
  };

  const categoryList = async () => {
    setLoading(true);
    let response = await API.Api(
      `${APIs.baseURL}/categories`
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      setLoading(false);
      setCategoryArr(response?.response?.data);
    } else {
      if (!response.status) {
        setLoading(false);
      }
    }
  };

  const Modalcolumns = [
    {
      title: "Item",
      dataIndex: "itemName",
      key: "itemName",
      render: (text) => <text>{capitalize(text)}</text>,
    },
    {
      title: "No. of Units",
      dataIndex: "noOfUnits",
      key: "noOfUnits",
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      className: "text-center",
    },
  ];
  const { Option } = Select;

  let optionsForStatus = [
    "PENDING",
    "PARTLY-APPROVED",
    "APPROVED",
    "CANCELLED",
  ];

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    getTableData(pagination.pageSize, pagination.current - 1);
  };
  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="p-2">
        <Heading title={"Order Request"} />
        <div className="order-container">
          <Row className="p-1" gutter={[10]} justify={"end"}>
            <Col lg={6}>
              <Select
                placeholder="Status"
                className="filter-input"
                onSelect={(e) => setFilterData({ ...filterData, status: e })}
              >
                {optionsForStatus?.map((cat) => (
                  <Option key={cat} value={cat}>
                    {capitalize(cat)}
                  </Option>
                ))}
                <Option value={null}>All</Option>
              </Select>
            </Col>
            <Col lg={6}>
              <RangePicker
                className="filter-input"
                onChange={(e) =>
                  setFilterData({ ...filterData, fromAndToDate: e })
                }
              />
            </Col>
            <Col lg={8}>
              <Input
                placeholder="Search by Order ID & Customer name"
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    search: e.target.value == "" ? null : e.target.value,
                  })
                }
                suffix={<img src={search_icon} />}
                className="filter-input"
              />
            </Col>
            {/* <Col lg={3}>
              <Button
                className="btn-add-category"
                onClick={() => onSearchAndDownload("download")}
              >
                <Download_pin_icon style={{ marginRight: "5px" }} /> Download
              </Button>
            </Col> */}
          </Row>
          <Table
            className="order-table-container"
            columns={columns}
            dataSource={
              filterData?.status == null &&
              filterData?.fromAndToDate == null &&
              filterData?.search == null
                ? tableDate
                : filterdTable
            }
            pagination={{
              pageSize: pageSize,
              total: total,
              itemRender: itemRender,
              current: pageNum == 0 ? +1 : pageNum,
            }}
            onChange={handlePageChange}
            scroll={{
              x: 1500,
              y: 500,
            }}
          />
        </div>
        <Modal
          title={"Order Details"}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          width={900}
          footer={null}
          className="hub-show-modal"
        >
          <Spin spinning={loadingModal}>
            <div className="order-details-modal">
              <div>
                <div className="space-between">
                  <div className="space-between w-45">
                    <span>Order ID: </span>
                    <span className="fw-500">{orderDetails?.orderId}</span>
                  </div>
                  <div className="space-between w-45">
                    <span>Invoice Number: </span>
                    <span className="fw-500">{orderDetails?.invoiceId}</span>
                  </div>
                </div>
                <div>
                  <div className="d-flex w-45">
                    <span>Issue Date: </span>
                    <span className="fw-500">
                      &nbsp;{orderDetails?.issueDate}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="space-between">
                  <div className="party-info w-45">
                    <span className="fw-500">Party Informations</span>
                    <span className="fw-500">Head Office</span>
                    <span className="mt-05">
                      {" "}
                      {capitalize(orderDetails?.headOfficeAddress)}{" "}
                    </span>
                  </div>
                  <div className="party-info w-45">
                    <span className="fw-500">Billing Address</span>
                    <span className="fw-500">
                      {capitalize(orderDetails?.premiseName)}
                    </span>
                    <span className="mt-05">
                      {" "}
                      {capitalize(orderDetails?.premiseAddress)}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="space-between">
              <div className="party-info w-45">
                <span className="fw-500">Party Informations</span>
                <span className="fw-500">Head Office</span>
                <span className="mt-05">
                  {" "}
                  {capitalize(orderDetails?.headOfficeAddress)}{" "}
                </span>
              </div>
              <div className="party-info w-45">
                <span className="fw-500">Billing Address</span>
                <span className="fw-500">
                  {capitalize(orderDetails?.premiseName)}
                </span>
                <span className="mt-05">
                  {" "}
                  {capitalize(orderDetails?.premiseAddress)}{" "}
                </span>
              </div>
            </div> */}
            <p className="fw-500 mt-05">Item Details</p>

            <Table
              columns={Modalcolumns}
              dataSource={orderDetails?.invoiceTableDataDtos}
              pagination={false}
              size="small"
              className="order-details-table"
            />

            <p className="text-right mt-05 fw-500 " style={{ width: "90%" }}>
              Subtotal: <span className="ml-1">{orderDetails?.subTotal}</span>
            </p>
          </Spin>
        </Modal>
      </div>
    </Spin>
  );
}

export default OrderRequest;
