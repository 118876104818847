/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { LeadDetailsContainer } from "../../../../components/LeadDetailsContainer";
import React, { useState, useEffect } from "react";
import { Spin, Button, Modal, Radio, Space, Input, message } from "antd";
import Heading from "../../../../components/Heading";
import urls from "../../../../api/urls";
import API from "../../../../api";
import { useParams, useNavigate } from "react-router";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";
import "./style.scss";

function LeadDetails() {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState("option1");
  const [loading, setLoading] = useState(false);
  const [leadData, setleadData] = useState([]);
  const [inputText, setInputText] = useState(null);
  const [status, setStatus] = useState(true);
  const { GET_LEAD_DETAILS, LEAD_APPROVAL } = urls;
  let parmas = useParams();

  useEffect(() => {
    getLeadDataById();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    let payload = {
      leadId: parmas?.id,
      approvalResponses: "rejected",
      remark: value === "option4" ? inputText : value,
    };
    let res = await API.Api(`${LEAD_APPROVAL}`).putCustomIdHeader(payload);

    if (res?.status) {
      setLoading(false);
      setIsModalOpen(false);
    } else {
      message.error(res?.response?.data?.message || "Internal server error");
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    setValue(e.target.value);
    setInputText(null);
  };

  const getLeadDataById = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_LEAD_DETAILS + "/" + parmas?.leadId
    ).getWithUserNPremiseHeader();
    if (response.status) {
      const temp = response?.response?.data;
      const basicDetails = temp?.basicDetail;
      const cpDetailRes = temp?.cpDetail;
      const deliveryDetailRes = temp?.quotation?.deliveryDetails;
      // console.log(deliveryDetailRes, "deliveryDetailRes");

      if (temp?.status == "approved" || temp?.status == "rejected") {
        setStatus(false);
      }
      const basicDetail = {
        leadName: basicDetails.leadName,
        nameOfOwner: basicDetails?.name,
        phoneNumber: basicDetails?.officialNumber,
        emailId: basicDetails?.officialEmailId,
        Remarks: basicDetails?.remark,
      };

      const cpDetaildata = {
        cpName: cpDetailRes?.premiseName,
        cpType: cpDetailRes?.cpType,
        motherHubName: cpDetailRes?.parentPremiseName,
        Zone: cpDetailRes?.zone,
        rateCardType: cpDetailRes?.rateCardType,
        billingCycles: cpDetailRes?.billingCycle,
        addressLine1: cpDetailRes?.addressLine1,
        addressLine2: cpDetailRes?.addressLine2,
        PinCode: cpDetailRes?.pincode,
        City: cpDetailRes?.city,
        stateCode: cpDetailRes?.stateCode,
        stateName: cpDetailRes?.state,
      };

      const pinCodeAreaNameInObject = deliveryDetailRes?.areas?.reduce(
        (acc, item, i) => {
          acc[`Delivery Pincode${i}`] = item?.pincode;
          acc[`Area name${i}`] = item?.assignedAreas
            ?.map((area) => area?.areaName)
            .join(", ");
          return acc;
        },
        {}
      );

      const deliveryDetail = {
        ["No. of delivery Pincodes"]: deliveryDetailRes?.areas?.length,
        [`${"CP Pincode"}`]: deliveryDetailRes?.pincode,
        ...pinCodeAreaNameInObject,
      };

      setleadData({ basicDetail, cpDetaildata, deliveryDetail });
      setLoading(false);
    } else {
      if (!response?.status) {
        setLoading(false);
        message.error(response?.response?.data?.message || "Network error");
      }
    }
  };

  const reRouteToRateCard = () => {
    // navigate(
    //   `/lead-approval-confirmation/${parmas?.id}/${parmas?.status}/${parmas?.leadId}`,
    //   {
    //     state: {
    //       view: true,
    //     },
    //   }
    // );
    navigate(
      `/lead-cp-approval-confirmation/${parmas?.id}/${parmas?.status}/${parmas?.leadId}`
    );
  };

  const style = {
    viewDetailsContainer: {
      padding: "20px 15%",
    },
    viewDetailsHeader: {
      height: "35px",
      background: "#f5faff",
      border: "1px solid #bfdcff",
      borderRadius: "5px",
      fontWeight: "700",
      fontSize: "16px",
      color: "#061624",
      paddingLeft: "17px",
    },
    leadDetailsGrid: {},
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <Modal
        title="Reason for  rejection"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            <Radio value={"option1"}>Option A</Radio>
            <Radio value={"option2"}>Option B</Radio>
            <Radio value={"option3"}>Option C</Radio>
            <Radio value={"option4"}>Other</Radio>
          </Space>
        </Radio.Group>
        {value === "option4" && (
          <div style={{ marginTop: "20px" }}>
            <div>Remarks</div>
            <TextArea
              className="input-field-style"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              style={{
                borderRadius: "10px",
                minWidth: "250px",
              }}
              placeholder="Please enter remarks"
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
            />
          </div>
        )}
      </Modal>
      <div className="p-2">
        <Heading title="Lead Details" />
        <div style={{ background: "white" }}>
          <LeadDetailsContainer
            dataSources={leadData?.basicDetail}
            numberOfColumns={4}
            style={style}
            headingText={"Basic Details"}
          />
          <LeadDetailsContainer
            dataSources={leadData?.cpDetaildata}
            numberOfColumns={4}
            style={style}
            headingText={"CP Details"}
          />
          <LeadDetailsContainer
            dataSources={leadData?.deliveryDetail}
            numberOfColumns={4}
            style={style}
            headingText={"Delivery Details"}
          />
          <hr className="divider" />

          {/* {status && (
            <div className="d-flex flex-end mt-1">
              <Button
                className="btn-style cancel-btn ml-1 w-10"
                onClick={() => showModal()}
              >
                REJECT
              </Button>
              <Button
                htmlType="submit"
                className="save-btn ml-1"
                onClick={() => reRouteToRateCard()}
              >
                APPROVE
              </Button>
            </div>
          )} */}
          {/* {parmas?.status?.toLowerCase() !== "approved" &&
          parmas?.status?.toLowerCase() !== "rejected" ? ( */}
          <div className="d-flex flex-end mt-1 p-1">
            <Button
              className="btn-style cancel-btn ml-1 w-10"
              onClick={() => navigate("/all-approvals")}
            >
              BACK
            </Button>
            <Button
              htmlType="submit"
              className="save-btn ml-1"
              onClick={() => reRouteToRateCard()}
            >
              GO TO RATE CARD
            </Button>
          </div>
          {/* ) : null} */}
        </div>
      </div>
    </Spin>
  );
}

export default LeadDetails;
