/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Collapse,
  Table,
  Select,
  Input,
  Modal,
  Button,
  Popconfirm,
  Form,
  message,
  DatePicker,
  Row,
  Col,
  Divider,
  Space,
} from "antd";
import API from "../../../api";
import urls from "../../../api/urls";
import "./style.scss";
import { capitaliseWithHyphen } from "../../../utils";
import { ReactComponent as Plus } from "../../../assests/images/inputCollectionPlusBtn.svg";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const {
  GET_ALL_CHARGES,
  GET_ALL_RATE_SLABS,
  GET_ALL_CALCULATION_BASIS,
  CREATE_RATE_CHARGE,
  CREATE_RATE_SLAB,
} = urls;

const RateCharges = ({ rateCharge, quotationRateCharge }) => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const { Panel } = Collapse;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rateCharges, setRateCharges] = useState([]);
  const [openSlabModal, setOpenSlabModal] = useState(false);
  const [calculationBasisList, setCalculationBasisList] = useState([]);
  const [rateSlabList, setRateSlabList] = useState([]);
  const [popConVisible, setPopConVisible] = useState(false);
  const [currentSlabDetails, setCurrentSlabDetails] = useState(null);
  const [openCreateSlab, setOpenCreateSlab] = useState(false);
  const [chargeHead, setChargeHead] = useState([]);

  useEffect(() => {
    console.log(quotationRateCharge);
  }, [quotationRateCharge]);

  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  const getFinancialEndDate = () => {
    const currentYear = moment().year();
    const financialEndYear =
      moment().month() >= 3 ? currentYear + 1 : currentYear; // Fiscal year ending March 31st
    return moment(`${financialEndYear}-03-31`, "DD-MM-YYYY");
  };
  const dateFormat = "DD-MM-YYYY";
  const chargeTypeList = useMemo(
    () => [
      { label: "Value", value: "VALUE" },
      { label: "Percentage", value: "PERCENTAGE" },
    ],
    []
  );

  const pricingTypeList = useMemo(
    () => [
      { label: "Fixed", value: "FIXED" },
      { label: "Slab", value: "SLAB" },
    ],
    []
  );

  const appliesOnList = useMemo(
    () => [
      { label: "Shipment", value: "SHIPMENT" },
      { label: "Invoice", value: "INVOICE" },
    ],
    []
  );

  const unitList = useMemo(
    () => [
      { label: "Grams", value: "GRAMS" },
      { label: "Inr", value: "INR" },
    ],
    []
  );

  useEffect(() => {
    rateCharge(
      rateCharges.filter((item) => selectedRowKeys.includes(item._id))
    );
  }, [rateCharges, selectedRowKeys]);

  useEffect(() => {
    initialize();
    getAllCharge();
  }, []);

  const initialize = async () => {
    try {
      const [calcRes, rateRes] = await Promise.all([
        API.Api(GET_ALL_CALCULATION_BASIS).get(),
        API.Api(GET_ALL_RATE_SLABS).get(),
      ]);

      if (calcRes?.status) {
        setCalculationBasisList(
          calcRes?.response?.data?.map((item) => ({ label: item, value: item }))
        );
      }

      if (rateRes?.status) {
        setRateSlabList(
          rateRes?.response?.data?.items?.map((item) => ({
            label: item.name,
            value: item._id,
            ...item,
          }))
        );
      }
    } catch (error) {
      console.error("Error initializing data:", error);
    }
  };

  const getAllCharge = async () => {
    let res = await API.Api(GET_ALL_CHARGES).get();
    if (res?.status) {
      const data = res.response.data.map(({ _id, head, calculationBasis }) => ({
        _id,
        head,
        key: _id,
        calculationBasis,
        global: true,
      }));
      setSelectedRowKeys(data?.map(({ _id }) => _id));
      setChargeHead(
        data.map(({ head, _id }) => ({
          label: head,
          value: head,
          key: _id,
        }))
      );
      setRateCharges(
        data.map((item) => ({
          ...item,
          chargeType: null,
          pricingType: null,
          appliesOn: null,
          rate: null,
          percentage: null,
          slabId: null,
          activeType: "rate",
          rateSlab: null,
        }))
      );
    } else {
      message.error(res?.response?.data?.message || "Internal server error.");
    }
  };

  const onSelectChange = useCallback((newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  }, []);

  const changeRateCharge = useCallback((value, type, record) => {
    if (type === "chargeType") {
      if (value === "VALUE") {
        setRateCharges((prevCharges) =>
          prevCharges.map((item) =>
            item._id === record._id
              ? { ...item, [type]: value, activeType: "rate" }
              : item
          )
        );
      } else if (value === "PERCENTAGE") {
        setRateCharges((prevCharges) =>
          prevCharges.map((item) =>
            item._id === record._id
              ? { ...item, [type]: value, activeType: "percentage" }
              : item
          )
        );
      }
    }
    if (type === "pricingType") {
      if (value === "SLAB") {
        setRateCharges((prevCharges) =>
          prevCharges.map((item) =>
            item._id === record._id
              ? { ...item, [type]: value, activeType: "slabId" }
              : item
          )
        );
      }
    }

    setRateCharges((prevCharges) =>
      prevCharges.map((item) =>
        item._id === record._id ? { ...item, [type]: value } : item
      )
    );
  }, []);

  const changeRateSlab = (val, record) => {
    const { activeType, _id } = record;
    console.log(activeType);
    const propertyMap = {
      slabId: "slabId",
      rate: "rate",
      percentage: "percentage",
    };
    setRateCharges((prevCharges) =>
      prevCharges.map((item) =>
        item._id === _id ? { ...item, [propertyMap[activeType]]: val } : item
      )
    );
  };

  const addMoreCharge = async (val) => {
    setRateCharges((prevCharges) => [
      ...prevCharges,
      {
        _id: Math.random(),
        head: val?.head,
        calculationBasis: val?.calculationBasis,
        chargeType: null,
        pricingType: null,
        appliesOn: null,
        rate: null,
        percentage: null,
        slabId: null,
        activeType: "rate",
        global: false,
      },
    ]);
    setPopConVisible(false);
    form.resetFields();
  };

  const viewSlab = (slabId) => {
    setOpenSlabModal(true);
    const data = rateSlabList?.find((e) => e._id === slabId);
    setCurrentSlabDetails(data);
  };

  const createSlab = () => {
    setOpenCreateSlab(true);
  };

  const submitCreateSlab = async (val) => {
    let payload = {
      ...val,
      toDate: val?.endDate?._i,
      fromDate: moment(val?.startDate?._i).format("DD-MM-YYYY"),
    };
    let res = await API.Api(CREATE_RATE_SLAB).post(payload);
    if (res?.status) {
      initialize();
      setOpenCreateSlab(false);
    } else {
      message.error(res?.response?.data?.message || "Internal server error.");
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Charge Head",
        dataIndex: "head",
        render: (text, record) =>
          text ? (
            capitaliseWithHyphen(text)
          ) : (
            <Input
              style={{ width: "150px" }}
              placeholder="Enter charge head"
              value={text}
            />
          ),
      },
      {
        title: "Charge Type",
        dataIndex: "chargeType",
        render: (text, record) => (
          <Select
            options={chargeTypeList}
            className="rate-select"
            placeholder="Select"
            value={text}
            onChange={(value) => changeRateCharge(value, "chargeType", record)}
          />
        ),
      },
      {
        title: "Applied On",
        dataIndex: "appliesOn",
        render: (text, record) => (
          <Select
            options={appliesOnList}
            className="rate-select"
            placeholder="Select"
            value={text}
            onChange={(value) => changeRateCharge(value, "appliesOn", record)}
          />
        ),
      },
      {
        title: "Calculation Basis",
        dataIndex: "calculationBasis",
        render: (text, record) => capitaliseWithHyphen(text),
      },
      {
        title: "Pricing Type",
        dataIndex: "pricingType",
        render: (text, record) => (
          <Select
            options={pricingTypeList}
            className="rate-select"
            placeholder="Select"
            value={text}
            onChange={(value) => changeRateCharge(value, "pricingType", record)}
          />
        ),
      },
      {
        title: "Rate/Item/Slab ID",
        dataIndex: "activeType",
        render: (text, record) =>
          record?.activeType === "rate" ||
          record?.activeType === "percentage" ? (
            <Input
              style={{ width: "150px" }}
              onChange={(e) => changeRateSlab(e.target.value, record)}
            />
          ) : record?.activeType === "slabId" ? (
            <div className="d-flex">
              <Select
                className="rate-select"
                placeholder="Choose Slab"
                options={rateSlabList}
                style={{ width: "150px" }}
                onChange={(val) => changeRateSlab(val, record)}
              />
              {record?.slabId ? (
                <a
                  className="align-center ml-1"
                  onClick={() => {
                    viewSlab(record?.slabId);
                  }}
                >
                  View Slab
                </a>
              ) : (
                <a className="align-center ml-1" onClick={() => createSlab()}>
                  Create Slab
                </a>
              )}
            </div>
          ) : (
            record[text]
          ),
      },
    ],
    [
      chargeTypeList,
      appliesOnList,
      calculationBasisList,
      rateSlabList,
      pricingTypeList,
      changeRateCharge,
    ]
  );

  const slabColumns = useMemo(
    () => [
      {
        title: "Min Value",
        dataIndex: "minValue",
      },
      {
        title: "Max Value",
        dataIndex: "maxValue",
      },
      {
        title: "Rate",
        dataIndex: "value",
      },
      {
        title: "Rate Type",
        dataIndex: "rateType",
      },
    ],
    [
      chargeTypeList,
      appliesOnList,
      calculationBasisList,
      pricingTypeList,
      changeRateCharge,
    ]
  );

  return (
    <>
      <Collapse collapsible={"header"} defaultActiveKey={["1"]}>
        <Panel header="Rate Charges" key="1">
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            columns={columns}
            dataSource={rateCharges}
            rowKey="_id"
            className="rate-charge-table"
            pagination={false}
          />
          <div className="ml-4">
            <Popconfirm
              icon={null}
              placement="top"
              visible={popConVisible}
              onCancel={() => setPopConVisible(false)}
              title={
                <div>
                  <Form
                    className="global-form"
                    layout="vertical"
                    form={form}
                    scrollToFirstError={{
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    }}
                    onFinish={addMoreCharge}
                  >
                    <Form.Item
                      name="head"
                      label="Charge Head"
                      rules={[
                        {
                          required: true,
                          message: "Enter charge head",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter charge head"
                        style={{ borderRadius: "5px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="calculationBasis"
                      label="Select Calculation Basis"
                      rules={[
                        {
                          required: true,
                          message: "Select calculation basis",
                        },
                      ]}
                    >
                      <Select
                        options={calculationBasisList}
                        placeholder="Select"
                      />
                    </Form.Item>
                  </Form>
                </div>
              }
              onConfirm={() => {
                form.submit();
              }}
              okText="CREATE"
              cancelText="CANCEL"
            >
              <Button
                className="save-btn mt-1"
                onClick={() => setPopConVisible(true)}
              >
                Add More
              </Button>
            </Popconfirm>
          </div>
        </Panel>
      </Collapse>
      <Modal
        title={currentSlabDetails?.name}
        open={openSlabModal}
        onCancel={() => setOpenSlabModal(false)}
        footer={null}
      >
        <div className="space-between">
          <div>
            <p>
              <b>Charges Head:</b>
              &nbsp;&nbsp;{currentSlabDetails?.chargesHead}
            </p>
            <p>
              <b>From Date:</b> {currentSlabDetails?.fromDate?.split("T")[0]}
            </p>
          </div>
          <div>
            <p>
              <b>Unit:</b>
              &nbsp;&nbsp;{currentSlabDetails?.unit}
            </p>
            <p>
              <b>To Date:</b> {currentSlabDetails?.toDate?.split("T")[0]}
            </p>
          </div>
        </div>

        <Table
          columns={slabColumns}
          dataSource={currentSlabDetails?.slab}
          className="rate-charge-table"
          pagination={false}
        />
      </Modal>
      <Modal
        title={"Create Slab"}
        open={openCreateSlab}
        onCancel={() => {
          setOpenCreateSlab(false), form1.resetFields();
        }}
        width={650}
        footer={
          <div className="d-flex flex-end" style={{ padding: "1rem 0 0 0" }}>
            <Button
              onClick={() => setOpenCreateSlab(false)}
              className="cancel-btn w-13"
            >
              {"CANCEL"}
            </Button>
            <Button
              onClick={() => form1.submit()}
              className="save-btn ml-1 w-13"
            >
              {"SUBMIT"}
            </Button>
          </div>
        }
      >
        <div>
          <Form
            className="global-form"
            layout="vertical"
            form={form1}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
            onFinish={submitCreateSlab}
          >
            <Row className="global-form-row" gutter={10}>
              <Col className="form-inputs" span={8}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Enter name",
                    },
                  ]}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>
              </Col>
              <Col className="form-inputs" span={8}>
                <Form.Item
                  name="chargesHead"
                  label="Charge Head"
                  rules={[
                    {
                      required: true,
                      message: "Enter charge head",
                    },
                  ]}
                >
                  <Select
                    options={chargeHead}
                    placeholder="Select charge head"
                  />
                </Form.Item>
              </Col>
              <Col className="form-inputs" span={8}>
                <Form.Item
                  name="unit"
                  label="Unit"
                  rules={[
                    {
                      required: true,
                      message: "Enter unit",
                    },
                  ]}
                >
                  <Select options={unitList} placeholder="Select unit" />
                </Form.Item>
              </Col>
              <Col className="form-inputs" span={8}>
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  initialValue={moment(new Date(), dateFormat)}
                  rules={[
                    {
                      required: true,
                      message: "Please select start date",
                    },
                  ]}
                >
                  <DatePicker disabledDate={disabledDate} format={dateFormat} />
                </Form.Item>
              </Col>
              <Col className="form-inputs" span={8}>
                <Form.Item
                  name="endDate"
                  label="End Date"
                  initialValue={getFinancialEndDate()}
                  rules={[
                    {
                      required: true,
                      message: "Please select end date",
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    disabledDate={(current) =>
                      current && current > getFinancialEndDate()
                    }
                  />
                </Form.Item>
              </Col>
              <Divider>Rate Slabs</Divider>

              <div className="m-05">
                <Form.List name="slab">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Space key={field.key} align="end">
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area ||
                              prevValues.sights !== curValues.sights
                            }
                          >
                            {() => (
                              <Form.Item
                                {...field}
                                label="Min Value"
                                name={[field.name, "minValue"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing min value",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Min Value"
                                  style={{ width: "140px" }}
                                />
                              </Form.Item>
                            )}
                          </Form.Item>

                          <Form.Item
                            {...field}
                            label="Max Value"
                            name={[field.name, "maxValue"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing max value",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Max Value"
                              style={{ width: "140px" }}
                            />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            label="Rate Type"
                            name={[field.name, "rateType"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing rate type",
                              },
                            ]}
                          >
                            <Select
                              options={chargeTypeList}
                              placeholder="Select rate type"
                              style={{ width: "140px" }}
                            />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            label="Value"
                            name={[field.name, "value"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing value",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Value"
                              style={{ width: "140px" }}
                            />
                          </Form.Item>

                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Space>
                      ))}
                      <div className="center w-100">
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add New Slab
                          </Button>
                        </Form.Item>
                      </div>
                    </>
                  )}
                </Form.List>
              </div>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default RateCharges;
