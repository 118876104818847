import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Row, Button } from "antd";
import PacketingCP from "./createPacket";
import ViewPacket from "./viewPacket";

import Heading from "../../../components/Heading";
const baggingTab = () => {
  const [step, setStep] = useState("1");
  const navigate = useNavigate();
  const onChange = (key) => {
    setStep(key);
  };
  const items = [
    {
      key: "1",
      label: "Create Packet",
      children: <PacketingCP />,
    },
    {
      key: "2",
      label: "View Packets",
      children: <ViewPacket />,
    },
  ];
  const handleNext = () => {
    // navigate("/child-scan");
    navigate("/scan");
  };
  return (
    <div className="p-2">
      <Heading
        title="Packeting"
        nextPage={null}
        nextPageTitle={null}
        imgSrc={null}
      />
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey={"1"}
        activeKey={step}
        items={items}
        onChange={onChange}
        style={{ marginTop: "-1.5rem" }}
      />
      <Row className="submit">
        <Button
          className="save"
          type="primary"
          // disabled={allPackets?.createPacketDtos?.length !== 0}
          onClick={handleNext}
        >
          NEXT
        </Button>
      </Row>
    </div>
  );
};

export default baggingTab;
