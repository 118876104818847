/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from "react";
import "./style.scss";

import {
  Button,
  Drawer,
  Space,
  Table,
  message,
  Col,
  Row,
  Form,
  Popconfirm,
  Spin,
  Tooltip,
  notification,
} from "antd";
import {
  NamewithFirstCaptialwithNoSpace,
  checkAlphabets,
  checkNumbervalue,
} from "../../../../utils";
// import useEwayBill from "../../RetailBooking/AddressForm/ewayBillCompo";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";
import { ReactComponent as Edit_icon } from "../../../../assests/images/edit-logo.svg";
import { ReactComponent as DeleteIcon } from "../../../../assests/images/deleteIcon.svg";

import FormItem from "../../../../components/FormItem";
import urls from "../../../../api/urls";
import moment, { duration } from "moment";
import API from "../../../../api";
import { focusFirstInputAndScrollToTop } from "../../../../components/CustomFunctions";
const { EWAYBILL } = urls;

const EwayBillDrawer = ({
  allEwayBills,
  setAllEwayBills,
  setOpen,
  open,
  ewayForm,
}) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(null);
  const currentDate = moment();

  // eslint-disable-next-line no-undef
  const transporterId = process.env.REACT_APP_ENV_TRANSPORTER_ID;
  const todayDateNTimeFormat = currentDate.format("DD/MM/YYYY hh:mm:ss A");
  const [pinCodeServiceableStatus, setPinCodeServiceableStatus] = useState({});
  const [mainData, setMainData] = useState([]);

  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Eway Number",
      dataIndex: "ewayBillNumber",
      key: "ewayBillNumber",
    },
    {
      title: "Eway Bill Date",
      dataIndex: "ewayBillCreateDate",
      key: "ewayBillCreateDate",
      render: (text) => text?.substring(0, 10),
    },
    {
      title: "Valid till",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (text) => text?.substring(0, 10),
    },
    {
      title: "Transport Mode",
      dataIndex: "transMode",
      key: "transMode",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "address",
      key: "address",
      render: (text, item, idx) => {
        return (
          <div className="d-flex">
            <Tooltip title="Edit" placement="bottom">
              <Space onClick={() => handleEdit(idx)}>
                <Edit_icon />
              </Space>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <Popconfirm
                title={
                  <div>
                    <div>Do you want to delete this E-way bill</div>
                  </div>
                }
                onConfirm={() => handleDelete(idx)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon />
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleDelete = (params) => {
    setEdit(null);
    let temp = allEwayBills.filter((val, idx) => idx !== params);
    setAllEwayBills(temp);
    setMainData(null);
  };

  const handleEdit = (params) => {
    setEdit(params);
    let temp = allEwayBills[params];
    setPinCodeServiceableStatus({
      status: "success",
      msg: null,
    });

    setMainData({
      expiryDate: temp?.expiryDate,
      ewayBillCreateDate: temp?.ewayBillCreateDate,
    });
    ewayForm.setFieldsValue({
      ewayBillNumber: temp.ewayBillNumber,
      name: temp.name,
      transMode: temp.transMode,
      expiryDate: temp.expiryDate?.substring(0, 10),
      ewayBillCreateDate: temp.ewayBillCreateDate?.substring(0, 10),
    });
  };

  const openNotification = (type, message) => {
    notification[type]({
      message: null,
      className: "custom-top-right-drawer-notification",
      // duration: 0,
      description: message,
      placement: "topRight",
    });
  };

  const eWayBillHandle = async (value) => {
    if (value?.length === 12) {
      setLoading(true);
      if (allEwayBills?.length) {
        const currentIdx = allEwayBills?.findIndex(
          (item, idx) => item.ewayBillNumber === value
        );

        const isDuplicate = allEwayBills?.some(
          (item) => item.ewayBillNumber === value
        );

        if (isDuplicate && currentIdx !== edit) {
          setLoading(false);
          setPinCodeServiceableStatus({
            status: "error",
            msg: "E-way bill already exists",
          });
          return;
        }
      }
      try {
        // handleLoader(true);
        const response = await API.Api(
          EWAYBILL + "/" + value
        ).getWithPremiseIdHeader();
        if (response?.status) {
          setLoading(false);
          // handleLoader(false);
          const data = JSON.parse(response?.response?.data?.data);
          const validilityDate = moment(data?.validUpto, "DD/MM/YYYY");
          const todayDateNTime = moment(todayDateNTimeFormat, "DD/MM/YYYY");

          if (data?.errorCodes) {
            setPinCodeServiceableStatus({
              status: "error",
              msg: "Please give correct E-way bill number",
            });
            return;
          } else {
            if (todayDateNTime.isAfter(validilityDate)) {
              setPinCodeServiceableStatus({
                status: "error",
                msg: "E-way bill expired",
              });
              return;
            } else if (data?.transporterId !== transporterId) {
              setPinCodeServiceableStatus({
                status: "error",
                msg: "Transporter id is not matching",
              });
              return;
            } else {
              setPinCodeServiceableStatus({
                status: "success",
                msg: null,
              });

              setMainData({
                expiryDate: data?.validUpto,
                ewayBillCreateDate: data?.ewayBillDate,
              });
              ewayForm.setFieldsValue({
                // name: "test",
                transMode:
                  data?.VehiclListDetails[0]?.transMode == "1" ? "Road" : "Air",
                expiryDate: data?.validUpto?.substring(0, 10),
                ewayBillCreateDate: data?.ewayBillDate?.substring(0, 10),
              });

              openNotification("success", "E-way bill request succeeds");
            }
          }
        } else {
          setLoading(false);
          setPinCodeServiceableStatus({
            status: "error",
            msg: "Please give correct E-way bill number",
          });
          openNotification("error", "Please give correct E-way bill number");
        }
      } catch (error) {
        setLoading(false);
        openNotification(
          "error",
          "An error occurred while processing the request"
        );
      }
    }
  };

  const Validator = (minLength, errorMessage) => {
    return (rule, value = "") => {
      return new Promise((resolve, reject) => {
        if (value?.length > 0 && value?.length < minLength) {
          reject(errorMessage);
        } else {
          resolve();
        }
      });
    };
  };

  const NameValidator = Validator(
    3,
    "Name should be between 3 to 20 characters"
  );

  const formProps = {
    name: "retail-address-form",
    className: "retail-address-form",
    autoComplete: "off",
  };

  const onSubmitFormData = async (value) => {
    if (pinCodeServiceableStatus.status == "error") {
      return;
    } else {
      if (edit !== null) {
        let temp = [...allEwayBills];

        temp[edit] = {
          transMode: value.transMode,
          ewayBillNumber: value.ewayBillNumber,
          name: value.name,
          expiryDate: value?.expiryDate,
          ewayBillCreateDate: value?.ewayBillCreateDate,
        };

        setAllEwayBills(temp);
        ewayForm.resetFields();
        setEdit(null);
      } else {
        setAllEwayBills((prevItems) => {
          const updatedItems = [
            ...prevItems,
            {
              transMode: value.transMode,
              ewayBillNumber: value.ewayBillNumber,
              name: value.name,
              // expiryDate: value?.expiryDate,
              // ewayBillCreateDate: value?.ewayBillCreateDate,
              expiryDate:
                mainData !== null ? mainData?.expiryDate : value.expiryDate,
              ewayBillCreateDate:
                mainData !== null
                  ? mainData?.ewayBillCreateDate
                  : value.ewayBillCreateDate,
            },
          ];

          ewayForm.resetFields();
          focusFirstInputAndScrollToTop("eway-1");
          setEdit(null);
          setMainData(null);
          return updatedItems;
        });
      }
    }
  };

  const layoutSizes = { md: 6, lg: 6, xl: 6, sm: 6 };

  const styles = {
    background: "white",
    borderRadius: "5px",
    // color: "red",
    fontSize: "12px",
    fontWeight: "400",
    height: "35px",
    lineHeight: "128%",
    // border: "1px solid #919191",
  };

  useEffect(() => {
    if (open) {
      focusFirstInputAndScrollToTop("eway-1");
    }
  }, [open]);

  function onEnter(event, name) {
    event.preventDefault();

    const inputFieldId = name;
    const targetElement = document.getElementById(inputFieldId);
    if (targetElement) {
      targetElement.focus();
    }
  }

  return (
    <>
      <Drawer
        className="credit-custom-styled-drawer"
        title={"Add/ View e-way Bill"}
        placement="right"
        // size={size}
        width={650}
        onClose={onClose}
        open={open}
      >
        <Spin
          spinning={loading}
          indicator={
            <img src={Smcs_Loader} style={{ height: "50px", width: "50px" }} />
          }
        >
          {/* <h2>851400696102</h2>
        <h2>891400694955</h2> */}

          <div className="eway-drawer-container">
            <Fragment>
              <Form
                {...formProps}
                form={ewayForm}
                onFinish={onSubmitFormData}
                layout="vertical"
                //   onFieldsChange={onFeildsChange} // layout={formLayout}
                scrollToFirstError={{
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                }}
                className="global-form"
              >
                <Row className="global-form-row">
                  <Row style={{ alignItems: "center" }}>
                    <FormItem
                      {...layoutSizes}
                      id={"eway-1"}
                      maxLength={12}
                      validateStatus={pinCodeServiceableStatus?.status}
                      help={pinCodeServiceableStatus?.msg}
                      label={"Eway-bill"}
                      // disabled={edit !== null ? true : false}
                      name="ewayBillNumber"
                      styles={styles}
                      placeholder="Enter Eway-bill"
                      rules={[
                        {
                          required: true,
                          message: "Enter Eway-bill",
                        },
                        () => ({
                          validator(rule, value = "") {
                            if (value?.length > 0 && value?.length < 12) {
                              return setPinCodeServiceableStatus({
                                status: "error",
                                msg: "E-way bill should be 12 digits",
                              });
                            } else {
                              return Promise.resolve();
                            }
                          },
                          validateTrigger: "onSubmit",
                        }),
                      ]}
                      onKeyPress={(event) => {
                        if (event.code == "Enter") {
                          onEnter(event, "name");
                        } else if (
                          checkNumbervalue(event) ||
                          event.key === "."
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (e?.target?.value?.length > 11) {
                          eWayBillHandle(e.target.value);
                        }
                      }}
                    />

                    <FormItem
                      {...layoutSizes}
                      styles={styles}
                      label="Created date"
                      disabled
                      type="input"
                      name="ewayBillCreateDate"
                      placeholder="Created date"
                    />

                    <FormItem
                      {...layoutSizes}
                      styles={styles}
                      label="Expiry date"
                      disabled
                      type="input"
                      name="expiryDate"
                      placeholder="Expiry date"
                    />

                    <FormItem
                      {...layoutSizes}
                      label="Transport mode"
                      styles={styles}
                      disabled
                      type="input"
                      name="transMode"
                      placeholder="Mode of transport"
                    />
                    <FormItem
                      {...layoutSizes}
                      styles={styles}
                      label="Generated by"
                      type="input"
                      id={"name"}
                      name="name"
                      placeholder="Enter value"
                      rules={[
                        {
                          required: false,
                          message: "Please value",
                        },
                        {
                          validator: NameValidator,
                          validateTrigger: "onSubmit",
                        },
                      ]}
                      onKeyDown={(event) => {
                        if (checkAlphabets(event) || event.key === ".") {
                          event.preventDefault();
                        } else {
                          NamewithFirstCaptialwithNoSpace(event);
                        }
                      }}
                    />

                    <Col md={11} sm={11}>
                      <div className="d-flex">
                        <Button
                          className="eway-cancel-btn "
                          onClick={() => {
                            setPinCodeServiceableStatus({});
                            setEdit(null);
                            ewayForm.resetFields();
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          className="eway-save-btn ml-1 "
                          htmlType="submit"
                        >
                          {edit === null ? " + Add" : " Update"}
                        </Button>
                      </div>
                    </Col>

                    <Col md={6} sm={6}></Col>
                  </Row>
                </Row>
              </Form>
            </Fragment>
            <Table
              size="small"
              className="mt-1"
              dataSource={allEwayBills}
              columns={columns}
              pagination={false}
            />
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

export default EwayBillDrawer;
