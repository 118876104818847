/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Tag,
  Table,
  Input,
  Row,
  Col,
  Spin,
  Dropdown,
  Menu,
  message,
  Modal,
  Select,
  DatePicker,
  Button,
  Form,
} from "antd";
import search_icon from "../../../assests/images/search-icon.svg";
import "../OrderRequest/style.scss";
const { RangePicker } = DatePicker;
import { useParams } from "react-router-dom";

import Heading from "../../../components/Heading";
import API from "../../../api";
import { capitalize, isValidDocumentNum, allBarCodeType } from "../../../utils";
import urls from "../../../api/urls";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
const {
  GET_PRODUCT_BY_ORDER_ID,
  APPROVE_CANCEL_ORDER,
  ORDER_CANCEL_REASON,
  APPROVE_ALLOCATE_ORDER,
} = urls;
function OrderDetailsHo() {
  const [orderDetails, setOrderDetails] = useState({});
  const [loadingModal, setLoadingModal] = useState(false);
  const [cancelReasonList, setCancelReasonList] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showAllocateModal, setShowAllocateModal] = useState(false);
  const params = useParams();
  const [orderId, setOrderId] = useState(null);
  const [cancelReason, setCancelReason] = useState(null);
  const [form] = Form.useForm();
  const [barcodeType, setBarcodeType] = useState(null);
  useEffect(() => {
    getOrderDetail();
    getCancelReason();
  }, []);

  const getCancelReason = async () => {
    setLoadingModal(true);
    let response = await API.Api(ORDER_CANCEL_REASON).get();
    if (response.status) {
      setCancelReasonList(
        response?.response?.data?.map((item) => ({
          label: item.cancelReason,
          value: item.cancelReason,
          id: item.id,
        }))
      );
      setLoadingModal(false);
    } else {
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
      setLoadingModal(false);
    }
  };

  const getOrderDetail = async () => {
    setLoadingModal(true);
    let response = await API.Api(GET_PRODUCT_BY_ORDER_ID + params?.id).get();
    if (response.status) {
      setOrderDetails(response?.response?.data);
      setLoadingModal(false);
    } else {
      message.error("Internal server error.");
      setLoadingModal(false);
    }
  };

  const approveOrder = async (order) => {
    setLoadingModal(true);
    let response = await API.Api(APPROVE_CANCEL_ORDER + order?.id).put({
      status: "Approve",
      cancelReason: "",
    });
    if (response.status) {
      getOrderDetail();
    } else {
      setLoadingModal(false);
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  const approveAllocateOrder = async (record) => {
    setOrderId(record?.id);
    setBarcodeType(record?.itemName);
    form.setFieldsValue({
      series: `${record?.lastAssignBarcode} - ${record?.lastAssignEndBarcode} `,
      quantity: record.noOfUnits,
    });
    setShowAllocateModal(true);
  };

  const openCancelModal = (record) => {
    setOrderId(record.id);
    setShowCancelModal(true);
  };

  const cancelRequest = async () => {
    setLoadingModal(true);
    let response = await API.Api(APPROVE_CANCEL_ORDER + orderId).put({
      status: "CANCEL",
      cancelReason: cancelReason,
    });
    if (response.status) {
      setShowCancelModal(false);
      getOrderDetail();
    } else {
      setLoadingModal(false);
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  const allocateSeries = async (value) => {
    setLoadingModal(true);
    let response = await API.Api(APPROVE_ALLOCATE_ORDER + orderId).put({
      startAllocateSeries: parseInt(value?.startAllocateSeries),
      endAllocateSeries: parseInt(value?.endAllocateSeries),
    });
    if (response.status) {
      setShowAllocateModal(false);
      getOrderDetail();
    } else {
      setLoadingModal(false);
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  const Modalcolumns = [
    {
      title: "Item",
      dataIndex: "itemName",
      key: "itemName",
      render: (text) => <text>{capitalize(text)}</text>,
    },
    {
      title: "No. of Units",
      dataIndex: "noOfUnits",
      key: "noOfUnits",
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      className: "text-center",
    },
    {
      title: "Cancel Reason",
      dataIndex: "cancelReason",
      key: "cancelReason",
      className: "text-center",
      render: (status, record) => record.cancelReason || "------",
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (status, record) =>
        record.status === "APPROVED" || record.status === "CANCELLED" ? (
          <>
            <p className="mt-05">{record.status}</p>
            {/* <button
              className="cancel-btn-disable mr-1"
              onClick={() => approveOrder(record)}
            >
              Approve
            </button>
            <button className="cancel-btn-disable">Cancel</button> */}
          </>
        ) : (
          <div className="d-flex">
            {record?.hasAssigned ? (
              <button
                className="cancel-btn mr-1"
                onClick={() => approveAllocateOrder(record)}
              >
                Approve & Allocate
              </button>
            ) : (
              <button
                className="cancel-btn mr-1"
                onClick={() => approveOrder(record)}
              >
                Approve
              </button>
            )}

            <button
              className="cancel-btn"
              onClick={() => openCancelModal(record)}
            >
              Cancel
            </button>
          </div>
        ),
    },
  ];

  return (
    <Spin
      spinning={loadingModal}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="p-2">
        <Heading title={"Order Details"} />
        <div className="order-container p-2">
          <div className="order-details-modal">
            <div>
              <div className="space-between">
                <div className="w-45">
                  <span>Order ID: </span>
                  <span className="fw-500">
                    &nbsp;&nbsp;{orderDetails?.orderId}
                  </span>
                </div>
                <div className="w-45">
                  <span>Invoice Number: </span>
                  <span className="fw-500">
                    &nbsp;&nbsp;{orderDetails?.invoiceId}
                  </span>
                </div>
              </div>
              <div>
                <div className="d-flex w-45">
                  <span>Issue Date: </span>
                  <span className="fw-500">
                    &nbsp;{orderDetails?.issueDate}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="space-between">
                <div className="party-info w-45">
                  <span className="fw-500">Party Informations</span>
                  <span className="fw-500">Head Office</span>
                  <span className="mt-05">
                    {" "}
                    {capitalize(orderDetails?.headOfficeAddress)}{" "}
                  </span>
                </div>
                <div className="party-info w-45">
                  <span className="fw-500">Billing Address</span>
                  <span className="fw-500">
                    {capitalize(orderDetails?.premiseName)}
                  </span>
                  <span className="mt-05">
                    {" "}
                    {capitalize(orderDetails?.premiseAddress)}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p className="fw-500 mt-05">Item Details</p>

          <Table
            columns={Modalcolumns}
            dataSource={orderDetails?.invoiceTableDataDtos}
            pagination={false}
            size="small"
            className="order-details-table"
            rowClassName={(record) =>
              (record.status === "APPROVED" || record.status === "CANCELLED") &&
              "disabled-row"
            }
          />
        </div>
      </div>
      <Modal
        title={"Cancel"}
        open={showCancelModal}
        onCancel={() => {
          setShowCancelModal(false);
        }}
        width={500}
        footer={null}
        style={{ marginTop: 120 }}
        destroyOnClose
        maskClosable={false}
        name="appPoduct"
      >
        <Spin spinning={loadingModal}>
          <div className="order-details-modal">
            <div className="flex-column-center">
              <p>Are you sure want to cancel this order?</p>
              <Select
                placeholder="Select"
                className="filter-input"
                options={cancelReasonList}
                onChange={(e) => setCancelReason(e)}
              />
            </div>

            <div className="flex-end">
              <button
                className="cancel-btn mr-1"
                onClick={() => {
                  setShowCancelModal(false);
                }}
              >
                Cancel
              </button>
              <button className="cancel-btn" onClick={() => cancelRequest()}>
                Submit
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        title={"Approve & Allocate"}
        open={showAllocateModal}
        onCancel={() => {
          form.resetFields();
          setShowAllocateModal(false);
        }}
        width={500}
        footer={null}
        style={{ marginTop: 120 }}
        destroyOnClose
        maskClosable={false}
      >
        <div>
          <Spin spinning={loadingModal}>
            <Form
              layout="vertical"
              form={form}
              scrollToFirstError={{
                behavior: "smooth",
                block: "center",
                inline: "center",
              }}
              onFinish={allocateSeries}
              className="global-form"
              name="form"
            >
              <Row gutter={20} className="global-form-row">
                <Col span={12}>
                  <Form.Item name="series" label="Prior allocated Series">
                    <Input placeholder="Prior allocated Series" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="quantity" label="Quantity">
                    <Input placeholder="Quantity" disabled />
                  </Form.Item>
                </Col>

                <Col span={24}>Allocate Series:</Col>
                <Col span={12} className="mt-05">
                  <Form.Item
                    name="startAllocateSeries"
                    label="From"
                    rules={[
                      {
                        required: true,
                        message: null,
                      },
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject("Enter valid doc number.");
                          }
                          if (allBarCodeType.includes(barcodeType)) {
                            if (!isValidDocumentNum(value, barcodeType)) {
                              return Promise.reject("Enter valid doc number.");
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="From"
                      style={{ textTransform: "capitalize" }}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (/[^0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length > 8) {
                          let val =
                            parseInt(
                              form.getFieldValue("startAllocateSeries")
                            ) + parseInt(form.getFieldValue("quantity"));

                          form.setFieldsValue({
                            endAllocateSeries: val,
                          });
                        }
                      }}
                      maxLength={14}
                      onBlur={() => {
                        let val =
                          parseInt(form.getFieldValue("startAllocateSeries")) +
                          parseInt(form.getFieldValue("quantity"));
                        form.setFieldsValue({
                          endAllocateSeries: val,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="mt-05">
                  <Form.Item
                    name="endAllocateSeries"
                    label="To"
                    rules={[
                      {
                        required: true,
                        message: null,
                      },
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject("Enter valid doc number.");
                          }
                          if (!isValidDocumentNum(value, barcodeType)) {
                            return Promise.reject("Enter valid doc number.");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="To"
                      style={{ textTransform: "capitalize" }}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (/[^0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      maxLength={14}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex-end">
                <Button
                  className="cancel-btn mr-1"
                  onClick={() => {
                    form.resetFields();
                    setShowAllocateModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button className="save-btn" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </Modal>
    </Spin>
  );
}

export default OrderDetailsHo;
