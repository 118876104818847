/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import urls from "../../../api/urls";
import API from "../../../api";
import { message, Table, Input } from "antd";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { serialNum, capitalize, convertDate } from "../../../utils";
import Heading from "../../../components/Heading";
import search_icon from "../../../assests/images/search-icon.svg";
import { useNavigate } from "react-router-dom";

// import { ReactComponent as Pfd_red_icon } from "../../assests/images/SVG/pdf-red-icon.svg";

// import "./style.scss";

function ClientApprovalLeadList() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [dataSource, setdataSource] = useState(tableData);
  const [inputSearchValue, setInputSearchValue] = useState("");

  const [pageSize, setPageSize] = useState(50);
  const [pageNum, setPageNum] = useState(0);
  const navigate = useNavigate();
  const { GET_CLIENT_APPROVAL_LIST } = urls;

  useEffect(() => {
    getClientApprovalList();
  }, []);
  const getClientApprovalList = async () => {
    setLoading(true);
    let response = await API.Api(GET_CLIENT_APPROVAL_LIST).getWithHeader();
    if (response?.status) {
      setLoading(false);
      const res = response?.response?.data;
      // const temp = res?.filter((val) => val?.status == "Waiting for Approval");

      setPageSize(res?.length);
      setTableData(res);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
  };

  const reRoute = (record) => {
    console.log(record, "record");
    navigate(`/approve-client/${record?.clientLeadId}/${record.status}`);
    // console.log(record, "value");
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      key: "clientLeadId",
      dataIndex: "clientLeadId",
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
      // render: (text) => text,
    },
    {
      title: "User name",
      dataIndex: "clientName",
      key: "clientName",

      render: (text, record) => {
        return <a onClick={() => reRoute(record)}>{text}</a>;
      },
      // render: (text, record) => <a onClick={() => reRoute(record)}>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (record) => <div>{record || "--"}</div>,
    },
    {
      title: "Onboarded Date",
      dataIndex: "createDate",
      key: "createDate",
      //   width: 100,
      render: (_, record) => (
        // <div>{convertDate(record?.onboaredOn) || "--"}</div>
        <div>{convertDate(record?.createDate) || "--"}</div>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      //   width: 100,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      //   width: 100,
    },
    {
      title: "Rate card expiry",
      dataIndex: "onboaredOn",
      key: "onboaredOn",
      //   width: 100,
      render: (_, record) => (
        <div>{convertDate(record?.onboaredOn) || "---"}</div>
      ),
    },
    {
      title: "...",
      dataIndex: "...",
      key: "...",
      render: (record) => <div>{record || "--"}</div>,
    },

    // {
    //   title: "Agreed Valid Date ",
    //   dataIndex: "agreedValidDate ",
    //   key: "agreedValidDate ",
    //   render: (record) => <div>{record || "--"}</div>,
    // },

    // {
    //   title: "Status",
    //   key: "status",
    //   dataIndex: "status",
    //   className: "actions-col",

    //   render: (text, record) => (
    //     <div className={`status center  ${text?.toLowerCase()}`}>
    //       {text?.toLowerCase().includes("waiting")
    //         ? capitalize("Pending")
    //         : capitalize(text)}
    //     </div>
    //   ),
    // },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const filterArray = (data, currValue) => {
    return data?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(tableData, currValue)
        : filterArray(tableData, currValue);
    setdataSource(filteredData);
    filteredData;
  };

  return (
    <div className="master-main-container lead-client-approval-list  ml-1">
      <div className="align-center">
        <div className="pl-1-5 fw-600"></div>
        <div className="text-right p-1">
          <Input
            placeholder="Search by keyword"
            bordered
            onChange={onSearch}
            style={{ borderRadius: "5px", height: "40px", width: "400px" }}
            suffix={<img src={search_icon} />}
          />
        </div>
      </div>
      <Table
        className="table-head-sticky"
        loading={{
          indicator: (
            <img
              src={Smcs_Loader}
              style={{ height: "100px", width: "100px" }}
            />
          ),
          spinning: loading,
        }}
        columns={columns}
        // loading={loading}
        defaultCurrent={1}
        onChange={handlePageChange}
        dataSource={inputSearchValue?.length == 0 ? tableData : dataSource}
        // dataSource={tableData}
        pagination={{
          // pageSize: pageSize,
          // total: inputSearchValue.length == 0 ? tableData.length : 10,
          itemRender: itemRender,
        }}
        rowClassName={(record) => !record?.status && "disabled-row"}
      />
    </div>
  );
}

export default ClientApprovalLeadList;
