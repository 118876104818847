/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Space,
  Input,
  Tooltip,
  Popconfirm,
  message,
  Table,
  Button,
  Row,
  Form,
} from "antd";

import { capitalize, serialNum } from "../../../utils";

import urls from "../../../api/urls";
import API from "../../../api";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Edit_icon } from "../../../assests/images/edit-logo.svg";
import { ReactComponent as Mail_sent } from "../../../assests/images/SVG/mail-sent-icon.svg";
import { ReactComponent as Pfd_icon } from "../../../assests/images/SVG/pdf-icon.svg";
import { ReactComponent as Pfd_red_icon } from "../../../assests/images/SVG/pdf-red-icon.svg";

import { ReactComponent as Mail_in } from "../../../assests/images/SVG/mail-in-icon.svg";
import { ReactComponent as Disable_Edit_icon } from "../../../assests/images/SVG/disableEdit.svg";

import search_icon from "../../../assests/images/search-icon.svg";
import check_mark from "../../../assests/images/SVG/check.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import Heading from "../../../components/Heading";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ReactComponent as EyeIcon } from "../../../assests/images/eyeIcon.svg";
import SendEmailModal from "../../ClientLeadList/SendEmailModal";

// import "./style.scss";

const { UPDATE_STAFF, GET_ALL_QUOTE_LIST } = urls;

// import "./style.scss";

const ClientQuotationList = ({ handleEdit = () => {} }) => {
  const param = useParams();
  const history = useLocation();
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getQuoteTable();
  }, []);

  const getQuoteTable = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_ALL_QUOTE_LIST + param?.id
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);
      let temp = response?.response?.data;
      setTableData(temp);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const columns = [
    {
      title: "Quote ID",
      dataIndex: "quoteId",
      key: "quoteId",
      align: "center",
      width: 50,
      render: (qId, record, idx) => {
        let check =
          record.status.toLowerCase() !== "approved" &&
          record.status.toLowerCase() !== "rejected";
        return check ? (
          <a
            onClick={() =>
              navigate(
                "/client/" +
                  param?.id +
                  "/new-quotation/" +
                  qId +
                  "/status/" +
                  record?.status
              )
            }
          >
            {qId}
          </a>
        ) : (
          qId
        );
      },
    },
    {
      title: "Quotation",
      dataIndex: "quoteName",
      align: "center",
      key: "quoteName",
      width: 100,
      render: (text, val) => {
        return (
          <>
            <div>{text || "N/A"}</div>
          </>
        );
      },
    },
    {
      title: "Client Email",
      dataIndex: "clientEmail",
      key: "clientEmail",
      align: "center",
      width: 100,
    },
    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      align: "center",
      width: 100,
      render: (text, val) => {
        return (
          <>
            <div>{text?.split(" ")[0]}</div>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,

      render: (text) => {
        return (
          <div className={`lead-list-status ${text?.toLowerCase()}`}>
            {text?.toLowerCase().includes("waiting")
              ? capitalize("Pending")
              : capitalize(text)}
          </div>
        );
      },
    },

    {
      title: "Actions",
      key: "status",
      dataIndex: "status",
      align: "center",
      className: "actions-col",
      width: 150,

      render: (status, record) => {
        return (
          <Space size="middle">
            {status?.toLowerCase() == "rejected" ||
            status?.toLowerCase() == "approved" ? (
              <p style={{ marginBottom: "-10px" }}>
                <Disable_Edit_icon />
              </p>
            ) : (
              <Tooltip title={"Edit"} placement="bottom">
                <p
                  className="pointer"
                  style={{ marginBottom: 0 }}
                  onClick={() =>
                    navigate(
                      "/client/" +
                        param?.id +
                        "/new-quotation/" +
                        record?.quoteId +
                        "/status/" +
                        record?.status
                    )
                  }
                >
                  <Edit_icon />
                </p>
              </Tooltip>
            )}

            <Tooltip title={"PDF"} placement="bottom">
              <p
                className="pointer"
                style={{ marginBottom: 0 }}
                onClick={() => handleEdit()}
              >
                <Pfd_icon />
              </p>
            </Tooltip>
            <Tooltip title={"Send mail to client"} placement="bottom">
              <p
                className="pointer"
                style={{ marginBottom: 0 }}
                onClick={() => showModal()}
              >
                <Mail_in />
              </p>
            </Tooltip>
            <Tooltip title="Send mail to HO" placement="bottom">
              <div className="view-all-staff">
                <p
                  className="pointer"
                  style={{ marginBottom: 0 }}
                  onClick={() => showModal()}
                >
                  <Mail_sent />
                </p>
              </div>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <>
      <SendEmailModal
        form={form}
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
      />
      <div className="lead-gen-list-container mt-1 ml-2">
        <Heading title="Quotation List" />
      </div>
      <div className="master-main-container lead-client-quote-list m-1">
        <div className="align-center space-between">
          {/* <div className="pl-1-5 fw-600"></div> */}
          <h2 className="p-1">{history?.state || "Client name"}</h2>
          <Row className="p-1">
            <Button
              className="save-btn"
              disabled={tableData.some(
                (val) => val.status.toLowerCase() == "approved"
              )}
              onClick={() => navigate("/new-quotation/" + param?.id)}
            >
              {" "}
              + New Quote
            </Button>
          </Row>
        </div>
        <Table
          className="table-head-sticky"
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
          columns={columns}
          // loading={loading}
          defaultCurrent={1}
          onChange={handlePageChange}
          dataSource={tableData}
          pagination={{
            // pageSize: pageSize,
            // total: inputSearchValue.length == 0 ? tableData.length : 10,
            itemRender: itemRender,
          }}
          rowClassName={(record) => !record?.status && "disabled-row"}
        />
      </div>
    </>
  );
};

export default ClientQuotationList;
