/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Radio,
  Upload,
  Modal,
  Checkbox,
  Button,
  message,
  Spin,
} from "antd";
// import ImgCrop from "antd-img-crop";
import API from "../../../api";
import urls from "../../../api/urls";
import { APIs, getToken } from "../../../utils/worker";
import { StarOutlined } from "@ant-design/icons";
import axios from "axios";
// import Heading from "../../../components/Heading";
import {
  firstLetterCapitalise,
  checkSpace,
  checkSpecialCharacter,
  checkNumbervalue,
  capitalize,
} from "../../../utils";
const { BARCODE_TYPE } = urls;

function AddProduct({ editDetails, onChange, addOrEdit }) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [restrictState, setRestrictState] = useState(false);
  const [categoryArr, setCategoryArr] = useState([]);
  const [filesData, setFileData] = useState([]);
  const [fileList, setfileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [defaultStates, setDefaultStates] = useState([]);
  const [checkStatus, setCheckStatus] = useState(true);
  const [hasSeriesStatus, setHasSeriesStatus] = useState(false);
  const [productName, setProductName] = useState("");
  const [barCodeTypeList, setBarCodeTypeList] = useState(null);

  const [validor, setValidor] = useState({
    validateStatus: "success",
    errorMsg: null,
  });

  useEffect(() => {
    if (categoryArr.length == 0) {
      categoryList();
    }
  }, []);

  useEffect(() => {
    if (editDetails !== null) {
      // setRestrictState(false);

      form.setFieldsValue({
        ...editDetails,
      });

      let temp = editDetails?.stateWisePriceDto;
      setSelectedStates(temp);
      let items = temp.map((e) => e.stateId);
      setDefaultStates(items);

      let imgList = editDetails?.imageDtos;
      let temp2 = [];
      if (imgList.length > 0) {
        imgList.map((e) => {
          let name = e?.imagePath?.toString("")?.split("/");
          temp2.push({
            name: name[name.length - 1],
            url: e.imagePath,
            id: e.id,
          });
        });
      }

      setfileList([...temp2]);
      setFileData([...temp2]);

      setRestrictState(editDetails?.restrictions);
      let label = form.getFieldsValue("eligibility");

      let eligibilityLabel =
        label?.eligibility == 1
          ? "Both"
          : label?.eligibility == 2
          ? "Hub"
          : "CP";
      let type = {
        label: eligibilityLabel,
      };
      getTableData("Edit", type);
      setHasSeriesStatus(editDetails?.hasSeries);
    } else {
      form.resetFields();
      getTableData();
      categoryList();
      form.setFieldsValue({
        hasSeries: false,
        restrictions: false,
      });
    }
  }, [editDetails]);

  const handleChange = (info) => {
    let allFiles = info?.fileList?.map((val) => val?.response?.data);
    let temp = [];
    info?.fileList?.map((item) => {
      if (item.response === undefined) {
        temp.push(item);
      } else {
        temp.push(item?.response?.data);
      }
    });
    if (info.file.status === "done") {
      message.success(info.file.response.message);
      setFileData(temp);
      // setfileList(allFiles);
    }
    if (info.file.status === "removed") {
      let filteredPeople = 0;
      if (info?.file?.id) {
        filteredPeople = filesData.filter((item) => item.id !== info?.file?.id);
      } else {
        filteredPeople = filesData.filter(
          (item) => item.id !== info?.file?.response?.data?.id
        );
      }

      setFileData(filteredPeople);

      localStorage.setItem("allFiles", JSON.stringify(allFiles));
    }
    if (info.file.status === "error") {
      message.error("File not Supported");
    }
    if (info.fileList.length === 0) {
      form.resetFields(["Productimages"]);
    }
    setfileList(info.fileList);
    // setFileData(info.fileList);
  };

  const handleOk = () => {
    let getPrice = form.getFieldsValue();

    let res = stateOptions.filter((i) => selectedIds.includes(i.stateId));
    if (res.length > 0) {
      let temp = [];
      res?.forEach((element) => {
        let ele = {
          ...element,
          price: getPrice?.price,
        };
        temp.push(ele);
      });
      setSelectedStates(temp);
    } else {
      form.resetFields(["allStates"]);
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    // let a = form.getFieldsValue();

    if (stateOptions.length === selectedStates.length) {
      form.setFieldValue("allStates", true);
    } else {
      form.setFieldValue("allStates", false);
    }
    // form.resetFields(["allStates"]);

    setIsModalOpen(false);
    setCheckStatus(true);
  };

  const getTableData = async (e, type) => {
    setLoading(true);
    let url = "/item/v1/all-states";
    let response = await API.Api(
      `${APIs.baseURL}` + url
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      setLoading(false);
      let temp = response?.response?.data;
      temp.map((item) => {
        item["price"] = 0;
      });
      setStateOptions(response?.response?.data);
      if (e == "add") {
        form.setFieldsValue({
          restrictions: false,
          allStates: null,
        });
        setRestrictState(false);
        setSelectedStates([]);
        setDefaultStates([]);
      }
    } else {
      if (!response.status) {
        setLoading(false);
      }
    }
  };

  const categoryList = async () => {
    setLoading(true);
    let response = await API.Api(
      `${APIs.baseURL}/categories`
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      setLoading(false);
      let temp = [];
      let length = response?.response?.data;
      for (let i = 0; i < length?.length; i++) {
        if (length[i]?.status === true) {
          temp.push(length[i]);
        }
      }
      setCategoryArr(temp);
    } else {
      if (!response.status) {
        setLoading(false);
      }
    }
  };

  const handleStatus = (value) => {
    let getPrice = form.getFieldsValue();
    if (value == false) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
      let temp = [];
      stateOptions?.forEach((element) => {
        let ele = {
          ...element,
          price: getPrice?.price,
        };
        temp.push(ele);
      });
      setSelectedStates(temp);
    }
  };

  const radioonChange = (e) => {
    setSelectedIds(e);
    if (e.length > 0) {
      setCheckStatus(false);
    } else {
      setCheckStatus(true);
    }
  };

  const onFinish = async (values) => {
    let stateWisePriceDto = selectedStates.map((e) => {
      return { stateName: e.stateName, price: +e.price, stateId: +e.stateId };
    });

    let condition =
      values?.restrictions == true && stateWisePriceDto?.length !== 0
        ? true
        : values?.restrictions == false
        ? true
        : false;
    if (condition) {
      let imageDtos = filesData?.map((e) => {
        return {
          id: e.id,
          imagePath: e.url || e.filePath,
        };
      });
      let {
        name,
        category,
        sku,
        hsn,
        minOrderQty,
        incrementBy,
        eligibility,
        description,
        hasSeries,
        restrictions,
        allStates,
        price,
        series,
      } = values;
      let response = addOrEdit
        ? await API.Api(
            `${APIs.baseURL}/item/v1/update-product/${editDetails?.id}`
          ).put({
            name,
            category,
            sku,
            hsn: +hsn,
            minOrderQty: +minOrderQty,
            incrementBy: +incrementBy,
            eligibility,
            description,
            hasSeries,
            price,
            restrictions: restrictions,
            stateWisePriceDto,
            imageDtos,
            allStates,
            series,
          })
        : await API.Api(`${APIs.baseURL}/item/v1/add-product`).postWithHeader({
            name,
            category,
            sku,
            hsn: +hsn,
            minOrderQty: +minOrderQty,
            incrementBy: +incrementBy,
            eligibility,
            description,
            hasSeries,
            price,
            restrictions: restrictions,
            stateWisePriceDto,
            imageDtos,
            allStates,
            series,
          });
      if (response.status) {
        form.resetFields();
        form.setFieldsValue({
          hasSeries: 1,
          restrictions: "false",
        });
        setFileData([]);
        setfileList([]);
        onChange("1");
      } else {
        setLoading(false);
        message.error(response?.response?.data?.message || "Network error");
      }
    } else {
      message.error("Enter the state price");
    }
  };

  const inputHandler = (e, i) => {
    setSelectedStates(
      selectedStates.map((item) => {
        return item.stateId === i.stateId
          ? { ...item, price: e.target.value }
          : item;
      })
    );
  };
  const { Option } = Select;
  let authToken = getToken();

  const props = {
    name: "file",
    multiple: true,
    listType: "picture-card",
    showUploadList: true,
    action: `${APIs.baseURL}/file-service/v1/profile/file`,
    headers: { Authorization: ` ${authToken}` },
    fileList: fileList,
    className: "upload-list-inline",

    onChange(info) {
      if (info.file.status === "done") {
        // Handle response from API
      }
    },

    removeIcon: <StarOutlined />,
  };

  const handlePreview = async (e) => {
    let dd = 0;
    if (e?.id !== undefined) {
      dd = e?.id;
    } else {
      dd = e?.response?.data?.id;
    }

    let findIdx = filesData.findIndex((e) => e?.id == dd);
    setPreviewImage(filesData[findIdx]?.filePath || filesData[findIdx]?.url);
    setPreviewTitle(
      filesData[findIdx]?.displayName || filesData[findIdx]?.name
    );

    setPreviewOpen(true);
  };
  const beforeUpload = (file, onSuccess) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/raw";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (file) {
      let checkSameFile = fileList
        ?.map((val) => val?.name)
        .some((val) => val === file.name);

      if (file.name.includes("jfif")) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      if (checkSameFile) {
        message.error("Same file cannot be uploaded again");
        return Upload.LIST_IGNORE;
      }
      if (!isLt2M) {
        message.error("File size should be less than 2MB");
        return Upload.LIST_IGNORE;
      }
      if (!isJpgOrPng) {
        message.error("File formats should be JPEG / JPG / PNG / RAW");
        return Upload.LIST_IGNORE;
      }
      return isJpgOrPng && isLt2M;
    } else {
      if (onSuccess) {
        message.success("File uploaded");
      }
      onSuccess("ok");
      return true;
    }
  };

  const user_premiseId = JSON.parse(window?.localStorage?.getItem("userId"));

  const handleRemove = (file) => {
    const { confirm } = Modal;
    let config = {
      headers: {
        Authorization: authToken,
      },
    };
    return new Promise((resolve, reject) => {
      confirm({
        title: "Are you sure you want to Delete ?",
        onOk: async () => {
          resolve(true);
          try {
            let res = await axios.delete(
              `${APIs.baseURL}/file-service/v1/file/${
                file?.response?.data?.id || file?.id
              }`,
              config
            );

            if (res) {
              message.success(res.data);
            }
          } catch (error) {
            // message.error(error.data + "world");
          }
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  };

  const changeChildPrice = (e) => {
    if (selectedStates.length > 0) {
      for (let i = 0; i < selectedStates.length; i++) {
        form.setFieldValue(selectedStates[i]?.stateName, e);
      }
    }
  };

  const restrictionsOnChange = (e) => {
    let eligibility = form.getFieldsValue();
    if (eligibility?.eligibility !== undefined) {
      setRestrictState(e);
    } else {
      message.error("Please select Eligibility");
    }
  };

  useEffect(() => {
    if (editDetails?.name !== productName && productName !== "") {
      const getData = setTimeout(async () => {
        let response = await API.Api(
          `${APIs.baseURL}/item/v1/item-name/${productName}`
        ).getWithUserNPremiseHeader();

        if (response?.response?.status === 400) {
          setValidor({
            validateStatus: "error",
            errorMsg: response?.response?.data?.message,
          });
        } else {
          setValidor({
            validateStatus: "success",
            errorMsg: null,
          });
        }
      }, 1000);

      return () => clearTimeout(getData);
    }
  }, [productName]);

  const getBarcodeType = async () => {
    let response = await API.Api(BARCODE_TYPE).getWithHeader();
    if (response?.status) {
      setBarCodeTypeList(response?.response?.data);
    } else {
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Modal
          title={
            <span style={{ fontWeight: "700", fontSize: "14px" }}>
              Select States to enabled the product
            </span>
          }
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={350}
          className="stationary-modal"
          footer={
            <>
              <div className="d-flex flex-end">
                <Button className="cancel-btn w-20" onClick={handleCancel}>
                  CANCEL
                </Button>
                <Button
                  // form={form}
                  disabled={checkStatus}
                  onClick={handleOk}
                  className="save-btn ml-1 w-20"
                >
                  OK
                </Button>
              </div>
            </>
          }
        >
          <div className="checkbox-row">
            <Checkbox.Group
              defaultValue={defaultStates}
              onChange={(e, l) => radioonChange(e, l)}
              className="checkbox-grp"
            >
              <Row>
                {stateOptions?.map((e) => (
                  <Col span={24} key={e?.stateId}>
                    <Checkbox value={e?.stateId}>{e?.stateName}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
        </Modal>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
        <div className="master-main-container">
          <Form
            className="global-form profile-container-form pl-4 pr-4 p-2"
            layout="vertical"
            form={form}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
            onFinish={onFinish}
            name="appPoduct"
          >
            <Row gutter={40} className="global-form-row">
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="category"
                  label={"Category"}
                  rules={[
                    {
                      required: true,
                      message: "Please select category",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select category"
                    onChange={(val, e) => {
                      if (e?.children === "Barcode") {
                        getBarcodeType();
                      } else {
                        setBarCodeTypeList(null);
                      }
                    }}
                  >
                    {categoryArr.map((option) => (
                      <Option key={option?.id} value={option?.id}>
                        {capitalize(option?.categoryName)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="name"
                  label="Product name"
                  rules={[
                    {
                      required: true,
                      message: "Enter product name",
                    },
                  ]}
                  validateStatus={validor?.validateStatus}
                  help={validor?.errorMsg}
                >
                  {barCodeTypeList ? (
                    <Select placeholder="Select product" disabled={editDetails}>
                      {barCodeTypeList.map((option) => (
                        <Option
                          key={option?.id}
                          value={option?.barcodeTypeName}
                          disabled={option?.assigned}
                        >
                          {capitalize(option?.barcodeTypeName)}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      placeholder="Enter product name"
                      maxLength={50}
                      // disabled={addOrEdit == true ? true : false}
                      onInput={(e) =>
                        (e.target.value = firstLetterCapitalise(e))
                      }
                      onChange={(e) => setProductName(e.target.value)}
                      disabled={editDetails}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="sku"
                  label="SKU"
                  rules={[
                    {
                      required: true,
                      message: "Enter SKU number",
                    },
                    {
                      min: 4,
                      message: "SKU atleast 4 characters",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (checkSpace(event)) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    placeholder="Enter here"
                    onInput={(e) =>
                      (e.target.value = e.target.value.toUpperCase())
                    }
                    maxLength={16}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="hsn"
                  label="HSN"
                  rules={[
                    {
                      required: true,
                      message: "Enter HSN number",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpecialCharacter(event) ||
                      checkSpace(event)
                    ) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input placeholder="Enter here" maxLength={6} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="minOrderQty"
                  label="Min order quantity"
                  rules={[
                    {
                      required: true,
                      message: "Enter min order",
                    },
                    {
                      pattern: new RegExp(/^[1-9]\d*$/),
                      message: "value should not be 0",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpecialCharacter(event) ||
                      checkSpace(event)
                    ) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input placeholder="0" maxLength={4} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="incrementBy"
                  label="Increment by"
                  rules={[
                    {
                      required: true,
                      message: "Enter Increment by",
                    },
                    {
                      pattern: new RegExp(/^[1-9]\d*$/),
                      message: "value should not be 0",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpecialCharacter(event) ||
                      checkSpace(event)
                    ) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input placeholder="0" maxLength={2} />
                </Form.Item>
              </Col>

              <Col lg={8}>
                <Form.Item
                  name="eligibility"
                  label={"Eligibility"}
                  rules={[
                    {
                      required: true,
                      message: "Please select value",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select value"
                    onSelect={(e, l) => getTableData("add", l)}
                    options={[
                      {
                        value: 1,
                        label: "Both",
                      },
                      {
                        value: 2,
                        label: "Hub",
                      },
                      {
                        value: 3,
                        label: "CP",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="price"
                  label="Default price"
                  rules={[
                    {
                      required: true,
                      message: "Enter price",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (checkNumbervalue(event) || checkSpace(event)) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    placeholder="0"
                    maxLength={8}
                    onChange={(e) => changeChildPrice(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item
                  name="description"
                  label={"Product description"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    showCount
                    maxLength={100}
                    placeholder="Write product description"
                  />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  name="hasSeries"
                  label={"Has series"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <Radio.Group
                    name="radiogroup"
                    onChange={(e) => setHasSeriesStatus(e.target.value)}
                    defaultValue={1}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {hasSeriesStatus && (
                <Col lg={8}>
                  <Form.Item
                    name="series"
                    label={"Series"}
                    rules={[
                      {
                        required: true,
                        maxLength: 3,

                        message: "Please enter description",
                      },
                    ]}
                    onKeyPress={(event) => {
                      if (
                        checkNumbervalue(event) ||
                        checkSpecialCharacter(event) ||
                        checkSpace(event)
                      ) {
                        event.preventDefault();
                      }
                    }}
                  >
                    <Input placeholder="Enter series" maxLength={14} />
                  </Form.Item>
                </Col>
              )}
              <Col lg={8}>
                <Form.Item
                  name="restrictions"
                  label={"Restrictions"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <Radio.Group
                    name="radiogroup"
                    defaultValue={"false"}
                    value={restrictState}
                    onChange={(e) =>
                      restrictionsOnChange(JSON.parse(e.target.value))
                    }
                  >
                    <Radio
                      value={true}
                      disabled={stateOptions.length > 0 ? false : true}
                    >
                      Enable
                    </Radio>
                    <Radio value={false}>Disable</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {restrictState && (
                <Col lg={8}>
                  <Form.Item
                    name="allStates"
                    label={"States"}
                    rules={[
                      {
                        required: true,
                        message: "Please select value",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select here"
                      onSelect={(value, event) => handleStatus(value, event)}
                      options={[
                        {
                          value: true,
                          label: "For All",
                        },
                        {
                          value: false,
                          label: "For Selected",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col lg={24}>
                <Form.Item
                  name="Productimages"
                  label={
                    <div>
                      Product images{" "}
                      <span
                        style={{
                          fontSize: "10px",

                          color: "#8B8B8B",
                        }}
                      >
                        (JPEG / JPG / PNG / RAW)
                      </span>
                    </div>
                  }
                  rules={[
                    {
                      // required: true,
                      required:
                        addOrEdit == false
                          ? true
                          : addOrEdit == true && fileList.length > 0
                          ? false
                          : true,
                      message: "Please add product image",
                    },
                  ]}
                >
                  <Upload
                    {...props}
                    fileList={fileList}
                    onChange={handleChange}
                    onRemove={handleRemove}
                    maxCount={10}
                    beforeUpload={beforeUpload}
                    onPreview={handlePreview}
                    multiple={false}
                    headers={{
                      "SMCS-PREMISE-ID": user_premiseId?.userInfo?.premiseId,
                      Authorization: authToken,
                    }}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            {selectedStates.length > 0 && restrictState && (
              <div className="price-states-container">
                <Row className="state-price-col">
                  <Col lg={12} className="pl-2">
                    State
                  </Col>
                  <Col lg={12} className="pl-2">
                    Price
                  </Col>
                </Row>
                <div style={{ maxHeight: "400px", overflow: "scroll" }}>
                  {selectedStates.map((i, index) => (
                    <Row
                      key={index}
                      className="price-states-form-row"
                      style={{
                        border: "1px solid #DDDDDD",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col lg={12} className="pl-2">
                        {i?.stateName}
                      </Col>
                      <Col lg={10} className="pl-2">
                        {" "}
                        <Form.Item
                          name={i?.stateName}
                          onKeyPress={(event) => {
                            if (checkNumbervalue(event) || checkSpace(event)) {
                              event.preventDefault();
                            }
                          }}
                          initialValue={i?.price}
                          rules={[
                            {
                              pattern: /^\d{1,10}(\.\d{0,2})?$/,
                              message: "Only two decimal values allowed",
                            },
                          ]}
                        >
                          <Input
                            placeholder="0"
                            maxLength={8}
                            onChange={(e) => inputHandler(e, i)}
                          />
                        </Form.Item>{" "}
                      </Col>{" "}
                    </Row>
                  ))}
                </div>
              </div>
            )}
          </Form>
          <div
            className="button-container1"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <div className="steps-action" style={{ display: "flex" }}>
              <Button className="cancel-btn mr-1" onClick={() => onChange("1")}>
                {"CANCEL"}
              </Button>
              <Button
                htmlType="submit"
                className="save-btn"
                form="appPoduct"
                key="submit"
              >
                {"SUBMIT"}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default AddProduct;
