/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable react/react-in-jsx-scope */
// /* eslint-disable no-unused-vars */
// import React from "react";
import Heading from "../../../components/Heading";
import {
  Row,
  Col,
  Table,
  Tag,
  Card,
  Typography,
  Spin,
  message,
  Tooltip,
} from "antd";
import { useLocation } from "react-router-dom";
import { ReactComponent as Driver } from "../../../assests/images/LCRIcons/Driver.svg";
import { ReactComponent as Calendar } from "../../../assests/images/LCRIcons/calendar_month.svg";
import { ReactComponent as Road } from "../../../assests/images/LCRIcons/road.svg";
import { ReactComponent as Truck } from "../../../assests/images/LCRIcons/Truck.svg";
import { ReactComponent as FrontLoader } from "../../../assests/images/LCRIcons/Frontloader.svg";
import { ReactComponent as Loaction } from "../../../assests/images/LCRIcons/location_on-1.svg";
import { ReactComponent as Bag } from "../../../assests/images/LCRIcons/money_bag.svg";
import { ReactComponent as Packet } from "../../../assests/images/LCRIcons/package_2.svg";
import { ReactComponent as Bagg } from "../../../assests/images/LCRIcons/Recyclebag.svg";
import { ReactComponent as Event } from "../../../assests/images/LCRIcons/event_available.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { ReloadOutlined } from "@ant-design/icons";

import nodeAPI from "../../../api/nodeUrls";
import React, { useState } from "react";
import "./style.scss";
import { Collapse } from "antd";
import moment from "moment";
import {
  camelCase,
  capitaliseWithHyphen,
  normalizeString,
} from "../../../utils";
import API from "../../../api";
import { useEffect } from "react";

function LCRReportNew() {
  const { Panel } = Collapse;
  const history = useLocation();
  const { LCRTableDetails, LCR_BAG_SHIPEMNT_DETAILS, NEW_LCR_REPORT } = nodeAPI;
  const [loading, setLoading] = useState(false);
  const [bagAndShipmentdata, setBagAndShipmentdata] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);
  const [panelContent, setPanelContent] = useState({});
  const [loadingPanels, setLoadingPanels] = useState({});
  const [report, setReport] = useState({});
  const { Title, Text } = Typography;

  const colorObj = {
    INITIATED: "#007BFF", // Bright Blue
    STARTED: "#17A2B8", // Teal
    "IN-TRANSIT": "#FFC107", // Amber/Yellow
    COMPLETED: "#28A745", // Green
  };
  console.log(history?.state);
  useEffect(() => {
    getLCRDetailData();
    getLCRTableData(history?.state?.vehicle_lcr_id);
  }, [history?.state]);

  const EllipsisMiddle = ({ suffixCount, children }) => {
    const { Text } = Typography;
    const start = children?.slice(0, children.length - suffixCount).trim();
    return (
      <Text
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Tooltip placement="topLeft" title={children}>
          {start}...
        </Tooltip>
      </Text>
    );
  };

  const arr = [
    {
      label: "Created at: ",
      value: moment.utc(report?.created_date).format("DD-MM-YYYY hh:mm A"),
      icon: <Calendar />,
    },
    { label: "Travel Mode:", value: report?.travel_by, icon: <Road /> },
    {
      label: "Vehicle/Flight No: ",
      value: report?.vehicle_num,
      icon: <Truck />,
    },
    {
      label: "Co-Loader: ",
      value: report?.coloader || "",
      icon: <FrontLoader />,
    },
    {
      label: "Driver Name: ",
      value: report?.driver_name,
      icon: <Driver />,
    },
    {
      label: "Route: ",
      value: (
        <EllipsisMiddle suffixCount={report?.route_name?.length - 25}>
          {report?.route_name}
        </EllipsisMiddle>
      ),
      icon: <Loaction />,
    },
    {
      label: "Current Hub: ",
      value: camelCase(report?.current_hub),
      icon: <Loaction />,
    },
    {
      label: "Destination Hub: ",
      value: camelCase(report?.destination_hub),
      icon: <Loaction />,
    },
    {
      label: "Bag Out | In: ",
      value: `${report?.bag_out_count} | ${report?.bag_in_count}`,
      icon: <Bag />,
      received: checkBagReceived(report, "bag_out_count", "bag_in_count"),
    },
    {
      label: "Shipment Out | In: ",
      value: ` ${report?.shipment_out_count} | ${report?.shipment_in_count}`,
      icon: <Packet />,
      received: checkBagReceived(
        report,
        "shipment_out_count",
        "shipment_in_count"
      ),
    },
    {
      label: "Completed On: ",
      value: moment
        .utc(report?.latest_operation_time)
        .format("DD-MM-YYYY hh:mm A"),
      icon: <Event />,
    },
  ];

  function checkBagReceived(report, bagOutKey, bagInKey) {
    let bagReceived = "";

    if (+report[bagOutKey] === +report[bagInKey]) {
      if (report[bagOutKey] == 0 && report[bagInKey] == 0) {
        bagReceived = "";
      } else {
        bagReceived = true;
      }
    } else {
      bagReceived = false;
    }

    return bagReceived;
  }

  const fetchPanelData = async (openedPanel, perentIndex) => {
    const filterData = bagAndShipmentdata[perentIndex].lcr_details.find(
      (item) => item.awb_number == openedPanel
    );
    setLoadingPanels((prev) => ({ ...prev, [openedPanel]: true }));
    const apiURL = filterData.bag
      ? `${filterData?.awb_number}&isBag=true`
      : filterData?.awb_number;
    const response = await API.Api(
      LCR_BAG_SHIPEMNT_DETAILS + apiURL
    ).NodeGetWithPremise();
    if (response.status) {
      return response.response.data;
    } else {
      setLoading(false);
      message.error(response.response.data.message || "Something went wrong");
    }
  };

  const columns = [
    {
      title: "Shipment No.",
      dataIndex: "barcode_no",
      key: "barcode_no",
      width: 150,
      render: (text, record) => (
        <span>{record?.barcode_no || record?.awb_number}</span>
      ),
    },
    {
      title: "Destination Hub Name",
      dataIndex: "destination_hub",
      key: "destination_hub",
      width: 180,
      render: (text) => <div>{capitaliseWithHyphen(text)}</div>,
    },
    {
      title: "Travel Status",
      dataIndex: "booking_status",
      key: "booking_status",
      width: 120,
      render: (text, record) => (
        <div>
          <Tag
            color={"#C5F3D9"}
            style={{
              marginLeft: 8,
              borderRadius: "10px",
              color: "#475467",
              width: "150px",
              textAlign: "center",
              whiteSpace: "normal",
            }}
          >
            {normalizeString(text)}
          </Tag>
          {capitaliseWithHyphen(record?.current_destination)}
        </div>
      ),
    },
    {
      title: "Date & Time",
      key: "latest_operation_time",
      dataIndex: "latest_operation_time",
      width: 150,
      render: (text) => (
        <div>{moment?.utc(text)?.format("DD-MM-YYYY hh:mm A")}</div>
      ),
    },
  ];

  const getLCRDetailData = async () => {
    setLoading(true);

    const response = await API.Api(
      LCRTableDetails + history?.state?.vehicle_lcr_id
    ).NodeGetWithPremise();
    if (response.status) {
      setBagAndShipmentdata(response?.response?.data);
      setLoading(false);
    } else {
      window.history.back();
      message.error(response?.response?.data?.message || "Network Error");
      setLoading(false);
    }
  };

  const onChange = async (keys, perentIndex) => {
    setActiveKeys(keys);

    const openedPanel = keys.find((key) => !activeKeys.includes(key));

    if (openedPanel && !panelContent[openedPanel]) {
      const data = await fetchPanelData(openedPanel, perentIndex);
      setPanelContent((prev) => ({ ...prev, [openedPanel]: data }));
      setLoadingPanels((prev) => ({ ...prev, [openedPanel]: false }));
    }
  };

  const isBagOrShipment = (isbag) => {
    return (
      <>
        {isbag.bag ? (
          <>
            <span>
              <Bagg /> Bag No.-
              <span className="vehicleContainerValue">{isbag?.awb_number}</span>
            </span>
            <span> {`(${isbag?.shipment_out_count} Shipments)`}</span>
          </>
        ) : (
          <span className="align-center">
            <Packet /> Ind. Shipment-
            <sapn className="vehicleContainerValue">{isbag.awb_number}</sapn>
          </span>
        )}
        {isbag?.premise_name &&
          activeKeys.includes(isbag?.awb_number.toString()) && (
            <Row className="pt-05">
              <Col lg={6}>
                <span className="vehicleContainerLabel"> Destination Hub:</span>
                <span className="vehicleContainerValue">
                  {normalizeString(isbag?.premise_name)}
                </span>
              </Col>
              <Col lg={6}>
                <span className="vehicleContainerLabel">Status:</span>
                <span className="vehicleContainerValue">
                  {
                    <Tag
                      color={"#C5F3D9"}
                      style={{
                        marginLeft: 8,
                        borderRadius: "10px",
                        color: "#475467",
                        width: "150px",
                        textAlign: "center",
                        whiteSpace: "normal",
                      }}
                    >
                      {normalizeString(isbag?.status)}
                    </Tag>
                  }
                </span>
              </Col>
              <Col lg={6}>
                <span className="vehicleContainerLabel">Date & Time: </span>
                <span className="vehicleContainerValue">
                  {moment
                    .utc(isbag?.latest_operation_time)
                    .format("DD-MM-YYYY hh:mm A")}
                </span>
              </Col>
              <Col lg={6}></Col>
            </Row>
          )}
      </>
    );
  };

  const getLCRTableData = async (searchValue) => {
    setLoading(true);
    const response = await API.Api(
      NEW_LCR_REPORT + "?search=" + searchValue
    ).NodeGetWithPremise();
    if (response.status) {
      setLoading(false);
      setReport({ ...response?.response?.data?.data[0], key: "key" });
    } else {
      message.error(response?.response?.data?.message, "Something went wrong");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="p-2-tab">
        {/* <Heading title={`LCR No. ${report?.lcr_num}`} /> */}
        <Spin spinning={loading}>
          <Heading title="LCR report" />
          <Card
            style={{ borderRadius: 10, marginBottom: 20, marginTop: 20 }}
            bodyStyle={{ padding: 20 }}
          >
            <div className="space-between-start">
              <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Title level={4} style={{ color: "#475467" }}>
                    Vehicle LCR NO.
                  </Title>
                  <Text strong>{report?.vehicle_lcr_id}</Text>
                  <Tag
                    color={colorObj[report?.status]}
                    style={{ marginLeft: 8, borderRadius: "10px" }}
                  >
                    {report?.status}
                  </Tag>
                </Col>
              </Row>
              <Tooltip title={"Reload"}>
                <ReloadOutlined
                  // onClick={getCurrentTime}
                  onClick={() => getLCRTableData(report?.vehicle_lcr_id)}
                  style={{
                    fontSize: 18,
                    paddingLeft: "10px",
                    color: "black",
                  }}
                />
              </Tooltip>
            </div>
            <Row className="pt-1" gutter={[16, 16]}>
              {arr.map((e, i) => (
                <LabelValue
                  item={e}
                  key={i}
                  index={i}
                  firstIndex={6}
                  allIndex={4}
                />
              ))}
            </Row>
          </Card>
        </Spin>
      </div>
      {bagAndShipmentdata?.map((payloadItem, perentIndex) => (
        <div className="container" key={perentIndex}>
          <div
            style={{
              backgroundColor: "#E5E5E5",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                padding: " 20px",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              <span className="vehicleContainerLabel"> STOP LCR: </span>
              <span className="vehicleContainerValue">
                {process.env.REACT_APP_ENV == "prod"
                  ? payloadItem?.sev_lcr_id
                  : payloadItem?.lcr_num}
              </span>
              &nbsp;|&nbsp;
              {/* <span className="vehicleContainerLabel">Destination Hub:</span> */}
              <span className="vehicleContainerValue">
                {camelCase(payloadItem?.from_hub)} to{" "}
                {camelCase(payloadItem?.to_hub)}
              </span>
              <span className="vehicleContainerLabel">
                ( {+payloadItem?.bag_count} Bags
                {+payloadItem?.shipment_count > 0 &&
                  ` & ${payloadItem?.shipment_count} Individual Shipment`}
                )
              </span>
            </div>
            <Collapse
              onChange={(e) => onChange(e, perentIndex)}
              activeKey={activeKeys}
            >
              {payloadItem.lcr_details.map((item) => (
                <Panel
                  header={<div>{isBagOrShipment(item)}</div>}
                  key={item?.awb_number?.toString()}
                >
                  <Table
                    dataSource={panelContent[item?.awb_number?.toString()]}
                    pagination={false}
                    columns={columns}
                    scroll={{ x: 1300 }}
                    loading={{
                      indicator: (
                        <img
                          src={Smcs_Loader}
                          style={{ height: "100px", width: "100px" }}
                        />
                      ),
                      spinning: loadingPanels[item?.awb_number?.toString()],
                    }}
                  />
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      ))}
    </>
  );
}

export default LCRReportNew;

function LabelValue({ item }) {
  return (
    <>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} className="align-center">
        {item.icon}
        <span className="vehicleContainerLabel"> {`${item?.label}`}</span>

        <span className="vehicleContainerValue truncated-text">
          {/* &nbps;{item?.value} */}
          &nbsp;&nbsp;{item?.value}&nbsp;
        </span>
        {item.received === false && (
          <Tag
            color={item.received ? "#C5F3D9" : "red"}
            style={{ marginLeft: 8, borderRadius: "10px", color: "#475467" }}
          >
            Pending
          </Tag>
        )}
        {item.received === true && (
          <Tag
            color={item.received ? "#C5F3D9" : "red"}
            style={{ marginLeft: 8, borderRadius: "10px", color: "#475467" }}
          >
            Received
          </Tag>
        )}
      </Col>
    </>
  );
}
