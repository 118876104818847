/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { LeadDetailsContainer } from "../../../../components/LeadDetailsContainer";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Select,
  DatePicker,
  Spin,
  Modal,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../../api";
import urls from "../../../../api/urls";
import Heading from "../../../../components/Heading";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";

const { GET_CLIENT_LEAD_ID } = urls;

function ViewApprovalDPBasicDetails() {
  const navigate = useNavigate();
  const params = useParams();

  const style = {
    viewDetailsContainer: {
      padding: "20px 15%",
      // background: "white",
    },
    viewDetailsHeader: {
      height: "35px",
      background: "#F5FAFF",
      border: "1px solid #BFDCFF",
      borderRadius: "5px",
      fontWeight: "700",
      fontSize: "16px",
      color: "#061624",
      paddingLeft: "17px",
    },
    leadDetailsGrid: {},
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    getClientApprovalDetails();
  }, []);

  const getClientApprovalDetails = async () => {
    setLoading(true);
    let response = await API.Api(GET_CLIENT_LEAD_ID + params?.id).get();
    if (response?.status) {
      setLoading(false);
      const res = response?.response?.data;
      const keysToRemove = [
        "centralized",
        "sez",
        "quoteStatus",
        "gstRegistred",
        "partyName",
        "premiseId",
        "state",
        "city",
        "addressLine1",
        "pincode",
        "addressLine2",
        "pan",
      ];

      const { organizationDetails } = res;
      organizationDetails.address = [
        organizationDetails.addressLine1,
        organizationDetails.state,
        organizationDetails.city,
        organizationDetails.pincode,
      ].join(" ,");

      const removeKeys = (obj) => {
        for (let key of keysToRemove) {
          delete obj[key];
        }
      };
      removeKeys(res?.businessDetails);
      removeKeys(res?.organizationDetails);
      setData(res);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  return (
    <div className="pl-2 pt-1">
      <Spin
        spinning={loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <div>
          <div className="ml-2">
            <Heading title={"Back"} />
          </div>
          <div className="container">
            <LeadDetailsContainer
              dataSources={data?.organizationDetails}
              numberOfColumns={3}
              style={style}
              headingText={"Basic Details"}
            />

            <div>
              <LeadDetailsContainer
                dataSources={data?.businessDetails}
                numberOfColumns={3}
                style={style}
                headingText={"Business Details"}
              />
            </div>
            <div>
              <Row justify={"end"} className="p-2">
                <Button
                  onClick={() => navigate("/all-approvals")}
                  className="cancel-btn ml-1"
                >
                  BACK
                </Button>
                <Button
                  htmlType="submit"
                  // onClick={() => handleButtonClick("saveNContinue")}
                  className="save-btn  ml-1"
                  // onClick={() =>
                  //   navigate("/view-pincode-mapping/" + params?.id)

                  // }
                  onClick={() =>
                    navigate(
                      `/view-pincode-mapping/${params?.id}/${params.status}`
                    )
                  }
                >
                  VIEW PINCODE MAPPING
                </Button>
              </Row>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default ViewApprovalDPBasicDetails;
