/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import MasterCPLists from "./MasterCPLists";
import MasterClientsLists from "./MasterClientLists";
import MasterUsersLists from "./MasterUsersLists";

// import BranchApprovalList from "./BranchApproval";
import { useLocation } from "react-router-dom";

// import ClientLeadList from "./CPLeadList";

const AllMasterLists = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [destroy, setDestroy] = useState(false);
  const [updateStaff, setUpdateStaff] = useState();

  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      setActiveKey(state.toString());
    }
  }, [state]);

  const changeTab = (key) => {
    setActiveKey(key);
    setDestroy(false);
  };

  function handleEdit(id) {
    setActiveKey("1");
    setUpdateStaff(id);
  }

  function handleSubmit() {
    setActiveKey("2");
    setDestroy(true);
  }

  return (
    <div className="p-2">
      {/* <Heading title="Staff Onboarding" /> */}
      <div className="global-card-tab-container">
        <Tabs
          activeKey={activeKey}
          destroyInactiveTabPane={destroy}
          onChange={changeTab}
          items={[
            {
              label: "CP list",
              key: "1",
              children: <MasterCPLists />,
            },

            {
              label: "Client/DP list",
              key: "2",
              children: <MasterClientsLists />,
            },
            {
              label: "Users list",
              key: "3",
              children: <MasterUsersLists />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AllMasterLists;
