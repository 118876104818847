import React, { useState, useEffect } from "react";
import { AutoComplete, Form } from "antd";

function AutoCompleteAddress({
  // addressDetailsCallback,
  form,
  fieldName,
  className = null,
  label,
  alertData,
  resetForm,
  returnAddress = false,
}) {
  const [options, setOptions] = useState([]);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [selectedArea, setSelectedArea] = useState({});

  useEffect(() => {
    if (alertData?.visible) {
      setOptions([]);
    }
  }, [alertData]);

  useEffect(() => {
    if (resetForm) {
      setOptions([]);
    }
  }, [resetForm]);

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      setIsApiLoaded(true);
    } else {
      const checkGoogleApi = setInterval(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
          setIsApiLoaded(true);
          clearInterval(checkGoogleApi);
        }
      }, 100);
    }
  }, []);

  const handleSearch = (value) => {
    if (!value || !isApiLoaded) {
      setOptions([]);
      return;
    }

    const allowedPincode = form.getFieldValue("deliveryPincode"); // Fetching pincode
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      { input: value, componentRestrictions: { country: "IN" } },
      (predictions, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          const placeService = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );

          // If pincode is not available, skip filtering by pincode
          if (!allowedPincode) {
            setOptions(
              predictions.map((item) => ({
                value: item.description,
                label: item.description,
                key: item.place_id,
              }))
            );
          } else {
            const filteredPredictions = [];
            const requests = predictions.map((prediction) => {
              return new Promise((resolve) => {
                placeService.getDetails(
                  {
                    placeId: prediction.place_id,
                    fields: ["address_components"],
                  },
                  (place, status) => {
                    if (
                      status ===
                        window.google.maps.places.PlacesServiceStatus.OK &&
                      place
                    ) {
                      const postalCodeComponent = place.address_components.find(
                        (component) =>
                          component.types.includes("postal_code") &&
                          component.long_name === allowedPincode
                      );

                      if (postalCodeComponent) {
                        filteredPredictions.push(prediction);
                      }
                    }
                    resolve();
                  }
                );
              });
            });

            // Wait for all place details requests to finish
            Promise.all(requests).then(() => {
              if (filteredPredictions.length > 0) {
                setOptions(
                  filteredPredictions.map((item) => ({
                    value: item.description,
                    label: item.description,
                    key: item.place_id,
                  }))
                );
              } else {
                setOptions([]);
              }
            });
          }
        } else {
          form.setFieldValue([fieldName], value);
          setOptions([]);
        }
      }
    );
  };

  const onSelect = (value, selectedObj) => {
    form.setFieldValue([fieldName], value);
    fetchPlaceDetails(selectedObj?.key);
    onEnter(null, "mobileNumber");
  };

  const fetchPlaceDetails = (placeId) => {
    const map = new window.google.maps.Map(document.createElement("div"));
    const service = new window.google.maps.places.PlacesService(map);

    service.getDetails(
      {
        placeId: placeId,
        fields: ["address_component", "geometry", "formatted_address", "name"],
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const details = place.address_components.reduce((acc, component) => {
            const typeToKey = {
              postal_code: "zip",
              subpremise: "house_flat_Num",
              administrative_area_level_3: "city",
              administrative_area_level_1: "state",
              country: "country",
              route: "route",
              landmark: "landmark",
            };
            component.types.forEach((type) => {
              if (typeToKey[type]) {
                acc[typeToKey[type]] = component.long_name;
              }
            });
            return acc;
          }, {});
          if (place.geometry && place.geometry.location) {
            details.latitude = place.geometry.location.lat();
            details.longitude = place.geometry.location.lng();
          }
          if (place.formatted_address) {
            details.completeAddress = place.formatted_address;
          }
          // addressDetailsCallback(details);
        }
      }
    );
  };

  const onChange = (value) => {
    form.setFieldValue([fieldName], value);
  };

  const handleChange = (newValue) => {
    setSelectedArea(newValue);
    form.setFieldValue([fieldName], newValue?.label);
  };

  const handleKeyDown = (event) => {
    const inputValue = form.getFieldValue(fieldName);
    const currentIndex = options.findIndex(
      (option) => option.label === inputValue
    );
    const lastIndex = options.length - 1;
    const keyActions = {
      ArrowUp: () =>
        handleChange(
          options[currentIndex === 0 ? lastIndex : currentIndex - 1]
        ),
      ArrowDown: () =>
        handleChange(
          options[currentIndex === lastIndex ? 0 : currentIndex + 1]
        ),
    };

    const action = keyActions[event.key];
    if (action) {
      action();
    }
  };

  const dropVisibelChange = () => {
    fetchPlaceDetails(selectedArea?.key);
  };

  function onEnter(event, name) {
    event.preventDefault();
    const inputFieldId = name;
    const targetElement = document.getElementById(inputFieldId);
    if (targetElement) {
      targetElement.focus();
      targetElement.select();
    }
  }
  // console.log(returnAddress, "returnAddress");

  return (
    <Form.Item
      label={label}
      name={fieldName}
      rules={[
        {
          required: true,
          message: "Enter address",
        },
      ]}
    >
      <AutoComplete
        showSearch
        className={className}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            if (returnAddress) {
              onEnter(event, "returnMobileNumber");
            } else {
              onEnter(event, "mobileNumber");
            }
          }
        }}
        options={options}
        onKeyDown={handleKeyDown}
        onSearch={handleSearch}
        onSelect={onSelect}
        onChange={onChange}
        onDropdownVisibleChange={dropVisibelChange}
        placeholder="Enter address"
      />
    </Form.Item>
  );
}

export default AutoCompleteAddress;
