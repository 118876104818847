/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Spin,
  Upload,
  Modal,
} from "antd";
import { useDispatch } from "react-redux";
import {
  capitalize,
  capitalise,
  getUserAndPremiseId,
  capitaliseWithHyphen,
} from "../../../utils";
import { shallowEqual, useSelector } from "react-redux";

import axios from "axios";
import { APIs, getPremiseId, getToken } from "../../../utils/worker";
import moment from "moment";
import {
  getStaffOnboard,
  getStaffOnboardClear,
} from "../../../actionsMethods/onboardingMethods";
import API from "../../../api";
import urls from "../../../api/urls";

import Smcs_Loader from "../../../assests/images/loader-new.gif";
import StaffForm from "./StaffForm";

const {
  UPDATE_STAFF,
  VERIFY_EMAIL,
  GET_BANK_DETAILS_BY_IFSC,
  VERIFY_PHONE_NUM,
  SEND_SMS_PHONE,
  VERIFY_PHONE_OTP,
  GET_STAFF_ROLES,
} = urls;

export const CreateStaff = ({ handleSubmit = () => {}, updateStaff }) => {
  const dispatch = useDispatch();

  const getFormData = useSelector(
    (state) => state?.onboardingReducer?.getStaffOnboardReducer,
    shallowEqual
  );

  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const premiseId = getPremiseId();
  const getUserInfo = getUserAndPremiseId();
  const userId = getUserInfo?.userId;
  const token = getToken();

  const [visible, setVisible] = useState(false);

  const [roleOptions, setRoleOptions] = useState();
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [driverSelected, setdriverSelected] = useState(false);
  const [showVerify, setShowVerify] = useState();
  const [showBank, setshowBank] = useState();
  const [phnOtpVerified, setPhnOtpVerified] = useState(false);
  const [selectedId, setselectedId] = useState();
  const [loading, setloading] = useState(false);
  const [roleSelected, setRoleSelected] = useState();
  const [bankDetails, setBankDetails] = useState();
  const [fileList, setfileList] = useState([]);
  const { result: formResult } = getFormData;
  const [editFile, setEditFile] = useState(null);

  const [idTypes, setIdTypes] = useState([
    {
      label: "Aadhar card",
      value: "aadhar",
    },
    {
      label: "Voter Id",
      value: "VOTER",
    },
    {
      label: "PAN Card",
      value: "pan",
    },
    {
      label: "Driving Licence",
      value: "DL",
    },
  ]);
  const dlClass = [
    "MC 50CC",
    "MCWOG/FVG",
    "MC EX50CC",
    "MGV",
    "HMV",
    "HGMV",
    "HPMV",
    "LMV",
    "LMV-NT",
    "HTV",
    "Trailer",
  ].map((value) => ({ label: value, value }));
  const idTypesMain = [
    {
      label: "Aadhar card",
      value: "aadhar",
    },
    {
      label: "Voter Id",
      value: "VOTER",
    },
    {
      label: "PAN Card",
      value: "pan",
    },
    {
      label: "Driving License",
      value: "DL",
    },
  ];

  useEffect(() => {
    if (updateStaff) {
      dispatch(getStaffOnboard(updateStaff));
    } else {
      dispatch(getStaffOnboardClear());
    }
  }, [updateStaff]);

  useEffect(() => {
    getALLRoles();
  }, []);

  useEffect(() => {
    modalForm.resetFields();
    if (visible === true) {
      setPhnOtpVerified(false);
    }
  }, [visible]);

  useEffect(() => {
    if (formResult) {
      form.setFieldsValue(formResult);
      setEditFile(formResult?.idUrl);

      form.setFieldsValue("bankDetail", false);
      if (
        formResult?.label == "DRIVER" ||
        formResult?.label == "DELIVERYBOY" ||
        formResult?.label == "FIELD EXECUTIVE"
      ) {
        setdriverSelected(true);
      }
      setselectedId(formResult?.idType);
      setshowBank(formResult?.bankDetail || false);
      form.setFieldValue(
        "dateOfBirth",
        formResult?.dateOfBirth
          ? moment(formResult?.dateOfBirth, "DD/MM/YYYY")
          : null
      );
    } else {
      form.resetFields();
    }
  }, [formResult]);

  const getALLRoles = async () => {
    setloading(true);
    let response = await API.Api(GET_STAFF_ROLES).getWithUserNPremiseHeader();
    if (response?.status) {
      setloading(false);
      let temp = response?.response?.data?.map((val) => {
        return { label: capitalize(val?.label), value: val?.role };
      });
      setRoleOptions(temp);
    } else {
      setloading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const verifyEmailExists = async (values) => {
    let response = await API.Api(VERIFY_EMAIL).post({
      emailId: values,
    });
    if (response?.status) {
      setloading(false);
      if (response?.response?.data === false) {
        message.error("E-mail already exists");
      } else {
        setShowVerifyEmail(true);
      }
    } else {
      setloading(false);
      message.error("Network error");
    }
  };

  const checkPhnNumExists = async (values) => {
    setloading(true);

    let response = await API.Api(VERIFY_PHONE_NUM).post({
      contactNumber: values,
    });
    if (response?.status) {
      setloading(false);
      if (response?.response?.data === false) {
        message.error("Phone number already exists");
      } else {
        // sentPhoneOtp(values);
        setShowVerify(true);
        setloading(false);
      }
    } else {
      message.error("Network error");
      setloading(false);
    }
  };
  const sentPhoneOtp = async () => {
    setloading(true);

    let phoneNum = form.getFieldValue("contactNumber");

    let response = await API.Api(SEND_SMS_PHONE).post({
      mobileNo: phoneNum,
    });

    if (response?.response?.data === true) {
      setloading(false);
      message.info("OTP has sent the given number");
    } else {
      setloading(false);
      message.error("Failed to send the OTP");
    }
  };

  const createPersonalDetails = async (values) => {
    setloading(true);
    let convertToDate = (date) => {
      let today = new Date(date);
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();
      today = `${dd}/${mm}/${yyyy}`;
      return today;
    };

    let mainPayload = {
      ...values,
      dlClass: values?.dlClass?.toString() || null,
      dateOfBirth: convertToDate(values?.dateOfBirth?._d),
    };

    if (formResult === null) {
      try {
        let response = await axios.post(
          APIs.baseURL + "/staff-service/v1/staff",
          mainPayload,
          {
            headers: headers,
          }
        );
        if (response?.status === 200) {
          setloading(false);
          localStorage.setItem("staff", response?.data?.data?.staffId);
          message.success("Staff onboarded successfully");
          handleSubmit();
        }
      } catch (error) {
        setloading(false);
        message.error(error?.response?.data?.message);
      }
    } else {
      let patchPayload = {
        ...values,
        dlClass: values?.dlClass?.toString() || null,
        staffId: updateStaff,
        dateOfBirth: convertToDate(values?.dateOfBirth?._d),
      };
      updateStaffOnboard(patchPayload);
    }
  };

  const updateStaffOnboard = async (values) => {
    setloading(true);

    let response = await API.Api(UPDATE_STAFF).patchIdHeader(values);
    if (response?.status) {
      setloading(true);
      message.success(response?.response?.data?.message);
      handleSubmit();
    } else {
      setloading(false);
      message.error(response?.response?.data?.message || "Failed to update");
    }
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const verifyPhoneOtp = async (values) => {
    setloading(true);

    let phoneNum = form.getFieldValue("contactNumber");
    let response = await API.Api(VERIFY_PHONE_OTP).post({
      mobileNo: phoneNum,
      otp: values.otp,
    });

    if (response?.response?.data === true) {
      setloading(false);

      message.success("Phone number verified successfully");
      setPhnOtpVerified(true);
      setVisible(false);
      setShowVerify(false);
    } else {
      setloading(false);
      message.error("Wrong OTP");
    }
  };

  function handleSelectedId(params) {
    setselectedId(params);
    form.setFieldValue("idNumber", null);
  }

  const fileProps = {
    name: "files",
    multiple: true,
    listType: "picture",
    showUploadList: true,
    action: `${
      APIs.baseURL
    }/staff-service/v1/staff/${selectedId?.toUpperCase()}`,
    className: "upload-list-inline",

    fileList:
      editFile !== null
        ? [
            {
              name: editFile && editFile?.split("/")?.pop(),
              url: editFile,
            },
          ]
        : fileList,
  };

  const handleChange = (info) => {
    if (info?.file?.status === "done") {
      form.setFieldValue("idUrl", info?.file?.response);
      message.success(
        info?.file?.response?.message || "Document uploaded successully"
      );
    }
    if (info?.file?.status === "error") {
      message.error("File not Supported");
    }
    setfileList(info?.fileList);
  };

  function handleRemoveFile() {
    message.success("File removed successfully");
    setfileList([]);
    setEditFile(null);
  }

  const headers = {
    Authorization: token,
    "SMCS-PREMISE-ID": premiseId?.split("Bearer")[1],
    "USER-ID": userId,
  };

  function handleRadioChange(e) {
    setshowBank(true);
    if (e.target.value === false) {
      setshowBank(false);
      form.setFieldValue("bankDetail", false);
      form.setFieldsValue({
        accountHolderName: "",
        accountNumber: "",
        ifscCode: "",
        bankName: "",
        branchName: "",
      });
    } else {
      form.setFieldValue("bankDetail", true);
    }
  }

  const checkIFSC = async (values) => {
    let response = await API.Api(
      GET_BANK_DETAILS_BY_IFSC + `?ifscCode=${values}`
    ).get();
    if (response?.status) {
      setBankDetails(response?.response?.data);
    } else {
      form.setFieldsValue({
        bankName: "",
        branchName: "",
      });
    }
  };

  useEffect(() => {
    if (bankDetails?.IFSC) {
      form.setFieldsValue({
        bankName: bankDetails?.BANK,
        branchName: capitaliseWithHyphen(bankDetails?.BRANCH),
      });
    }
  }, [bankDetails]);

  function handleRoleChange(e) {
    setRoleSelected(e);
    form.setFieldsValue({
      idType: null,
      idNumber: null,
    });

    if (e.includes("DRIVER") || e.includes("CPFIELDEXECUTIVE")) {
      setdriverSelected(true);
      let filterId = idTypes?.filter((val) => val.value === "DL");
      setIdTypes([...filterId]);
    } else {
      setdriverSelected(false);
      setIdTypes([...idTypesMain]);
    }
  }

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handlePreview = (file) => {
    if (file?.type == "application/pdf" || file?.name?.split(".")[1] == "pdf") {
      window.open(file?.url || file?.response);
    } else {
      setPreviewImage(file?.thumbUrl || file?.url);
      setPreviewOpen(true);
      setPreviewTitle(
        file?.name || file?.url?.substring(file?.url?.lastIndexOf("/") + 1)
      );
    }
  };
  const handleCancel = () => setPreviewOpen(false);

  const beforeUpload = (file, onSuccess) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (file) {
      let checkSameFile = fileList
        ?.map((val) => val?.name)
        .some((val) => val === file?.name);

      if (file?.name?.includes("jfif")) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      if (checkSameFile) {
        message.error("Same file cannot be uploaded again");
        return Upload.LIST_IGNORE;
      }
      if (!isLt2M) {
        message.error("File size should be less than 10MB");
        return Upload.LIST_IGNORE;
      }
      if (!isJpgOrPng) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      return isJpgOrPng && isLt2M;
    } else {
      if (onSuccess) {
        message.success("File uploaded");
      }
      onSuccess("ok");
      return true;
    }
  };

  const date = new Date();
  const sub = moment(date).subtract(18, "years");
  const minDate = sub;
  const disableDaysBeforeToday = useCallback(
    (d) => {
      if (d == null) {
        return null;
      }
      return minDate != null && d.isAfter(minDate) && !d.isSame(minDate, "day");
    },
    [minDate]
  );

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="master-main-container">
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
        <Modal
          title={null}
          open={visible}
          maskClosable={false}
          onCancel={handleCloseModal}
          width={400}
          loading={false}
          className="staff-onboard-modal"
          footer={
            <>
              <div className="d-flex flex-end">
                <Button
                  htmlType="submit"
                  onClick={modalForm.submit}
                  className="save-btn ml-1"
                >
                  SUBMIT
                </Button>
              </div>
            </>
          }
        >
          <div>
            <Form
              form={modalForm}
              onFinish={verifyPhoneOtp}
              layout={"vertical"}
              className="global-form"
            >
              <Row
                justify=""
                span={24}
                className="global-form-row mt-2"
                gutter={20}
                style={{ padding: "0 30px" }}
              >
                <Col span={24}>
                  <Form.Item
                    name="otp"
                    label="OTP"
                    rules={[
                      {
                        message: "OTP is required",
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter OTP"
                      onChange={"handlePincodeInput"}
                      maxLength={6}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>

        <StaffForm
          form={form}
          createPersonalDetails={createPersonalDetails}
          disableDaysBeforeToday={disableDaysBeforeToday}
          minDate={minDate}
          phnOtpVerified={phnOtpVerified}
          formResult={formResult}
          checkPhnNumExists={checkPhnNumExists}
          setPhnOtpVerified={setPhnOtpVerified}
          setShowVerify={setShowVerify}
          setVisible={setVisible}
          sentPhoneOtp={sentPhoneOtp}
          showVerify={showVerify}
          verifyEmailExists={verifyEmailExists}
          handleRoleChange={handleRoleChange}
          roleOptions={roleOptions}
          idTypes={idTypes}
          handleSelectedId={handleSelectedId}
          roleSelected={roleSelected}
          driverSelected={driverSelected}
          dlClass={dlClass}
          fileProps={fileProps}
          handleChange={handleChange}
          handleRemoveFile={handleRemoveFile}
          handlePreview={handlePreview}
          beforeUpload={beforeUpload}
          headers={headers}
          handleRadioChange={handleRadioChange}
          showBank={showBank}
          capitalise={capitalise}
          checkIFSC={checkIFSC}
          selectedId={selectedId}
        />
      </div>
    </Spin>
  );
};

export default CreateStaff;
