/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Button,
  Space,
  Table,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Switch,
  message,
  Spin,
  Tooltip,
} from "antd";
import "./style.scss";
import API from "../../../api";
import { APIs } from "../../../utils/worker";
import Heading from "../../../components/Heading";
import {
  firstLetterCapitalise,
  firstLetterCapsInSentence,
} from "../../../utils";

import { ReactComponent as Edit_icon } from "../../../assests/images/edit-logo.svg";
import { ReactComponent as Download_pin_icon } from "../../../assests/images/download.svg";
import { ReactComponent as Disable_Edit_icon } from "../../../assests/images/SVG/disableEdit.svg";
function AddCategory() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTabelData] = useState();
  const [updateOrAddCategory, setUpdateOrAddCategory] = useState(false);
  const [addOrEdit, setAddOrEdit] = useState(true);
  const [catagoryId, setCatagoryId] = useState("");
  // const [statuCheck, setStatuCheck] = useState(false);
  const [categorySatus, setCategorySatus] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [validator, setValidator] = useState({});

  useEffect(() => {
    getTableData();
  }, [updateOrAddCategory]);

  const onFinish = async (values) => {
    setLoading(true);
    let response = addOrEdit
      ? await API.Api(`${APIs.baseURL}/categories`).postWithHeader({
          ...values,
          status: categorySatus,
        })
      : await API.Api(`${APIs.baseURL}/categories/v1/${catagoryId}`).put({
          ...values,
          status: categorySatus,
        });

    if (response.status) {
      // getTableData();
      handleCancel();
      setUpdateOrAddCategory(!updateOrAddCategory);
      setLoading(false);
      form.resetFields();
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  // let authToken = getToken();

  const onChange = (val, checked) => {
    const { confirm } = Modal;
    // let config = {
    //   headers: {
    //     Authorization: authToken,
    //   },
    // };
    return new Promise((resolve, reject) => {
      confirm({
        title: "Are you sure you want disable the category ?",
        onOk: async () => {
          resolve(true);
          try {
            let res = await API.Api(
              `${APIs.baseURL}/categories/v1/${checked?.id}`
            ).put({
              ...checked,
              status: !val,
            });

            if (res) {
              setUpdateOrAddCategory(!updateOrAddCategory);

              message.success("Status updated successfully");
            }
          } catch (error) {
            // message.error(error.data + "world");
          }
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  };

  const handleRemove = async (val, checked) => {
    let response = await API.Api(
      `${APIs.baseURL}/categories/v1/${checked?.id}`
    ).put({
      ...checked,
      status: !val,
    });

    if (response.status) {
      // getTableData();
      setUpdateOrAddCategory(!updateOrAddCategory);
      setLoading(false);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const [initalData, setInitalData] = useState({});

  const handleEdit = (values) => {
    showModal(false);
    setCatagoryId(values?.id);
    setInitalData(values);
    // setStatuCheck(values?.status);
    form.setFieldsValue({
      ...values,
    });
    setCategorySatus(values?.status);
  };

  // const words = sentence.split(" ");
  // const capitalizedWords = words.map((word) => capitalizeFirstLetter(word));
  // const capitalizedSentence = capitalizedWords.join(" ");

  const columns = [
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (text) => <text>{firstLetterCapsInSentence(text)}</text>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <text>{firstLetterCapsInSentence(text)}</text>,
    },
    {
      title: "Published",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (val) => {
        const date = new Date(val);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        const formattedDate = date.toLocaleDateString("en-US", options);
        return (
          <>
            <span>{formattedDate}</span>
          </>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (val, record) => {
        return (
          <>
            <Switch
              checked={val}
              onChange={() =>
                val === true ? onChange(val, record) : handleRemove(val, record)
              }
            />
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return record?.status ? (
          <Space size="middle">
            <Tooltip title={"Edit"} placement="bottom">
              <p
                className="pointer"
                style={{ marginBottom: 0 }}
                onClick={() => handleEdit(record)}
              >
                <Edit_icon />
              </p>
            </Tooltip>
          </Space>
        ) : (
          <Space size="middle">
            {/* <Tooltip title={"Edit"} placement="bottom"> */}
            <p style={{ marginBottom: 0 }}>
              <Disable_Edit_icon />
            </p>
            {/* </Tooltip> */}
          </Space>
        );
      },
    },
  ];

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(
      `${APIs.baseURL}/categories`
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      setLoading(false);
      setTabelData(response?.response?.data);
    } else {
      if (!response.status) {
        setLoading(false);
      }
    }
  };
  const showModal = (key) => {
    setIsModalOpen(true);
    setAddOrEdit(key);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setValidator({});
    setCategoryName("");
  };
  const { TextArea } = Input;

  const [form] = Form.useForm();

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };
  const dowonloadList = async () => {
    window.open(`${APIs.baseURL}/categories/v1/catagory-download`);
  };

  useEffect(() => {
    if (initalData?.categoryName !== categoryName && categoryName !== "") {
      const getData = setTimeout(async () => {
        let response = await API.Api(
          `${APIs.baseURL}/categories/v1/category-name/${categoryName}`
        ).getWithUserNPremiseHeader();

        if (response?.response?.status === 400) {
          setValidator({
            validateStatus: "error",
            errorMsg: response?.response?.data?.message,
          });
        } else {
          setValidator({
            validateStatus: "success",
            errorMsg: null,
          });
        }
      }, 1000);

      return () => clearTimeout(getData);
    }
  }, [categoryName]);
  return (
    <div className="category-container p-2">
      <Spin spinning={loading}>
        <Heading title="View Category" />
        <Modal
          title={addOrEdit ? "Add Category" : "Edit Category"}
          open={isModalOpen}
          // onOk={handleOk}
          onOk={handleOk}
          footer={
            <>
              <div className="d-flex flex-end">
                <Button className="cancel-btn w-20" onClick={handleCancel}>
                  CANCEL
                </Button>
                <Button
                  htmlType="submit"
                  // form={form}
                  onClick={form.submit}
                  className="save-btn ml-1 w-20"
                >
                  SAVE
                </Button>
              </div>
            </>
          }
          onCancel={handleCancel}
        >
          <Form
            name="basic"
            //   labelCol={{
            //     span: 8,
            //   }}
            //   wrapperCol={{
            //     span: 16,
            //   }}

            className="global-form  category-container-form"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              status: true,
            }}
            //   onFinishFailed={onFinishFailed}
          >
            <Row
              // gutter={40}
              className="global-form-row basic-details-conten"
              justify={"center"}
            >
              <Col lg={20}>
                <Form.Item
                  label="Category name"
                  name="categoryName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter category name",
                    },
                  ]}
                  validateStatus={validator?.validateStatus}
                  help={validator?.errorMsg}
                >
                  <Input
                    placeholder="Enter here"
                    onInput={(e) => (e.target.value = firstLetterCapitalise(e))}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col lg={20}>
                <Form.Item
                  label="Category description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Write category description"
                    maxLength={80}
                    onInput={(e) => (e.target.value = firstLetterCapitalise(e))}
                  />
                </Form.Item>
              </Col>
              <Col lg={20} className="category-container-publish">
                <Form.Item
                  label="Published"
                  name="status"
                  valuePropName={categorySatus ? "checked" : "unChecked"}
                >
                  <Switch
                    onChange={(e) => setCategorySatus(e)}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                  />
                </Form.Item>
              </Col>

              {/* <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item> */}
            </Row>
          </Form>
        </Modal>
        <div className="category-table-container">
          <Button
            className="btn-add-category"
            style={{ float: "right" }}
            onClick={() => showModal(true)}
          >
            + Add Category
          </Button>
          {/* <Button
            className="btn-add-category "
            style={{ float: "right", marginRight: "10px" }}
            // onClick={() => showModal(true)}
            onClick={dowonloadList}
          >
            <Download_pin_icon style={{ marginRight: "5px" }} /> Download
          </Button> */}

          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{
              itemRender: itemRender,
            }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default AddCategory;
