/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import "./style.scss";
import API from "../../api";
import urls from "../../api/urls";
// import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router";

const { SEND_QUOTE_MAIL } = urls;

const SendEmailModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  showModal,
  form,
}) => {
  const { TextArea } = Input;

  const [loading, setLoading] = useState(false);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const params = useParams();

  const submitHandle = async (values) => {
    setLoading(true);
    values.cc = "abhishek@hutechsolutions.com";
    let response = await API.Api(
      SEND_QUOTE_MAIL + params?.id
    ).postWithPrmiseHeader(values);
    if (response?.status) {
      setLoading(false);

      message.success(
        response?.response?.data?.message || "Mail sent successfully"
      );
      handleOk();

      // getStaffTable();
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  return (
    <div className="p-1 send-mail-container">
      <Modal
        title="Send Email "
        open={isModalOpen}
        onOk={submitHandle}
        footer={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout={"vertical"}
          className="global-form"
          onFinish={submitHandle}
          // initialValues={{
          //   subject: "sddssdcdc",
          //   toEmail: "abhishek@hutechsolutions.com",
          //   message: "ssvsdvs",
          // }}
        >
          <Row
            gutter={12}
            style={{ width: "100%" }}
            justify={"center"}
            className="global-form-row"
          >
            <Col span={24}>
              <Form.Item name="toEmail">
                <Input
                  suffix={"Cc"}
                  className="send-mail-input p-05"
                  placeholder="E-mail Id"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="subject">
                <Input className="send-mail-input p-05" placeholder="Subject" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="message">
                <TextArea
                  rows={6}
                  placeholder="maxLength is 6"
                  style={{ border: "none" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12} justify={"end"} className="mt-1">
            <Col>
              <Button className="save-btn" htmlType="submit">
                SEND
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default SendEmailModal;
