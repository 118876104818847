/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Collapse, message } from "antd";
import RateMatrixTable from "./RateMatrixTable";
import API from "../../../api";
import urls from "../../../api/urls";
const { GET_DEFAULT_RATE_CARD } = urls;
const RateMatrix = ({ quotationRateMatrix }) => {
  const { Panel } = Collapse;
  const [standardDox, setStandardDox] = useState([]);
  const [standardNonDox, setStandardNonDox] = useState([]);
  const [fastTrackSurface, setFastTrackSurface] = useState([]);
  const [fastTrackAir, setFastTrackAir] = useState([]);
  const [fastTrackNonDox, setFastTrackNonDox] = useState([]);
  const [valuePlusDox, setValuePlusDox] = useState([]);
  const [valuePlusNonDox, setValuePlusNonDox] = useState([]);

  useEffect(() => {
    getDefaultRate();
  }, [quotationRateMatrix]);

  const transformArray = (array) =>
    array?.map((obj) => ({
      title: `Up to ${obj.maxValue || obj.volume} Gms`,
      design: obj.volume,
      minValue: obj.minValue,
      value: [
        { title: "LOCAL", value: (obj.local || 0).toFixed(2) },
        { title: "WITHIN STATE", value: (obj.withinZone || 0).toFixed(2) },
        { title: "My Zone 1", value: (obj.myZone1 || 0).toFixed(2) },
        { title: "My Zone 2", value: (obj.myZone2 || 0).toFixed(2) },
        { title: "My Zone 3", value: (obj.myZone3 || 0).toFixed(2) },
        { title: "My Zone 4", value: (obj.myZone4 || 0).toFixed(2) },
        { title: "METRO", value: (obj.metro || 0).toFixed(2) },
        {
          title: "SPECIAL LOCATION",
          value: (obj.specialLocation || 0).toFixed(2),
        },
        { title: "REST OF INDIA", value: (obj.roi || 0).toFixed(2) },
      ],
    }));

  const getDefaultRate = async () => {
    const data = quotationRateMatrix;
    setStandardDox(transformArray(data?.dox?.surface?.standard));
    setStandardNonDox(transformArray(data?.nonDox?.surface?.standard));
    setFastTrackAir(transformArray(data?.dox?.air?.fastTrack));
    setFastTrackSurface(transformArray(data?.dox?.surface?.fastTrack));
    setFastTrackNonDox(transformArray(data?.nonDox?.surface?.fastTrack));
    setValuePlusDox(transformArray(data?.dox?.surface?.valuePlus));
    setValuePlusNonDox(transformArray(data?.nonDox?.surface?.valuePlus));
  };

  const setWeightSlabData = (data, title) => {
    switch (title) {
      case "standardDocs":
        setStandardDox(data);
        break;
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackSurface":
        setFastTrackSurface(data);
        break;
      case "fastTrackAir":
        setFastTrackAir(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusDox":
        setValuePlusDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  const changeDynamicSelect = (title, data) => {
    switch (title) {
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  return (
    <div>
      <Collapse collapsible={"header"} defaultActiveKey={["2"]}>
        <Panel header="Rate Matrix" key="2">
          <RateMatrixTable
            standardDocsData={standardDox}
            standardNonDocsData={standardNonDox}
            fastTrackSurfaceData={fastTrackSurface}
            fastTrackAirData={fastTrackAir}
            fastTrackNonDoxData={fastTrackNonDox}
            valuePlusDoxData={valuePlusDox}
            valuePlusNonDoxData={valuePlusNonDox}
            handleChangeDocs={(e, data) => setWeightSlabData(e, data)}
            handleDynamicSelect={(data, title) =>
              changeDynamicSelect(data, title)
            }
            templateType={"view"}
          />
        </Panel>
      </Collapse>
    </div>
  );
};
export default RateMatrix;
