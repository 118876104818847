/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Input, Spin, Table, Col, Tooltip } from "antd";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getManifestTableData } from "../../../../actionsMethods/manifestMethods";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";

import TableContainer from "../../../../components/TableContainer";
import print_icon from "../../../../assests/images/printIcon.svg";
import search_icon from "../../../../assests/images/search-icon.svg";
import "./style.scss";
import { serialNum } from "../../../../utils";

const ViewManifest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [total, setTotal] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(0);

  const [inputSearchValue, setInputSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState(data);

  const { result, fetching } = useSelector(
    (state) => state.manifestReducer.getManifestReducer,
    shallowEqual
  );

  function MyComponent(dateToFormat) {
    const toUpperCaseFilter = (d) => {
      return d.slice(0, 10);
    };
    return (
      <Moment filter={toUpperCaseFilter} format="DD/MM/YYYY">
        {dateToFormat}
      </Moment>
    );
  }

  const navigatePage = (record) => {
    localStorage.setItem(
      "manifestedId",
      JSON.stringify({ manifestId: record?.manifestId })
    );
    localStorage.setItem("manifestFor", record?.travelBy);
    if (
      record?.status === "LOCKED" ||
      record?.status === "HUB-IN-SCANNED" ||
      record?.status === "OUT-SCANNED"
    ) {
      navigate(`/print/${record?.manifestId}`);
    } else if (record?.status === "UNLOCKED") {
      navigate("/truck");
    } else {
      if (record?.travelBy === "surface") {
        // navigate("/child-scan", { state: "false" });
        navigate("/cp-packeting", { state: "false" });
        // navigate("/viewBooking-packet", { state: "false" });
      } else {
        // navigate("/viewBooking-packet", { state: "false" });
        // navigate("/child-scan", { state: "false" });
        navigate("/cp-packeting", { state: "false" });
      }
    }
  };

  useEffect(() => {
    if (result === null) {
      dispatch(getManifestTableData());
    } else {
      setData(result);
    }
  }, [result]);

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  /******************* Input search ******************************/
  const onSearch = (e) => {
    // const currValue = e.target.value === undefined ? e : e.target.value;
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(data, currValue)
        : filterArray(data, currValue);
    setDataSource(filteredData);
  };

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const columns = [
    {
      title: "Sl",
      dataIndex: "manifestId",
      key: "manifestId",
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Manifest ID",
      dataIndex: "manifestId",
      key: "manifestId",
      render: (_, record) => (
        <a onClick={() => navigatePage(record)}>{record.manifestId}</a>
      ),
    },
    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (text) => MyComponent(text),
    },
    {
      title: "Mode of manifest",
      dataIndex: "travelBy",
      key: "travelBy",
      render: (text) => capitalizeFirstLetter(text),
    },
    {
      title: "No of shipment(s)",
      dataIndex: "shipmentNum",
      key: "shipmentNum",
    },
    {
      title: "No of packet(s)",
      dataIndex: "packetNum",
      key: "packetNum",
    },
    {
      title: "Total weight in Kg",
      dataIndex: "totalweight",
      key: "totalweight",
      render: (text) => (text === null ? 0 : text),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <span
          style={{
            color:
              record.status === "LOCKED"
                ? "#46C196"
                : record.status === "UNLOCKED"
                ? "#85AFE1"
                : "orange",
          }}
        >
          {record.status}
        </span>
      ),
    },
    {
      title: "Print E-way bill",
      dataIndex: "manifestId",
      key: "manifestId",
      width: 20,
      render: (record, _data) => {
        return (
          <div className="align-center">
            <Tooltip title={"Print"}>
              <a
                style={{ marginLeft: "10px" }}
                onClick={() =>
                  navigate("/print-eway-bill", {
                    state: {
                      state: _data?.manifestId,
                      key: "VIEW_MANIFEST",
                      slug: "/viewBooking",
                    },
                  })
                }
              >
                <img
                  src={print_icon}
                  style={{ width: "22px", height: "20px" }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(pagination) {
    // window.scrollTo(0, 0);
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
  }

  return (
    <Spin
      spinning={fetching}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="view-manifest-container">
        <div className="d-flex flex-end">
          <Col className="search-box">
            <Input
              placeholder="Search by manifest Id"
              bordered
              onChange={onSearch}
              style={{ borderRadius: "5px", height: "30px", width: "400px" }}
              suffix={<img src={search_icon} />}
            />
          </Col>
        </div>
        <div className="manifest-table-container mt-1">
          <TableContainer
            className="table-head-sticky"
            columns={columns}
            dataSource={inputSearchValue.length == 0 ? data : dataSource}
            pagination={{
              pageSize: pageSize,
              total: total,
              itemRender: itemRender,
              current: pageNum == 0 ? +1 : pageNum,
            }}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </Spin>
  );
};

export default React.memo(ViewManifest);
