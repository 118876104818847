/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Select,
  DatePicker,
  Spin,
  Modal,
} from "antd";
import {
  checkAlphabets,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";

import urls from "../../../api/urls";
import TextArea from "antd/lib/input/TextArea";
import CPDetails from "../CPDetails";

import Smcs_Loader from "../../../assests/images/loader-new.gif";

import API from "../../../api";

const { POST_LEAD_CP_DETAILS } = urls;

export const BasicDetails = ({
  handleStepsChange = () => {},
  patchClient,
  getData,
}) => {
  const userId = JSON.parse(localStorage.getItem("userId"));
  const leadCPId = localStorage.getItem("leadCPId");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);

  useEffect(() => {
    if (getData) {
      form.setFieldsValue({
        name: getData?.basicDetail?.name || null,
        officialNumber: getData?.basicDetail?.officialNumber || null,
        officialEmailId: getData?.basicDetail?.officialEmailId || null,
        remark: getData?.basicDetail?.remark || null,
      });
    }
  }, [getData]);

  const createBasicDetails = async (formValues) => {
    setLoading(true);
    const checkBtn = clickedButton === "saveAndContinueButton";
    if (leadCPId === null) {
      let response = await API.Api(POST_LEAD_CP_DETAILS).postCustomHeader(
        {
          basicDetail: {
            ...formValues,
            premiseRbacDto: {
              rbacs: [
                {
                  rbacId: 1,
                  premiseType: "CP",
                  rbacType: "Booking",
                  module: "Retail Booking",
                },
              ],
            },
          },
        },
        { "USER-ID": userId?.userInfo?.userId }
      );
      if (response?.status) {
        setLoading(false);
        if (checkBtn) {
          handleStepsChange(1);
        }
        message.success(response?.message || "CP added successfully");
        localStorage.setItem("leadCPId", response?.response?.data?.leadId);
        // form.resetFields();
      } else {
        message.error(response?.response?.data?.message || "Network error");
        setLoading(false);
      }
    } else {
      setLoading(false);
      patchClient(
        {
          basicDetail: { ...formValues },
        },
        checkBtn
      );
    }
  };

  const cancelReset = () => {
    localStorage.removeItem("leadCPId");
    window.history.back();
  };

  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div>
        <Form
          id="myForm"
          className="global-form profile-container-form pl-4 pr-4 p-2"
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinish={createBasicDetails}
        >
          <Row gutter={40} className="global-form-row">
            {/* <Col span={24} className="mb-1">
              <span className="master-title">Basic Details</span>
              <hr className="divider" />
            </Col> */}

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="name"
                label="Name of owner"
                onKeyPress={(event) => {
                  if (checkAlphabets(event)) {
                    event.preventDefault();
                  }
                }}
                rules={[
                  {
                    message: "Please enter owner name",
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Enter owner name"
                  maxLength={40}
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="officialNumber"
                label="Phone number"
                rules={[
                  {
                    required: true,
                    message: "Please enter mobile number",
                  },
                  {
                    pattern: /^([1-9]{1}[0-9]{9}){0,10}$/g,
                    message: "Invalid mobile number",
                  },
                ]}
                onKeyPress={(event) => {
                  if (
                    checkNumbervalue(event) ||
                    checkSpecialCharacter(event) ||
                    checkSpace(event)
                  ) {
                    event.preventDefault();
                  }
                }}
              >
                <Input
                  placeholder="Enter phone number"
                  maxLength={10}
                  // onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="officialEmailId"
                label="Email-ID"
                type="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter E-mail",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input placeholder="Enter E-mail" />
              </Form.Item>
            </Col>

            <CPDetails
              handleStepsChange={handleStepsChange}
              patchClient={patchClient}
              getData={getData}
              // goBackStep={goBackStep}
              //setCpPinCodeData={(val) => setCpPinCode(val)}
            />
            {/*  */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="remark"
                label="Remarks"
                rules={[
                  {
                    message: "Please enter remarks",
                    required: false,
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  showCount
                  maxLength={100}
                  placeholder="Enter remarks"
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="divider" />
          <div className="flex-end mt-1">
            <Button
              className="cancel-btn ml-1 w-15"
              id="save"
              onClick={cancelReset}
            >
              CANCEL
            </Button>
            <Button htmlType="submit" className="cancel-btn ml-1 w-15">
              SAVE
            </Button>
            <Button
              htmlType="submit"
              id="saveNContinue"
              onClick={() => setClickedButton("saveAndContinueButton")}
              className="save-btn  ml-1"
            >
              SAVE & CONTINUE
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default BasicDetails;
